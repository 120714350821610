import * as R from 'ramda';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import {
  COUNTRY_OPTIONS,
  ITEM_VOLUME_OPTIONS,
  PAYMENT_TERM_OPTIONS,
  ITEM_COMMODITY_OPTIONS,
  getFreightClassOptions,
  ITEM_DIMENSIONS_OPTIONS,
  ITEM_TEMPERATURE_OPTIONS,
  getRaleBillingEnumOptions,
  ITEM_ODOMETER_UOM_OPTIONS,
  TEMPERATURE_SENSOR_OPTIONS,
  ITEM_TEMPERATURE_TYPE_OPTIONS,
  DEFAULT_ITEM_WEIGHT_UOM_OPTIONS,
  DEFAULT_QUANTITY_RATE_UNIT_OPTIONS,
  DEFAULT_DISTANCE_RATE_UNIT_OPTIONS,
} from '../../../helpers/options';
//////////////////////////////////////////////////

export const itemDefaultFields = {
  [GC.FIELD_ITEM_ID]: '',
  [GC.FIELD_ITEM_TYPE]: '',
  [GC.FIELD_ITEM_WEIGHT]: '',
  [GC.FIELD_DESCRIPTION]: '',
  [GC.FIELD_ITEM_VOLUME]: '',
  [GC.FIELD_ITEM_N_M_F_C]: '',
  [GC.FIELD_ITEM_QUANTITY]: '',
  [GC.FIELD_ITEM_COMMODITY]: '',
  [GC.FIELD_ORIGIN_OF_GOODS]: '',
  [GC.FIELD_ITEM_VOLUME_UOM]: '',
  [GC.FIELD_ITEM_WEIGHT_TYPE]: '',
  [GC.FIELD_ITEM_PACKAGE_TYPE]: '',
  [GC.FIELD_ITEM_DECLARED_VALUE]: '',
  [GC.FIELD_ITEM_DIMENSIONS_UOM]: '',
  [GC.FIELD_ITEM_MONETARY_VALUE]: '',
  [GC.FIELD_ITEM_TEMPERATURE_LOW]: '',
  [GC.FIELD_ITEM_TEMPERATURE_UOM]: '',
  [GC.FIELD_ITEM_STACKABILITY]: false,
  [GC.FIELD_ITEM_TEMPERATURE_TYPE]: '',
  [GC.FIELD_CONTAINER_INTERNAL_ID]: '',
  [GC.FIELD_ITEM_TEMPERATURE_HIGH]: '',
  [GC.FIELD_ITEM_DIMENSIONS_LENGTH]: '',
  [GC.FIELD_ITEM_DIMENSIONS_WIDTH]: '',
  [GC.FIELD_ITEM_DIMENSIONS_HEIGHT]: '',
  [GC.FIELD_ITEM_DECLARED_CURRENCY]: '',
  [GC.FIELD_ITEM_CUSTOMER_PART_NUMBER]: '',
  [GC.FIELD_ITEM_STACKABILITY_CONDITION]: '',
  [GC.FIELD_ITEM_MONETARY_VALUE_CURRENCY]: '',
  // car hauler
  [GC.FIELD_VIN]: '',
  [GC.FIELD_BAY]: '',
  [GC.FIELD_YEAR]: '',
  [GC.FIELD_MAKE]: '',
  [GC.FIELD_MODEL]: '',
  [GC.FIELD_COLOR]: '',
  [GC.FIELD_ODOMETER]: '',
  [GC.FIELD_SUB_MODEL]: '',
  [GC.FIELD_WHEELBASE]: '',
  [GC.FIELD_ODOMETER_UOM]: '',
  [GC.FIELD_MANUFACTURER]: '',
  [GC.FIELD_ORIGIN_STATE]: '',
  [GC.FIELD_WHEELBASE_UOM]: '',
  [GC.FIELD_LICENSE_PLATE]: '',
  [GC.FIELD_ORIGIN_COUNTRY]: '',
  [GC.FIELD_OPERATIONAL]: false,
};

export const containerDefaultFields = {
  [GC.FIELD_WIDTH]: '',
  [GC.FIELD_HEIGHT]: '',
  [GC.FIELD_LENGTH]: '',
  [GC.FIELD_WEIGHT_UOM]: '',
  [GC.FIELD_DIMENSIONS_UOM]: '',
  [GC.FIELD_CONTAINER_NUMBER]: '',
  [GC.FIELD_CONTAINER_INITIAL]: '',
  [GC.FIELD_CONTAINER_TYPE_GUID]: '',
  [GC.FIELD_FULL_CONTAINER_WEIGHT]: '',
  [GC.FIELD_EMPTY_CONTAINER_WEIGHT]: '',
};

export const referenceDefaultFields = {
  [GC.FIELD_VALUE]: null,
  [GC.FIELD_REFERENCE_TYPE_GUID]: null,
};

export const getNewReferenceFields = () => R.assoc('uniq', G.generateGuid(), referenceDefaultFields);

export const defaultBillToFields = {
  [GC.FIELD_PAYMENT_TERM]: '',
  // location
  [GC.FIELD_ZIP]: '',
  [GC.FIELD_CITY]: '',
  [GC.FIELD_STATE]: '',
  [GC.FIELD_COUNTRY]: '',
  [GC.FIELD_COMMENTS]: '',
  [GC.FIELD_ADDRESS_1]: '',
  [GC.FIELD_ADDRESS_2]: '',
  [GC.FIELD_TEMPLATE_ID]: '',
  [GC.FIELD_LOCATION_TYPE]: '',
  [GC.FIELD_LOCATION_NAME]: '',
  // contact
  [GC.FIELD_FAX]: '',
  [GC.FIELD_PHONE]: '',
  [GC.FIELD_EMAILS]: [],
  [GC.FIELD_CONTACT_NAME]: '',
  [GC.FIELD_PHONE_EXTENSION]: '',
};

export const emptyStopFields = {
  // location
  [GC.FIELD_ZIP]: '',
  [GC.FIELD_CITY]: '',
  [GC.FIELD_STATE]: '',
  [GC.FIELD_COUNTRY]: '',
  [GC.FIELD_COMMENTS]: '',
  [GC.FIELD_LATITUDE]: '',
  [GC.FIELD_LONGITUDE]: '',
  [GC.FIELD_ADDRESS_1]: '',
  [GC.FIELD_ADDRESS_2]: '',
  [GC.FIELD_TEMPLATE_ID]: '',
  [GC.FIELD_LOCATION_TYPE]: '',
  [GC.FIELD_LOCATION_NAME]: '',
  // stop/appointment
  [GC.FIELD_STOP_NUMBER]: '',
  [GC.FIELD_LOAD_FCFS]: false,
  [GC.FIELD_LOAD_EVENT_LATE_TIME]: '',
  [GC.FIELD_LOAD_EVENT_LATE_DATE]: '',
  [GC.FIELD_LOAD_EVENT_EARLY_TIME]: '',
  [GC.FIELD_LOAD_EVENT_EARLY_DATE]: '',
  [GC.FIELD_LOAD_APPOINTMENT_DATE]: '',
  [GC.FIELD_LOAD_APPOINTMENT_NUMBER]: '',
  [GC.FIELD_LOAD_APPOINTMENT_REQUIRED]: '',
  [GC.FIELD_LOAD_APPOINTMENT_LATE_TIME]: '',
  [GC.FIELD_LOAD_APPOINTMENT_EARLY_TIME]: '',
  [GC.FIELD_LOAD_APPOINTMENT_REQUIRED]: false,
  // contact
  [GC.FIELD_FAX]: '',
  [GC.FIELD_PHONE]: '',
  [GC.FIELD_EMAIL]: '',
  [GC.FIELD_CONTACT_NAME]: '',
  // items
  [GC.FIELD_LOAD_ITEMS]: [],
  // references
  [GC.FIELD_LOAD_REFERENCES]: [],
  // containers
  [GC.FIELD_STOP_DROPPED_CONTAINERS]: [],
  [GC.FIELD_STOP_PICKED_UP_CONTAINERS]: [],
  // trailers
  [GC.FIELD_STOP_DROPPED_TRAILER_GUIDS]: [],
  [GC.FIELD_STOP_PICKED_UP_TRAILER_GUIDS]: [],
};

export const getNewItemFields = (branchConfigs: Object) => {
  const branchItemValues = G.mapObjectNullFieldsToEmptyStrings(
    G.getDefaultBranchCloItemValuesFromConfigs(branchConfigs),
  );

  return {
    ...itemDefaultFields,
    ...branchItemValues,
    [GC.FIELD_ITEM_INTERNAL_ID]: G.generateGuid(),
  };
};

export const getNewContainerFields = () => ({
  ...containerDefaultFields,
  [GC.FIELD_CONTAINER_INTERNAL_ID]: G.generateGuid(),
});

export const getDefaultPickupFields = () => {
  const branchConfigs = G.getItemFromWindow('amousNewDoBranchConfigs');

  return {
    ...emptyStopFields,
    [GC.FIELD_LOAD_ITEMS]: [
      getNewItemFields(branchConfigs),
    ],
  };
};

export const getDefaultDropFields = () => ({
  ...emptyStopFields,
});

export const locationFieldsToPick = [
  // location
  GC.FIELD_ZIP,
  GC.FIELD_CITY,
  GC.FIELD_STATE,
  GC.FIELD_COUNTRY,
  GC.FIELD_LATITUDE,
  GC.FIELD_COMMENTS,
  GC.FIELD_LONGITUDE,
  GC.FIELD_ADDRESS_1,
  GC.FIELD_ADDRESS_2,
  GC.FIELD_ADDRESS_3,
  GC.FIELD_ADDRESS_4,
  GC.FIELD_TEMPLATE_ID,
  GC.FIELD_LOCATION_TYPE,
  GC.FIELD_LOCATION_NAME,
  // contacts
  GC.FIELD_CONTACTS,
  // hours
  GC.FIELD_OPERATION_HOUR,
  // external
  GC.FIELD_EXTERNAL_ID,
  GC.FIELD_INTEGRATION_ID,
  GC.FIELD_INTEGRATION_TYPE,
];

export const stopInfoFieldsToPick = [
  // stop/appointment
  GC.FIELD_LOAD_FCFS,
  GC.FIELD_STOP_NUMBER,
  GC.FIELD_LOAD_EVENT_LATE_DATE,
  GC.FIELD_LOAD_EVENT_EARLY_DATE,
  GC.FIELD_LOAD_APPOINTMENT_DATE,
  GC.FIELD_LOAD_APPOINTMENT_NUMBER,
  GC.FIELD_LOAD_APPOINTMENT_REQUIRED,
  GC.FIELD_LOAD_APPOINTMENT_LATE_TIME,
  GC.FIELD_LOAD_APPOINTMENT_EARLY_TIME,
];

const commonProps = {
  width: 160,
  inputWrapperStyles: {
    mr: 20,
  },
};

const commonProps2 = {
  width: 160,
  inputWrapperStyles: {
    mr: 20,
    mb: 30,
  },
};

const commonProps3 = {
  width: 140,
  inputWrapperStyles: {
    mr: 20,
  },
};

const commonProps5 = {
  width: 300,
  inputWrapperStyles: {
    mr: 20,
  },
};

const commonProps6 = {
  width: 340,
  inputWrapperStyles: {
    mr: 20,
  },
};

const commonProps7 = {
  width: 180,
  inputWrapperStyles: {
    mr: 20,
  },
};

const commonProps8 = {
  width: 170,
  inputWrapperStyles: {
    mr: 15,
  },
};

const commonProps9 = {
  width: 100,
  inputWrapperStyles: {
    mr: 15,
  },
};

const commonProps10 = {
  width: 116,
  inputWrapperStyles: {
    mr: 15,
  },
};

export const mainStopFields = [
  {
    ...commonProps,
    type: 'select',
    fieldName: GC.FIELD_LOCATION_TYPE,
    label: G.getWindowLocale('titles:type', 'Type'),
    options: {
      configName: GC.TEMPLATES_LOCATION_TYPE,
    },
  },
  {
    inputWrapperStyles: {
      mr: 20,
      width: 250,
      zIndex: 15,
    },
    type: 'asyncSearch',
    fieldName: GC.FIELD_TEMPLATE_ID,
    label: G.getWindowLocale('titles:search-by-template-id-name', 'Search by Template Id/Name'),
  },
];

const latLongFields = [
  {
    ...commonProps2,
    type: 'text',
    disabled: true,
    isRequired: true,
    fieldName: GC.FIELD_LATITUDE,
    label: G.getWindowLocale('titles:latitude', 'Latitude'),
  },
  {
    ...commonProps2,
    type: 'text',
    disabled: true,
    isRequired: true,
    fieldName: GC.FIELD_LONGITUDE,
    label: G.getWindowLocale('titles:longitude', 'Longitude'),
  },
];

const restLocationFields = [
  {
    ...commonProps2,
    isRequired: true,
    type: 'countrySelect',
    fieldName: GC.FIELD_COUNTRY,
    label: G.getWindowLocale('titles:country', 'Country'),
  },
  ...latLongFields,
  {
    ...commonProps6,
    type: 'text',
    fieldName: GC.FIELD_COMMENTS,
    label: G.getWindowLocale('titles:comments', 'Comments'),
  },
];

export const addressStopFields = [
  {
    width: 160,
    inputWrapperStyles: {
      mr: 20,
      mb: 30,
      zIndex: 14,
    },
    type: 'text',
    isRequired: true,
    fieldName: GC.FIELD_LOCATION_NAME,
    label: G.getWindowLocale('titles:name', 'Name'),
  },
  {
    width: 160,
    inputWrapperStyles: {
      mr: 20,
      mb: 30,
      zIndex: 14,
    },
    type: 'addressAutocomplete',
    fieldName: GC.FIELD_ADDRESS_1,
    label: G.getWindowLocale('titles:address1', 'Address1'),
  },
  {
    width: 160,
    inputWrapperStyles: {
      mb: 30,
      mr: 20,
    },
    type: 'text',
    fieldName: GC.FIELD_ADDRESS_2,
    label: G.getWindowLocale('titles:address2', 'Address2'),
  },
  {
    width: 160,
    inputWrapperStyles: {
      mr: 20,
      mb: 30,
      zIndex: 14,
    },
    isRequired: true,
    omitAddress1: true,
    fieldName: GC.FIELD_CITY,
    type: 'addressAutocomplete',
    label: G.getWindowLocale('titles:city', 'City'),
  },
  {
    ...commonProps2,
    type: 'text',
    isRequired: true,
    fieldName: GC.FIELD_STATE,
    label: G.getWindowLocale('titles:state', 'State'),
  },
  {
    ...commonProps2,
    type: 'text',
    isRequired: true,
    fieldName: GC.FIELD_ZIP,
    label: G.getWindowLocale('titles:postal-code', 'Postal Code'),
  },
  ...restLocationFields,
];

export const quoteAddressStopFields = [
  {
    width: 160,
    inputWrapperStyles: {
      mr: 20,
      mb: 30,
      zIndex: 14,
    },
    type: 'text',
    fieldName: GC.FIELD_LOCATION_NAME,
    label: G.getWindowLocale('titles:name', 'Name'),
  },
  {
    width: 160,
    inputWrapperStyles: {
      mr: 20,
      mb: 30,
      zIndex: 14,
    },
    type: 'addressAutocomplete',
    fieldName: GC.FIELD_ADDRESS_1,
    label: G.getWindowLocale('titles:address1', 'Address1'),
  },
  {
    width: 160,
    inputWrapperStyles: {
      mb: 30,
      mr: 20,
    },
    type: 'text',
    fieldName: GC.FIELD_ADDRESS_2,
    label: G.getWindowLocale('titles:address2', 'Address2'),
  },
  {
    width: 160,
    inputWrapperStyles: {
      mr: 20,
      mb: 30,
      zIndex: 22,
    },
    isRequired: true,
    omitAddress1: true,
    fieldName: GC.FIELD_CITY,
    type: 'addressAutocomplete',
    label: G.getWindowLocale('titles:city', 'City'),
  },
  {
    ...commonProps2,
    type: 'text',
    isRequired: true,
    fieldName: GC.FIELD_STATE,
    label: G.getWindowLocale('titles:state', 'State'),
  },
  {
    ...commonProps2,
    type: 'text',
    isRequired: true,
    fieldName: GC.FIELD_ZIP,
    label: G.getWindowLocale('titles:postal-code', 'Postal Code'),
  },
  ...restLocationFields,
];

export const earlyCalendarDateTimeDisplayFunc = (props: Object) => {
  const { branchConfigs } = props;

  const conf = G.getConfigValueFromStoreOrWindow(GC.CLO_UI_SEPARATE_EVENT_DATE_AND_TIME, branchConfigs);

  return G.ifElse(
    G.isFalse(conf),
    'initial',
    'none',
  );
};

export const earlyCalendarDateAndTimeDisplayFunc = (props: Object) => {
  const { branchConfigs } = props;

  const conf = G.getConfigValueFromStoreOrWindow(GC.CLO_UI_SEPARATE_EVENT_DATE_AND_TIME, branchConfigs);

  return G.ifElse(
    G.isTrue(conf),
    'initial',
    'none',
  );
};

const lateCalendarDateTimeDisplayFunc = (props: Object) => {
  const { values, fromPage, branchConfigs } = props;

  const conf = G.getConfigValueFromStoreOrWindow(GC.CLO_UI_SEPARATE_EVENT_DATE_AND_TIME, branchConfigs);

  return G.ifElse(
    R.and(
      R.or(
        R.pathEq(true, [GC.FIELD_LOAD_FCFS], values),
        R.includes(fromPage, [GC.PAGE_DISPATCH_PLANNER, GC.PAGE_DISPATCH_PLANNER_EVENTS]),
      ),
      G.isFalse(conf),
    ),
    'initial',
    'none',
  );
};

const lateCalendarDateAndTimeDisplayFunc = (props: Object) => {
  const { values, fromPage, branchConfigs } = props;

  const conf = G.getConfigValueFromStoreOrWindow(GC.CLO_UI_SEPARATE_EVENT_DATE_AND_TIME, branchConfigs);

  return G.ifElse(
    R.and(
      R.or(
        R.pathEq(true, [GC.FIELD_LOAD_FCFS], values),
        R.includes(fromPage, [GC.PAGE_DISPATCH_PLANNER, GC.PAGE_DISPATCH_PLANNER_EVENTS]),
      ),
      G.isTrue(conf),
    ),
    'initial',
    'none',
  );
};

export const getCustomChangeHandler2 = (timeField: any, dateField: string) => ({ value, props }: Object) => {
  const { values, fromPage, setValues, setFieldValue } = props;

  const timePath = G.ifElse(G.isArray(timeField), timeField, R.of(Array, timeField));
  const eventTime = R.path(timePath, values);

  if (G.isValidMoment(value)) {
    const validDate = G.createLocalDateTimeFromInstanceOrISOString(value, GC.DEFAULT_DATE_FORMAT);
    const eventDateTime = `${validDate} ${eventTime}`;

    if (G.isValidMoment(eventDateTime)) {
      const isFromPlanner = R.includes(fromPage, [GC.PAGE_DISPATCH_PLANNER, GC.PAGE_DISPATCH_PLANNER_EVENTS]);

      if (R.and(isFromPlanner, R.equals(dateField, GC.FIELD_LOAD_EVENT_EARLY_DATE))) {
        return setValues(R.mergeRight(values, {
          [GC.FIELD_LOAD_EVENT_LATE_DATE]: eventDateTime,
          [GC.FIELD_LOAD_EVENT_EARLY_DATE]: eventDateTime,
        }));
      }

      return setFieldValue(dateField, eventDateTime);
    }
  }

  setFieldValue(
    dateField,
    G.createLocalDateTimeFromInstanceOrISOString(value, GC.DEFAULT_DATE_FORMAT),
  );
};

export const getDateCustomBlurHandler = (timeField: any, dateField: string) => (
  event: Object,
  field: Object,
  props: Object,
) => {
  const { values, fromPage, setValues, setFieldValue } = props;

  const value = event.target.value;
  const timePath = G.ifElse(G.isArray(timeField), timeField, R.of(Array, timeField));
  const eventTime = R.path(timePath, values);

  if (G.isValidMoment(value)) {
    const validDate = G.createLocalDateTimeFromInstanceOrISOString(value, GC.DEFAULT_DATE_FORMAT);
    const eventDateTime = G.createLocalDateTimeFromInstanceOrISOString(
      `${validDate} ${eventTime}`,
      GC.DEFAULT_DATE_TIME_FORMAT,
    );
    const isFromPlanner = R.includes(fromPage, [GC.PAGE_DISPATCH_PLANNER, GC.PAGE_DISPATCH_PLANNER_EVENTS]);

    if (G.isValidMoment(eventDateTime)) {
      if (R.and(isFromPlanner, R.equals(dateField, GC.FIELD_LOAD_EVENT_EARLY_DATE))) {
        return setValues(R.mergeRight(values, {
          [GC.FIELD_LOAD_EVENT_LATE_DATE]: eventDateTime,
          [GC.FIELD_LOAD_EVENT_EARLY_DATE]: eventDateTime,
        }));
      }

      setFieldValue(dateField, eventDateTime);
    }
  }
};

const getDateTimeCustomBlurHandler = (dateField: string) => (
  event: Object,
  field: Object,
  props: Object,
) => {
  const { values, fromPage, setValues, setFieldValue } = props;

  const value = event.target.value;

  if (G.isValidMoment(value)) {
    const validDateTime = G.createLocalDateTimeFromInstanceOrISOString(value, GC.DEFAULT_DATE_TIME_FORMAT);
    const isFromPlanner = R.includes(fromPage, [GC.PAGE_DISPATCH_PLANNER, GC.PAGE_DISPATCH_PLANNER_EVENTS]);

    if (G.isValidMoment(validDateTime)) {
      if (R.and(isFromPlanner, R.equals(dateField, GC.FIELD_LOAD_EVENT_EARLY_DATE))) {
        return setValues(R.mergeRight(values, {
          [GC.FIELD_LOAD_EVENT_LATE_DATE]: validDateTime,
          [GC.FIELD_LOAD_EVENT_EARLY_DATE]: validDateTime,
        }));
      }

      setFieldValue(dateField, validDateTime);
    }
  }
};

export const getTimeCustomBlurHandler = (dateField: any) => (event: Object, field: Object, props: Object) => {
  const { values, fromPage, setValues, setFieldValue } = props;

  const value = event.target.value;
  const datePath = G.ifElse(G.isArray(dateField), dateField, R.of(Array, dateField));
  const eventDate = R.path(datePath, values);

  if (G.isValidMoment(eventDate)) {
    const validDate = G.createLocalDateTimeFromInstanceOrISOString(eventDate, GC.DEFAULT_DATE_FORMAT);
    const eventDateTime = G.createLocalDateTimeFromInstanceOrISOString(
      `${validDate} ${value}`,
      GC.DEFAULT_DATE_TIME_FORMAT,
    );

    if (G.isValidMoment(eventDateTime)) {
      let dateFieldToSet = dateField;

      if (G.isArray(dateField)) dateFieldToSet = R.join('.', dateField);

      const isFromPlanner = R.includes(fromPage, [GC.PAGE_DISPATCH_PLANNER, GC.PAGE_DISPATCH_PLANNER_EVENTS]);

      if (R.and(isFromPlanner, R.equals(dateField, GC.FIELD_LOAD_EVENT_EARLY_DATE))) {
        setValues(R.mergeRight(values, {
          [GC.FIELD_LOAD_EVENT_LATE_TIME]: value,
          [GC.FIELD_LOAD_EVENT_LATE_DATE]: eventDateTime,
          [GC.FIELD_LOAD_EVENT_EARLY_DATE]: eventDateTime,
        }));
      } else {
        setFieldValue(dateFieldToSet, eventDateTime);
      }
    }
  }
};

export const pickupInfoStopFields = [
  {
    width: 100,
    type: 'text',
    inputWrapperStyles: {
      mr: 10,
    },
    fieldName: GC.FIELD_STOP_NUMBER,
    label: G.getWindowLocale('titles:pickup-number', 'Pickup Number'),
  },
  {
    width: 60,
    type: 'checkbox',
    inputWrapperStyles: {
      mb: 0,
      mr: 10,
    },
    fieldName: GC.FIELD_LOAD_FCFS,
    label: G.getWindowLocale('titles:fcfs', 'FCFS'),
  },
  {
    width: 180,
    isRequired: true,
    timeIntervals: 1,
    type: 'datePicker',
    timeSelection: true,
    shouldCustomBlur: true,
    inputWrapperStyles: {
      mr: 20,
      zIndex: 13,
      display: earlyCalendarDateTimeDisplayFunc,
    },
    name: 'titles:early-date',
    fieldName: GC.FIELD_LOAD_EVENT_EARLY_DATE,
    placeholder: GC.DEFAULT_DATE_TIME_PLACEHOLDER,
    label: G.getWindowLocale('titles:pickup-window-from', 'Pickup Window From'),
    customBlurHandler: getDateTimeCustomBlurHandler(GC.FIELD_LOAD_EVENT_EARLY_DATE),
  },
  {
    width: 180,
    timeIntervals: 1,
    type: 'datePicker',
    timeSelection: true,
    inputWrapperStyles: {
      mr: 20,
      zIndex: 13,
      display: lateCalendarDateTimeDisplayFunc,
    },
    name: 'titles:window-to',
    fieldName: GC.FIELD_LOAD_EVENT_LATE_DATE,
    placeholder: GC.DEFAULT_DATE_TIME_PLACEHOLDER,
    label: G.getWindowLocale('titles:pickup-window-to', 'Pickup Window To'),
  },
  {
    width: 120,
    isRequired: true,
    type: 'datePicker',
    inputWrapperStyles: {
      mr: 10,
      zIndex: 13,
      display: earlyCalendarDateAndTimeDisplayFunc,
    },
    shouldCustomBlur: true,
    shouldCustomChange: true,
    placeholder: GC.DEFAULT_DATE_PLACEHOLDER,
    fieldName: GC.FIELD_LOAD_EVENT_EARLY_DATE,
    label: G.getWindowLocale('titles:pickup-window-from', 'Pickup Window From'),
    customBlurHandler: getDateCustomBlurHandler(GC.FIELD_LOAD_EVENT_EARLY_TIME, GC.FIELD_LOAD_EVENT_EARLY_DATE),
    customChangeHandler2: getCustomChangeHandler2(GC.FIELD_LOAD_EVENT_EARLY_TIME, GC.FIELD_LOAD_EVENT_EARLY_DATE),
  },
  {
    width: 100,
    isRequired: true,
    type: 'timeInput',
    shouldCustomBlur: true,
    inputWrapperStyles: {
      mr: 10,
      display: earlyCalendarDateAndTimeDisplayFunc,
    },
    fieldName: GC.FIELD_LOAD_EVENT_EARLY_TIME,
    placeholder: GC.DEFAULT_TIME_AM_PLACEHOLDER,
    label: G.getWindowLocale('titles:time', 'Time'),
    customBlurHandler: getTimeCustomBlurHandler(GC.FIELD_LOAD_EVENT_EARLY_DATE),
  },
  {
    width: 112,
    isRequired: true,
    type: 'datePicker',
    inputWrapperStyles: {
      mr: 10,
      zIndex: 13,
      display: lateCalendarDateAndTimeDisplayFunc,
    },
    shouldCustomBlur: true,
    shouldCustomChange: true,
    fieldName: GC.FIELD_LOAD_EVENT_LATE_DATE,
    placeholder: GC.DEFAULT_DATE_PLACEHOLDER,
    label: G.getWindowLocale('titles:pickup-window-to', 'Pickup Window To'),
    customBlurHandler: getDateCustomBlurHandler(GC.FIELD_LOAD_EVENT_LATE_TIME, GC.FIELD_LOAD_EVENT_LATE_DATE),
    customChangeHandler2: getCustomChangeHandler2(GC.FIELD_LOAD_EVENT_LATE_TIME, GC.FIELD_LOAD_EVENT_LATE_DATE),
  },
  {
    width: 100,
    isRequired: true,
    type: 'timeInput',
    shouldCustomBlur: true,
    inputWrapperStyles: {
      mr: 0,
      zIndex: 13,
      display: lateCalendarDateAndTimeDisplayFunc,
    },
    fieldName: GC.FIELD_LOAD_EVENT_LATE_TIME,
    placeholder: GC.DEFAULT_TIME_AM_PLACEHOLDER,
    label: G.getWindowLocale('titles:time', 'Time'),
    customBlurHandler: getTimeCustomBlurHandler(GC.FIELD_LOAD_EVENT_LATE_DATE),
  },
];

export const dropInfoStopFields = [
  {
    width: 100,
    type: 'text',
    inputWrapperStyles: {
      mr: 10,
    },
    fieldName: GC.FIELD_STOP_NUMBER,
    label: G.getWindowLocale('titles:drop-number', 'Drop Number'),
  },
  {
    width: 60,
    type: 'checkbox',
    inputWrapperStyles: {
      mb: 0,
      mr: 10,
    },
    fieldName: GC.FIELD_LOAD_FCFS,
    label: G.getWindowLocale('titles:fcfs', 'FCFS'),
  },
  {
    width: 180,
    isRequired: true,
    timeIntervals: 1,
    isClearable: true,
    type: 'datePicker',
    timeSelection: true,
    shouldCustomBlur: true,
    inputWrapperStyles: {
      mr: 20,
      zIndex: 13,
      display: earlyCalendarDateTimeDisplayFunc,
    },
    name: 'titles:early-date',
    placeholder: GC.DEFAULT_DATE_TIME_FORMAT,
    fieldName: GC.FIELD_LOAD_EVENT_EARLY_DATE,
    label: G.getWindowLocale('titles:drop-window-from', 'Drop Window From'),
    customBlurHandler: getDateTimeCustomBlurHandler(GC.FIELD_LOAD_EVENT_EARLY_DATE),
  },
  {
    width: 180,
    timeIntervals: 1,
    isClearable: true,
    type: 'datePicker',
    timeSelection: true,
    inputWrapperStyles: {
      mr: 20,
      display: lateCalendarDateTimeDisplayFunc,
    },
    name: 'titles:window-to',
    placeholder: GC.DEFAULT_DATE_TIME_FORMAT,
    fieldName: GC.FIELD_LOAD_EVENT_LATE_DATE,
    label: G.getWindowLocale('titles:drop-window-to', 'Drop Window To'),
  },
  {
    width: 120,
    isRequired: true,
    type: 'datePicker',
    inputWrapperStyles: {
      mr: 10,
      zIndex: 13,
      display: earlyCalendarDateAndTimeDisplayFunc,
    },
    shouldCustomBlur: true,
    shouldCustomChange: true,
    placeholder: GC.DEFAULT_DATE_PLACEHOLDER,
    fieldName: GC.FIELD_LOAD_EVENT_EARLY_DATE,
    label: G.getWindowLocale('titles:drop-window-from', 'Drop Window From'),
    customBlurHandler: getDateCustomBlurHandler(GC.FIELD_LOAD_EVENT_EARLY_TIME, GC.FIELD_LOAD_EVENT_EARLY_DATE),
    customChangeHandler2: getCustomChangeHandler2(GC.FIELD_LOAD_EVENT_EARLY_TIME, GC.FIELD_LOAD_EVENT_EARLY_DATE),
  },
  {
    width: 100,
    isRequired: true,
    type: 'timeInput',
    shouldCustomBlur: true,
    inputWrapperStyles: {
      mr: 10,
      display: earlyCalendarDateAndTimeDisplayFunc,
    },
    fieldName: GC.FIELD_LOAD_EVENT_EARLY_TIME,
    placeholder: GC.DEFAULT_TIME_AM_PLACEHOLDER,
    label: G.getWindowLocale('titles:time', 'Time'),
    customBlurHandler: getTimeCustomBlurHandler(GC.FIELD_LOAD_EVENT_EARLY_DATE),
  },
  {
    width: 112,
    isRequired: true,
    type: 'datePicker',
    inputWrapperStyles: {
      mr: 10,
      zIndex: 13,
      display: lateCalendarDateAndTimeDisplayFunc,
    },
    shouldCustomBlur: true,
    shouldCustomChange: true,
    fieldName: GC.FIELD_LOAD_EVENT_LATE_DATE,
    placeholder: GC.DEFAULT_DATE_PLACEHOLDER,
    label: G.getWindowLocale('titles:drop-window-to', 'Drop Window To'),
    customBlurHandler: getDateCustomBlurHandler(GC.FIELD_LOAD_EVENT_LATE_TIME, GC.FIELD_LOAD_EVENT_LATE_DATE),
    customChangeHandler2: getCustomChangeHandler2(GC.FIELD_LOAD_EVENT_LATE_TIME, GC.FIELD_LOAD_EVENT_LATE_DATE),
  },
  {
    width: 100,
    isRequired: true,
    type: 'timeInput',
    inputWrapperStyles: {
      mr: 0,
      zIndex: 13,
      display: lateCalendarDateAndTimeDisplayFunc,
    },
    shouldCustomBlur: true,
    fieldName: GC.FIELD_LOAD_EVENT_LATE_TIME,
    placeholder: GC.DEFAULT_TIME_AM_PLACEHOLDER,
    label: G.getWindowLocale('titles:time', 'Time'),
    customBlurHandler: getTimeCustomBlurHandler(GC.FIELD_LOAD_EVENT_LATE_DATE),
  },
];

const apptsLateTimeVisibilityFunc = (props: Object) => {
  const { branchConfigs } = props;

  return G.ifElse(
    G.isTrue(G.getConfigValueFromStore(GC.CLO_UI_SINGLE_APPOINTMENT_TIME, branchConfigs)),
    'hidden',
    'visible',
  );
};

const apptEarlyTimeLabelFunc = (props: Object) => {
  const { branchConfigs } = props;

  return G.ifElse(
    G.isTrue(G.getConfigValueFromStore(GC.CLO_UI_SINGLE_APPOINTMENT_TIME, branchConfigs)),
    G.getWindowLocale('titles:appt-time', 'Appt Time'),
    G.getWindowLocale('titles:early-time', 'Early Time'),
  );
};

export const apptsStopFields = [
  {
    width: 80,
    inputWrapperStyles: {
      mb: 0,
      mr: 20,
    },
    type: 'checkbox',
    fieldName: GC.FIELD_LOAD_APPOINTMENT_REQUIRED,
    label: G.getWindowLocale('titles:required', 'Required'),
  },
  {
    width: 110,
    type: 'text',
    inputWrapperStyles: {
      mr: 20,
    },
    fieldName: GC.FIELD_LOAD_APPOINTMENT_NUMBER,
    label: G.getWindowLocale('titles:appt-number', 'Appt Number'),
  },
  {
    width: 120,
    isClearable: true,
    type: 'datePicker',
    inputWrapperStyles: {
      mr: 20,
      zIndex: 12,
    },
    placeholder: GC.DEFAULT_DATE_PLACEHOLDER,
    fieldName: GC.FIELD_LOAD_APPOINTMENT_DATE,
    label: G.getWindowLocale('titles:appt-date', 'Appt Date'),
  },
  {
    width: 110,
    type: 'timeInput',
    inputWrapperStyles: {
      mr: 20,
    },
    placeholder: GC.DEFAULT_TIME_AM_PLACEHOLDER,
    fieldName: GC.FIELD_LOAD_APPOINTMENT_EARLY_TIME,
    label: (props: Object) => apptEarlyTimeLabelFunc(props),
  },
  {
    width: 110,
    type: 'timeInput',
    inputWrapperStyles: {
      mr: 20,
      visibility: apptsLateTimeVisibilityFunc,
    },
    placeholder: GC.DEFAULT_TIME_PM_PLACEHOLDER,
    fieldName: GC.FIELD_LOAD_APPOINTMENT_LATE_TIME,
    label: G.getWindowLocale('titles:late-time', 'Late Time'),
  },
];

export const contactStopFields = [
  {
    ...commonProps3,
    type: 'text',
    fieldName: GC.FIELD_CONTACT_NAME,
    label: G.getWindowLocale('titles:contact-name', 'Contact Name'),
  },
  {
    width: 200,
    type: 'phoneNumber',
    fieldName: GC.FIELD_PHONE,
    inputWrapperStyles: { mr: 20 },
    label: G.getWindowLocale('titles:phone', 'Phone'),
  },
  {
    ...commonProps3,
    type: 'number',
    fieldName: GC.FIELD_PHONE_EXTENSION,
    label: G.getWindowLocale('titles:phone-extension', 'Phone Extension'),
  },
  {
    ...commonProps3,
    type: 'text',
    fieldName: GC.FIELD_EMAIL,
    label: G.getWindowLocale('titles:email', 'Email'),
    inputWrapperStyles: {
      mr: 20,
      mt: 30,
    },
  },
  {
    width: 140,
    type: 'text',
    fieldName: GC.FIELD_FAX,
    label: G.getWindowLocale('titles:fax-number', 'Fax Number'),
    inputWrapperStyles: {
      mr: 20,
      mt: 30,
    },
  },
];

const multiEmailsField = {
  width: 200,
  type: 'multiEmail',
  shouldOverrideEmails: true,
  fieldName: GC.FIELD_EMAILS,
  label: G.getWindowLocale('titles:emails', 'Emails'),
  inputWrapperStyles: {
    mr: 20,
    mt: 30,
  },
};

export const billToContactFields = R.set(R.lensIndex(3), multiEmailsField, contactStopFields);

const itemCommonProps1 = {
  width: 100,
  inputWrapperStyles: {
    mr: 20,
    mb: 30,
  },
};

const itemCommonProps2 = {
  width: 160,
  inputWrapperStyles: {
    mr: 20,
    mb: 30,
  },
};

const itemCommonProps3 = {
  width: 120,
  inputWrapperStyles: {
    mr: 20,
    mb: 30,
  },
};

const itemCommonProps4 = {
  width: 130,
  inputWrapperStyles: {
    mr: 20,
    mb: 30,
  },
};

export const vehicleItemStopGroups = {
  general: {
    fields: [
      {
        ...itemCommonProps1,
        width: 220,
        type: 'text',
        isRequired: false,
        shouldCustomBlur: true,
        fieldName: GC.FIELD_VIN,
        rejectFieldName: GC.FIELD_VIN,
        label: G.getWindowLocale('titles:vin', 'VIN'),
        customBlurHandler: (event: Object, field: Object, props: Object) => {
          const { values, itemIndex, handleBlur, handleVinLookup } = props;

          const value = event.target.value;
          const currentValues = R.path([GC.FIELD_STOP_ITEMS, itemIndex], values);
          const newValues = R.assoc(GC.FIELD_VIN, value, currentValues);

          if (G.isNotNilAndNotEmpty(value)) handleVinLookup(newValues, true);

          handleBlur(event);
        },
      },
      {
        ...itemCommonProps1,
        type: 'text',
        isRequired: true,
        fieldName: GC.FIELD_ITEM_ID,
        label: G.getWindowLocale('titles:item-id', 'Item ID'),
      },
      {
        ...itemCommonProps1,
        type: 'select',
        options: 'itemContainerOptions',
        fieldName: GC.FIELD_CONTAINER_INTERNAL_ID,
        label: G.getWindowLocale('titles:container', 'Container'),
      },
      {
        ...itemCommonProps1,
        type: 'number',
        isRequired: false,
        fieldName: GC.FIELD_YEAR,
        rejectFieldName: GC.FIELD_YEAR,
        label: G.getWindowLocale('titles:year', 'Year'),
      },
      {
        ...itemCommonProps1,
        type: 'text',
        isRequired: false,
        fieldName: GC.FIELD_MANUFACTURER,
        rejectFieldName: GC.FIELD_MANUFACTURER,
        label: G.getWindowLocale('titles:manufacturer', 'Manufacturer'),
      },
      {
        ...itemCommonProps1,
        type: 'text',
        isRequired: false,
        fieldName: GC.FIELD_COLOR,
        rejectFieldName: GC.FIELD_COLOR,
        label: G.getWindowLocale('titles:color', 'Color'),
      },
      {
        ...itemCommonProps1,
        type: 'text',
        isRequired: false,
        fieldName: GC.FIELD_MAKE,
        rejectFieldName: GC.FIELD_MAKE,
        label: G.getWindowLocale('titles:make', 'Make'),
      },
      {
        ...itemCommonProps1,
        type: 'text',
        isRequired: false,
        fieldName: GC.FIELD_MODEL,
        rejectFieldName: GC.FIELD_MODEL,
        label: G.getWindowLocale('titles:model', 'Model'),
      },
      {
        ...itemCommonProps1,
        type: 'text',
        isRequired: false,
        fieldName: GC.FIELD_SUB_MODEL,
        rejectFieldName: GC.FIELD_SUB_MODEL,
        label: G.getWindowLocale('titles:sub-model', 'Submodel'),
      },
      {
        ...itemCommonProps1,
        type: 'number',
        isRequired: false,
        fieldName: GC.FIELD_ODOMETER,
        rejectFieldName: GC.FIELD_ODOMETER,
        label: G.getWindowLocale('titles:odometer', 'Odometer'),
      },
      {
        ...itemCommonProps1,
        type: 'select',
        isRequired: false,
        fieldName: GC.FIELD_ODOMETER_UOM,
        rejectFieldName: GC.FIELD_ODOMETER,
        options: ITEM_ODOMETER_UOM_OPTIONS,
        label: G.getWindowLocale('titles:odometer-uom', 'Odometer UOM'),
      },
      {
        ...itemCommonProps1,
        type: 'text',
        isRequired: false,
        fieldName: GC.FIELD_BAY,
        rejectFieldName: GC.FIELD_BAY,
        label: G.getWindowLocale('titles:bay', 'Bay'),
      },
      {
        ...itemCommonProps1,
        type: 'number',
        isRequired: false,
        fieldName: GC.FIELD_WHEELBASE,
        rejectFieldName: GC.FIELD_WHEELBASE,
        label: G.getWindowLocale('titles:wheelbase', 'Wheelbase'),
      },
      {
        ...itemCommonProps1,
        type: 'select',
        isRequired: false,
        options: ITEM_DIMENSIONS_OPTIONS,
        fieldName: GC.FIELD_WHEELBASE_UOM,
        rejectFieldName: GC.FIELD_WHEELBASE,
        label: G.getWindowLocale('titles:wheelbase-uom', 'Wheelbase UOM'),
      },
      {
        ...itemCommonProps1,
        type: 'text',
        isRequired: false,
        fieldName: GC.FIELD_LICENSE_PLATE,
        rejectFieldName: GC.FIELD_LICENSE_PLATE,
        label: G.getWindowLocale('titles:license-plate', 'License Plate'),
      },
      {
        ...itemCommonProps1,
        type: 'text',
        isRequired: false,
        fieldName: GC.FIELD_ORIGIN_STATE,
        rejectFieldName: GC.FIELD_ORIGIN_STATE,
        label: G.getWindowLocale('titles:origin-state', 'Origin State'),
      },
      {
        ...itemCommonProps1,
        type: 'select',
        isRequired: false,
        fieldName: GC.FIELD_ORIGIN_COUNTRY,
        rejectFieldName: GC.FIELD_ORIGIN_COUNTRY,
        options: G.prependEmptyLabelValueOption(COUNTRY_OPTIONS),
        label: G.getWindowLocale('titles:origin-country', 'Origin Country'),
      },
      {
        ...itemCommonProps1,
        type: 'number',
        isRequired: true,
        fieldName: GC.FIELD_ITEM_WEIGHT,
        label: G.getWindowLocale('titles:weight', 'Weight'),
      },
      {
        ...itemCommonProps1,
        type: 'select',
        isRequired: true,
        fieldName: GC.FIELD_ITEM_WEIGHT_TYPE,
        options: DEFAULT_ITEM_WEIGHT_UOM_OPTIONS,
        label: G.getWindowLocale('titles:uom', 'UOM'),
      },
      {
        ...itemCommonProps1,
        type: 'checkbox',
        fieldName: GC.FIELD_OPERATIONAL,
        rejectFieldName: GC.FIELD_OPERATIONAL,
        label: G.getWindowLocale('titles:operational', 'Operational'),
      },
      {
        ...itemCommonProps2,
        type: 'text',
        isRequired: false,
        fieldName: GC.FIELD_DESCRIPTION,
        rejectFieldName: GC.FIELD_ITEM_FREIGHT_CLASS,
        label: G.getWindowLocale('titles:description', 'Description'),
      },
      {
        ...itemCommonProps1,
        type: 'text',
        isRequired: false,
        fieldName: GC.FIELD_ITEM_QUANTITY,
        rejectFieldName: GC.FIELD_ITEM_QUANTITY,
        label: G.getWindowLocale('titles:quantity', 'Quantity'),
      },
      {
        ...itemCommonProps1,
        type: 'select',
        isRequired: false,
        fieldName: GC.FIELD_ITEM_PACKAGE_TYPE,
        rejectFieldName: GC.FIELD_ITEM_QUANTITY,
        options: DEFAULT_QUANTITY_RATE_UNIT_OPTIONS,
        label: G.getWindowLocale('titles:uom', 'UOM'),
      },
      {
        ...itemCommonProps1,
        type: 'select',
        isRequired: false,
        options: getFreightClassOptions(),
        fieldName: GC.FIELD_ITEM_FREIGHT_CLASS,
        rejectFieldName: GC.FIELD_ITEM_FREIGHT_CLASS,
        label: G.getWindowLocale('titles:freight-class', 'Freight Class'),
      },
      {
        ...itemCommonProps1,
        type: 'text',
        isRequired: false,
        fieldName: GC.FIELD_ITEM_MONETARY_VALUE,
        rejectFieldName: GC.FIELD_ITEM_MONETARY_VALUE,
        label: G.getWindowLocale('titles:monetary-value', 'Monetary Value'),
      },
      {
        ...itemCommonProps1,
        type: 'select',
        isRequired: false,
        options: GC.CURRENCY_OPTIONS_2,
        rejectFieldName: GC.FIELD_ITEM_MONETARY_VALUE,
        fieldName: GC.FIELD_ITEM_MONETARY_VALUE_CURRENCY,
        label: G.getWindowLocale('titles:currency', 'Currency'),
      },
      {
        ...itemCommonProps1,
        type: 'text',
        isRequired: false,
        fieldName: GC.FIELD_ITEM_N_M_F_C,
        rejectFieldName: GC.FIELD_ITEM_N_M_F_C,
        label: G.getWindowLocale('titles:nmfc', 'NMFC'),
      },
    ],
  },
};

export const pickupItemStopGroups = {
  general: {
    fields: [
      {
        ...itemCommonProps1,
        type: 'text',
        isRequired: true,
        fieldName: GC.FIELD_ITEM_ID,
        label: G.getWindowLocale('titles:item-id', 'Item ID'),
      },
      {
        ...itemCommonProps1,
        type: 'number',
        isRequired: true,
        shouldCustomChange: true,
        fieldName: GC.FIELD_ITEM_WEIGHT,
        label: G.getWindowLocale('titles:weight', 'Weight'),
      },
      {
        ...itemCommonProps1,
        type: 'select',
        isRequired: true,
        shouldCustomChange: true,
        fieldName: GC.FIELD_ITEM_WEIGHT_TYPE,
        options: DEFAULT_ITEM_WEIGHT_UOM_OPTIONS,
        label: G.getWindowLocale('titles:uom', 'UOM'),
      },
      {
        ...itemCommonProps1,
        type: 'select',
        options: 'itemContainerOptions',
        fieldName: GC.FIELD_CONTAINER_INTERNAL_ID,
        label: G.getWindowLocale('titles:container', 'Container'),
      },
      {
        ...itemCommonProps2,
        type: 'text',
        fieldName: GC.FIELD_DESCRIPTION,
        label: G.getWindowLocale('titles:description', 'Description'),
      },
      {
        ...itemCommonProps1,
        type: 'text',
        isRequired: false,
        fieldName: GC.FIELD_ITEM_QUANTITY,
        rejectFieldName: GC.FIELD_ITEM_QUANTITY,
        label: G.getWindowLocale('titles:quantity', 'Quantity'),
      },
      {
        ...itemCommonProps1,
        type: 'select',
        isRequired: false,
        fieldName: GC.FIELD_ITEM_PACKAGE_TYPE,
        rejectFieldName: GC.FIELD_ITEM_QUANTITY,
        options: DEFAULT_QUANTITY_RATE_UNIT_OPTIONS,
        label: G.getWindowLocale('titles:uom', 'UOM'),
      },
      {
        ...itemCommonProps1,
        type: 'select',
        isRequired: false,
        options: getFreightClassOptions(),
        fieldName: GC.FIELD_ITEM_FREIGHT_CLASS,
        rejectFieldName: GC.FIELD_ITEM_FREIGHT_CLASS,
        label: G.getWindowLocale('titles:freight-class', 'Freight Class'),
      },
      {
        ...itemCommonProps1,
        type: 'text',
        isRequired: false,
        fieldName: GC.FIELD_ITEM_MONETARY_VALUE,
        rejectFieldName: GC.FIELD_ITEM_MONETARY_VALUE,
        label: G.getWindowLocale('titles:monetary-value', 'Monetary Value'),
      },
      {
        ...itemCommonProps1,
        type: 'select',
        isRequired: false,
        options: GC.CURRENCY_OPTIONS_2,
        rejectFieldName: GC.FIELD_ITEM_MONETARY_VALUE,
        fieldName: GC.FIELD_ITEM_MONETARY_VALUE_CURRENCY,
        label: G.getWindowLocale('titles:currency', 'Currency'),
      },
      {
        ...itemCommonProps1,
        type: 'text',
        isRequired: false,
        fieldName: GC.FIELD_ITEM_N_M_F_C,
        rejectFieldName: GC.FIELD_ITEM_N_M_F_C,
        label: G.getWindowLocale('titles:nmfc', 'NMFC'),
      },
      {
        ...itemCommonProps1,
        type: 'select',
        isRequired: false,
        fieldName: GC.FIELD_ORIGIN_OF_GOODS,
        rejectFieldName: GC.FIELD_ORIGIN_OF_GOODS,
        options: G.prependEmptyLabelValueOption(COUNTRY_OPTIONS),
        label: G.getWindowLocale('titles:origin-of-goods', 'Origin Of Goods'),
      },
      // car hauler
      {
        ...itemCommonProps1,
        type: 'text',
        isRequired: false,
        fieldName: GC.FIELD_MANUFACTURER,
        rejectFieldName: GC.FIELD_MANUFACTURER,
        label: G.getWindowLocale('titles:manufacturer', 'Manufacturer'),
      },
      {
        ...itemCommonProps1,
        type: 'checkbox',
        fieldName: GC.FIELD_OPERATIONAL,
        rejectFieldName: GC.FIELD_OPERATIONAL,
        label: G.getWindowLocale('titles:operational', 'Operational'),
      },
      {
        ...itemCommonProps1,
        type: 'number',
        isRequired: false,
        fieldName: GC.FIELD_YEAR,
        rejectFieldName: GC.FIELD_YEAR,
        label: G.getWindowLocale('titles:year', 'Year'),
      },
      {
        ...itemCommonProps1,
        type: 'text',
        isRequired: false,
        fieldName: GC.FIELD_MAKE,
        rejectFieldName: GC.FIELD_MAKE,
        label: G.getWindowLocale('titles:make', 'Make'),
      },
      {
        ...itemCommonProps1,
        type: 'text',
        isRequired: false,
        fieldName: GC.FIELD_MODEL,
        rejectFieldName: GC.FIELD_MODEL,
        label: G.getWindowLocale('titles:model', 'Model'),
      },
      {
        ...itemCommonProps1,
        type: 'text',
        isRequired: false,
        fieldName: GC.FIELD_SUB_MODEL,
        rejectFieldName: GC.FIELD_SUB_MODEL,
        label: G.getWindowLocale('titles:sub-model', 'Submodel'),
      },
      {
        ...itemCommonProps1,
        type: 'number',
        isRequired: false,
        fieldName: GC.FIELD_WHEELBASE,
        rejectFieldName: GC.FIELD_WHEELBASE,
        label: G.getWindowLocale('titles:wheelbase', 'Wheelbase'),
      },
      {
        ...itemCommonProps1,
        type: 'select',
        isRequired: false,
        options: ITEM_DIMENSIONS_OPTIONS,
        fieldName: GC.FIELD_WHEELBASE_UOM,
        rejectFieldName: GC.FIELD_WHEELBASE,
        label: G.getWindowLocale('titles:wheelbase-uom', 'Wheelbase UOM'),
      },
      {
        ...itemCommonProps1,
        type: 'text',
        isRequired: false,
        fieldName: GC.FIELD_BAY,
        rejectFieldName: GC.FIELD_BAY,
        label: G.getWindowLocale('titles:bay', 'Bay'),
      },
      {
        ...itemCommonProps1,
        type: 'number',
        isRequired: false,
        fieldName: GC.FIELD_ODOMETER,
        rejectFieldName: GC.FIELD_ODOMETER,
        label: G.getWindowLocale('titles:odometer', 'Odometer'),
      },
      {
        ...itemCommonProps1,
        type: 'select',
        isRequired: false,
        fieldName: GC.FIELD_ODOMETER_UOM,
        rejectFieldName: GC.FIELD_ODOMETER,
        options: ITEM_ODOMETER_UOM_OPTIONS,
        label: G.getWindowLocale('titles:odometer-uom', 'Odometer UOM'),
      },
      {
        ...itemCommonProps1,
        type: 'number',
        isRequired: false,
        fieldName: GC.FIELD_ITEM_DECLARED_VALUE,
        rejectFieldName: GC.FIELD_ITEM_DECLARED_VALUE,
        label: G.getWindowLocale('titles:declared-value', 'Declared Value'),
      },
      {
        ...itemCommonProps1,
        type: 'select',
        isRequired: false,
        options: GC.CURRENCY_OPTIONS_2,
        fieldName: GC.FIELD_ITEM_DECLARED_CURRENCY,
        rejectFieldName: GC.FIELD_ITEM_DECLARED_VALUE,
        label: G.getWindowLocale('titles:currency', 'currency'),
      },
      {
        ...itemCommonProps1,
        type: 'text',
        isRequired: false,
        fieldName: GC.FIELD_COLOR,
        rejectFieldName: GC.FIELD_COLOR,
        label: G.getWindowLocale('titles:color', 'Color'),
      },
      {
        ...itemCommonProps1,
        type: 'text',
        isRequired: false,
        fieldName: GC.FIELD_LICENSE_PLATE,
        rejectFieldName: GC.FIELD_LICENSE_PLATE,
        label: G.getWindowLocale('titles:license-plate', 'License Plate'),
      },
      {
        ...itemCommonProps1,
        type: 'text',
        isRequired: false,
        fieldName: GC.FIELD_VIN,
        rejectFieldName: GC.FIELD_VIN,
        label: G.getWindowLocale('titles:vin', 'VIN'),
      },
      {
        ...itemCommonProps1,
        type: 'text',
        isRequired: false,
        fieldName: GC.FIELD_ORIGIN_STATE,
        rejectFieldName: GC.FIELD_ORIGIN_STATE,
        label: G.getWindowLocale('titles:origin-state', 'Origin State'),
      },
      {
        ...itemCommonProps1,
        type: 'select',
        isRequired: false,
        fieldName: GC.FIELD_ORIGIN_COUNTRY,
        rejectFieldName: GC.FIELD_ORIGIN_COUNTRY,
        options: G.prependEmptyLabelValueOption(COUNTRY_OPTIONS),
        label: G.getWindowLocale('titles:origin-country', 'Origin Country'),
      },
    ],
  },
  dimensionsL: {
    rejectFieldName: GC.FIELD_ITEM_DIMENSIONS_LENGTH,
    fields: [
      {
        ...itemCommonProps1,
        type: 'text',
        isRequired: false,
        shouldCustomChange: true,
        fieldName: GC.FIELD_ITEM_DIMENSIONS_LENGTH,
        rejectFieldName: GC.FIELD_ITEM_DIMENSIONS_LENGTH,
        label: G.getWindowLocale('titles:dimensions-l', 'Dimensions L'),
      },
      {
        ...itemCommonProps1,
        type: 'text',
        isRequired: false,
        shouldCustomChange: true,
        fieldName: GC.FIELD_ITEM_DIMENSIONS_WIDTH,
        rejectFieldName: GC.FIELD_ITEM_DIMENSIONS_LENGTH,
        label: G.getWindowLocale('titles:dimensions-w', 'Dimensions W'),
      },
      {
        ...itemCommonProps1,
        type: 'text',
        isRequired: false,
        shouldCustomChange: true,
        fieldName: GC.FIELD_ITEM_DIMENSIONS_HEIGHT,
        rejectFieldName: GC.FIELD_ITEM_DIMENSIONS_LENGTH,
        label: G.getWindowLocale('titles:dimensions-l', 'Dimensions H'),
      },
      {
        ...itemCommonProps3,
        type: 'select',
        isRequired: false,
        shouldCustomChange: true,
        options: ITEM_DIMENSIONS_OPTIONS,
        fieldName: GC.FIELD_ITEM_DIMENSIONS_UOM,
        label: G.getWindowLocale('titles:uom', 'UOM'),
        rejectFieldName: GC.FIELD_ITEM_DIMENSIONS_LENGTH,
      },
    ],
  },
  temperature: {
    rejectFieldName: [GC.FIELD_ITEM_TEMPERATURE_LOW, GC.FIELD_ITEM_TEMPERATURE_TYPE],
    fields: [
      {
        ...itemCommonProps3,
        type: 'select',
        isRequired: false,
        options: ITEM_TEMPERATURE_TYPE_OPTIONS,
        fieldName: GC.FIELD_ITEM_TEMPERATURE_TYPE,
        rejectFieldName: GC.FIELD_ITEM_TEMPERATURE_TYPE,
        label: G.getWindowLocale('titles:temperature-type', 'Temperature Type'),
      },
      {
        ...itemCommonProps1,
        type: 'text',
        isRequired: false,
        fieldName: GC.FIELD_ITEM_TEMPERATURE_LOW,
        rejectFieldName: GC.FIELD_ITEM_TEMPERATURE_LOW,
        label: G.getWindowLocale('titles:temperature-low', 'Temperature Low'),
      },
      {
        ...itemCommonProps1,
        type: 'text',
        isRequired: false,
        fieldName: GC.FIELD_ITEM_TEMPERATURE_HIGH,
        rejectFieldName: GC.FIELD_ITEM_TEMPERATURE_LOW,
        label: G.getWindowLocale('titles:temperature-high', 'Temperature High'),
      },
      {
        ...itemCommonProps3,
        type: 'select',
        isRequired: false,
        options: ITEM_TEMPERATURE_OPTIONS,
        fieldName: GC.FIELD_ITEM_TEMPERATURE_UOM,
        label: G.getWindowLocale('titles:uom', 'UOM'),
        rejectFieldName: GC.FIELD_ITEM_TEMPERATURE_LOW,
      },
    ],
  },
  volume: {
    fields: [
      {
        ...itemCommonProps1,
        type: 'text',
        isRequired: false,
        fieldName: GC.FIELD_ITEM_VOLUME,
        rejectFieldName: GC.FIELD_ITEM_VOLUME,
        label: G.getWindowLocale('titles:volume', 'Volume'),
      },
      {
        ...itemCommonProps3,
        type: 'select',
        isRequired: false,
        options: ITEM_VOLUME_OPTIONS,
        fieldName: GC.FIELD_ITEM_VOLUME_UOM,
        rejectFieldName: GC.FIELD_ITEM_VOLUME,
        label: G.getWindowLocale('titles:uom', 'UOM'),
      },
      {
        ...itemCommonProps3,
        type: 'select',
        isRequired: false,
        options: ITEM_COMMODITY_OPTIONS,
        fieldName: GC.FIELD_ITEM_COMMODITY,
        rejectFieldName: GC.FIELD_ITEM_COMMODITY,
        label: G.getWindowLocale('titles:commodity', 'Commodity'),
      },
      {
        ...itemCommonProps1,
        type: 'text',
        isRequired: false,
        fieldName: GC.FIELD_ITEM_CUSTOMER_PART_NUMBER,
        rejectFieldName: GC.FIELD_ITEM_CUSTOMER_PART_NUMBER,
        label: G.getWindowLocale('titles:part-num', 'Part Number'),
      },
    ],
  },
  stackability: {
    rejectFieldName: [GC.FIELD_ITEM_STACKABILITY, GC.FIELD_ITEM_TEMPERATURE_SENSOR],
    fields: [
      {
        ...itemCommonProps1,
        type: 'checkbox',
        fieldName: GC.FIELD_ITEM_STACKABILITY,
        rejectFieldName: GC.FIELD_ITEM_STACKABILITY,
        label: G.getWindowLocale('titles:stackability', 'Stackability'),
      },
      {
        ...itemCommonProps2,
        type: 'text',
        isRequired: false,
        rejectFieldName: GC.FIELD_ITEM_STACKABILITY,
        fieldName: GC.FIELD_ITEM_STACKABILITY_CONDITION,
        label: G.getWindowLocale('titles:stackability-condition', 'Stackability Condition'),
      },
      {
        ...itemCommonProps3,
        type: 'select',
        isRequired: false,
        options: TEMPERATURE_SENSOR_OPTIONS,
        fieldName: GC.FIELD_ITEM_TEMPERATURE_SENSOR,
        rejectFieldName: GC.FIELD_ITEM_TEMPERATURE_SENSOR,
        label: G.getWindowLocale('titles:temperature-sensor', 'Temperature Sensor'),
      },
    ],
  },
};

export const pickupContainerFields = [
  {
    ...itemCommonProps2,
    type: 'select',
    shouldCustomChange: true,
    customChangeHandler: (event: Object, field: Object, props: Object) => {
      const { values, itemIndex, containerTypes, setFieldValue } = props;

      const value = event.target.value;
      const containers = R.prop(GC.FIELD_STOP_PICKED_UP_CONTAINERS, values);
      const currentValues = R.path([GC.FIELD_STOP_PICKED_UP_CONTAINERS, itemIndex], values);

      const containerInternalId = R.prop(GC.FIELD_CONTAINER_INTERNAL_ID, currentValues);

      if (G.isNilOrEmpty(value)) {
        const newValues = R.assoc(GC.FIELD_CONTAINER_TYPE_GUID, value, currentValues);
        const updatedContainers = R.update(itemIndex, newValues, containers);

        return setFieldValue(GC.FIELD_STOP_PICKED_UP_CONTAINERS, updatedContainers);
      }

      const newValues = R.compose(
        G.mapObjectNullFieldsToEmptyStrings,
        R.assoc(GC.FIELD_CONTAINER_TYPE_GUID, value),
        R.assoc(GC.FIELD_CONTAINER_INTERNAL_ID, containerInternalId),
        R.pick([
          GC.FIELD_WIDTH,
          GC.FIELD_HEIGHT,
          GC.FIELD_LENGTH,
          GC.FIELD_WEIGHT_UOM,
          GC.FIELD_DIMENSIONS_UOM,
          GC.FIELD_EMPTY_CONTAINER_WEIGHT,
        ]),
        R.find(R.propEq(value, GC.FIELD_GUID)),
      )(containerTypes);
      const newValuesWithFullWeight = R.assoc(
        GC.FIELD_FULL_CONTAINER_WEIGHT,
        R.prop(GC.FIELD_EMPTY_CONTAINER_WEIGHT, newValues),
        newValues,
      );
      const updatedContainers = R.update(itemIndex, newValuesWithFullWeight, containers);

      return setFieldValue(GC.FIELD_STOP_PICKED_UP_CONTAINERS, updatedContainers);
    },
    options: 'containerTypeOptions',
    fieldName: GC.FIELD_CONTAINER_TYPE_GUID,
    label: G.getWindowLocale('titles:container-type', 'Container Type'),
  },
  {
    ...itemCommonProps2,
    type: 'text',
    fieldName: GC.FIELD_CONTAINER_INITIAL,
    label: G.getWindowLocale('titles:container-initial', 'Container Initial'),
  },
  {
    ...itemCommonProps2,
    type: 'text',
    isRequired: true,
    shouldCustomBlur: true,
    customBlurHandler: (event: Object, field: Object, props: Object) => {
      const { stop, values, itemIndex, handleBlur, setFieldValue, setFormDataToStop } = props;

      const value = event.target.value;
      const containers = R.prop(GC.FIELD_STOP_PICKED_UP_CONTAINERS, values);
      const currentValues = R.path([GC.FIELD_STOP_PICKED_UP_CONTAINERS, itemIndex], values);
      const newValues = R.assoc(GC.FIELD_CONTAINER_NUMBER, value, currentValues);
      const updatedContainers = R.update(itemIndex, newValues, containers);
      setFieldValue(GC.FIELD_STOP_PICKED_UP_CONTAINERS, updatedContainers);
      handleBlur(event);

      if (G.isNilOrEmpty(stop)) return;

      const { order, isValid } = stop;

      return setFormDataToStop({ isValid, formData: values, stopOrder: order });
    },
    fieldName: GC.FIELD_CONTAINER_NUMBER,
    label: G.getWindowLocale('titles:container-number', 'Container Number'),
  },
  {
    ...itemCommonProps1,
    type: 'text',
    fieldName: GC.FIELD_EMPTY_CONTAINER_WEIGHT,
    label: G.getWindowLocale('titles:empty-weight', 'Empty Weight'),
  },
  {
    ...itemCommonProps1,
    type: 'text',
    isRequired: true,
    fieldName: GC.FIELD_FULL_CONTAINER_WEIGHT,
    label: G.getWindowLocale('titles:full-weight', 'Full Weight'),
  },
  {
    ...itemCommonProps4,
    type: 'select',
    isRequired: true,
    fieldName: GC.FIELD_WEIGHT_UOM,
    options: DEFAULT_ITEM_WEIGHT_UOM_OPTIONS,
    label: G.getWindowLocale('titles:weight-uom', 'Weight UOM'),
  },
  {
    ...itemCommonProps4,
    type: 'select',
    fieldName: GC.FIELD_RAIL_BILLING_STATUS,
    label: G.getWindowLocale('titles:rail-billing', 'Rail Billing'),
    options: G.prependEmptyLabelValueOption(getRaleBillingEnumOptions()),
  },
  {
    ...itemCommonProps1,
    type: 'text',
    fieldName: GC.FIELD_WIDTH,
    label: G.getWindowLocale('titles:width', 'Width'),
  },
  {
    ...itemCommonProps1,
    type: 'text',
    fieldName: GC.FIELD_HEIGHT,
    label: G.getWindowLocale('titles:height', 'Height'),
  },
  {
    ...itemCommonProps1,
    type: 'text',
    fieldName: GC.FIELD_LENGTH,
    label: G.getWindowLocale('titles:length', 'Length'),
  },
  {
    ...itemCommonProps2,
    type: 'select',
    options: ITEM_DIMENSIONS_OPTIONS,
    fieldName: GC.FIELD_DIMENSIONS_UOM,
    label: G.getWindowLocale('titles:dimensions-uom', 'Dimensions UOM'),
  },
];

export const dropItemStopFields = [
  {
    ...commonProps5,
    inputWrapperStyles: {
      mr: 20,
      zIndex: 20,
    },
    isMulti: true,
    isRequired: true,
    type: 'reactSelect',
    shouldValidate: true,
    closeMenuOnSelect: false,
    useMenuPortalTarget: true,
    options: GC.FIELD_LOAD_ITEMS,
    fieldName: GC.FIELD_LOAD_ITEMS,
    label: G.getWindowLocale('titles:items', 'items'),
  },
];

export const dropContainerStopFields = [
  {
    ...commonProps5,
    inputWrapperStyles: {
      zIndex: 21,
    },
    isMulti: true,
    type: 'reactSelect',
    shouldValidate: true,
    closeMenuOnSelect: false,
    useMenuPortalTarget: true,
    options: 'dropContainerOptions',
    fieldName: GC.FIELD_STOP_DROPPED_CONTAINERS,
    label: G.getWindowLocale('titles:containers', 'Container(s)'),
  },
];

export const trailersStopFields = [
  {
    ...commonProps5,
    inputWrapperStyles: {
      mr: 20,
      zIndex: 21,
    },
    isMulti: true,
    type: 'reactSelect',
    shouldValidate: true,
    closeMenuOnSelect: false,
    useMenuPortalTarget: true,
    options: 'trailersInServiceOptions',
    fieldName: GC.FIELD_STOP_PICKED_UP_TRAILER_GUIDS,
    label: G.getWindowLocale('titles:picked-up', 'Picked Up'),
  },
  {
    ...commonProps5,
    inputWrapperStyles: {
      zIndex: 21,
    },
    isMulti: true,
    type: 'reactSelect',
    shouldValidate: true,
    closeMenuOnSelect: false,
    useMenuPortalTarget: true,
    options: 'trailersInServiceOptions',
    fieldName: GC.FIELD_STOP_DROPPED_TRAILER_GUIDS,
    label: G.getWindowLocale('titles:dropped', 'Dropped'),
  },
];

export const mainReferenceFields = [
  {
    type: 'checkbox',
    shouldCustomChange: true,
    fieldName: GC.FIELD_FAST_LOAD,
    showFieldConfig: GC.UI_SHOW_FAST_LOAD_INDICATOR,
    label: G.getWindowLocale('titles:fast-load', 'Fast Load'),
  },
];

export const specialInstructionsRefFields = [
  {
    p: 15,
    width: 642,
    errorTop: 81,
    type: 'textarea',
    fieldName: GC.FIELD_LOAD_SPECIAL_INSTRUCTIONS,
  },
];

export const internalInstructionsRefFields = [
  {
    p: 15,
    width: 642,
    errorTop: 81,
    type: 'textarea',
    fieldName: GC.FIELD_LOAD_INTERNAL_INSTRUCTIONS,
  },
];

export const referenceFields1 = [
  {
    ...commonProps7,
    isRequired: true,
    type: 'reactSelect',
    options: 'referenceTypes',
    useMenuPortalTarget: true,
    fieldName: GC.FIELD_REFERENCE_TYPE_GUID,
    label: G.getWindowLocale('titles:name', 'Name'),
  },
  {
    ...commonProps7,
    type: 'text',
    isRequired: true,
    fieldName: GC.FIELD_VALUE,
    label: G.getWindowLocale('titles:value', 'Value'),
  },
];

export const referenceFields2 = [
  {
    ...commonProps7,
    isRequired: true,
    type: 'reactSelect',
    options: 'referenceTypes',
    useMenuPortalTarget: true,
    fieldName: GC.FIELD_REFERENCE_TYPE_GUID,
    label: G.getWindowLocale('titles:name', 'Name'),
  },
  {
    ...commonProps7,
    isRequired: true,
    type: 'reactSelect',
    options: 'allowedValues',
    useMenuPortalTarget: true,
    fieldName: GC.FIELD_VALUE,
    label: G.getWindowLocale('titles:value', 'Value'),
  },
];

export const refFieldsForNextStartDate1 = (prefix: string) => [
  {
    ...commonProps7,
    type: 'select',
    isRequired: true,
    options: 'referenceTypes',
    inputWrapperStyles: { mr: 20 },
    label: G.getWindowLocale('titles:name', 'Name'),
    fieldName: `${prefix}.${GC.FIELD_REFERENCE_TYPE_GUID}`,
  },
  {
    ...commonProps7,
    type: 'text',
    isRequired: true,
    fieldName: `${prefix}.${GC.FIELD_VALUE}`,
    label: G.getWindowLocale('titles:value', 'Value'),
  },
];

export const refFieldsForNextStartDate2 = (prefix: string) => [
  {
    ...commonProps7,
    type: 'select',
    isRequired: true,
    options: 'referenceTypes',
    inputWrapperStyles: { mr: 20 },
    label: G.getWindowLocale('titles:name', 'Name'),
    fieldName: `${prefix}.${GC.FIELD_REFERENCE_TYPE_GUID}`,
  },
  {
    ...commonProps7,
    type: 'select',
    isRequired: true,
    options: 'allowedValues',
    inputWrapperStyles: { mr: 20 },
    fieldName: `${prefix}.${GC.FIELD_VALUE}`,
    label: G.getWindowLocale('titles:value', 'Value'),
  },
];

export const containerFieldsForNextStartDate = (prefix: string) => [
  {
    ...commonProps7,
    type: 'text',
    isRequired: true,
    fieldName: `${prefix}.${GC.FIELD_CONTAINER_INITIAL}`,
    label: G.getWindowLocale('titles:container-initial', 'Container Initial'),
  },
  {
    ...commonProps7,
    type: 'text',
    isRequired: true,
    fieldName: `${prefix}.${GC.FIELD_CONTAINER_NUMBER}`,
    label: G.getWindowLocale('titles:container-number', 'Container Number'),
  },
];

export const mainBillToFields = [
  {
    ...commonProps,
    type: 'select',
    isRequired: true,
    name: 'titles:payment-terms',
    fieldName: GC.FIELD_PAYMENT_TERM,
    label: G.getWindowLocale('titles:payment-term', 'Payment Term'),
    options: R.prepend(GC.EMPTY_OPTION_OBJECT, PAYMENT_TERM_OPTIONS),
  },
  {
    ...commonProps,
    type: 'select',
    fieldName: GC.FIELD_LOCATION_TYPE,
    label: G.getWindowLocale('titles:type', 'Type'),
    options: {
      configName: GC.TEMPLATES_LOCATION_TYPE,
    },
  },
  {
    inputWrapperStyles: {
      mr: 20,
      width: 250,
      zIndex: 15,
    },
    type: 'asyncSearch',
    fieldName: GC.FIELD_TEMPLATE_ID,
    label: G.getWindowLocale('titles:search-by-template-id-name', 'Search by Template Id/Name'),
  },
];

export const addressBillToFields = [
  {
    ...commonProps2,
    type: 'text',
    isRequired: true,
    fieldName: GC.FIELD_LOCATION_NAME,
    label: G.getWindowLocale('titles:name', 'Name'),
  },
  {
    width: 160,
    inputWrapperStyles: {
      mr: 20,
      mb: 30,
      zIndex: 15,
      wordBreak: 'break-word',
    },
    isRequired: true,
    type: 'addressAutocomplete',
    fieldName: GC.FIELD_ADDRESS_1,
    label: G.getWindowLocale('titles:address1', 'Address1'),
  },
  {
    ...commonProps2,
    type: 'text',
    fieldName: GC.FIELD_ADDRESS_2,
    label: G.getWindowLocale('titles:address2', 'Address2'),
  },
  {
    width: 160,
    inputWrapperStyles: {
      mr: 20,
      mb: 30,
      zIndex: 15,
      wordBreak: 'break-word',
    },
    isRequired: true,
    omitAddress1: true,
    fieldName: GC.FIELD_CITY,
    type: 'addressAutocomplete',
    label: G.getWindowLocale('titles:city', 'City'),
  },
  {
    ...commonProps2,
    type: 'text',
    isRequired: true,
    fieldName: GC.FIELD_STATE,
    label: G.getWindowLocale('titles:state', 'State'),
  },
  {
    ...commonProps2,
    type: 'text',
    fieldName: GC.FIELD_ZIP,
    label: G.getWindowLocale('titles:postal-code', 'Postal Code'),
  },
  {
    ...commonProps,
    isRequired: true,
    type: 'countrySelect',
    fieldName: GC.FIELD_COUNTRY,
    label: G.getWindowLocale('titles:country', 'Country'),
  },
  {
    ...commonProps6,
    type: 'text',
    fieldName: GC.FIELD_COMMENTS,
    label: G.getWindowLocale('titles:comments', 'Comments'),
  },
];

const handleChangeTransportationMode = (event: Object, _: any, props: Object) => {
  const {
    values,
    setValues,
    handleChange,
    transportationModeGroupingList,
  } = props;

  const { services, equipment } = values;

  const value = G.getEventTargetValue(event);

  if (G.notHas(value, transportationModeGroupingList)) return handleChange(event);

  const {
    services: availableServices,
    equipments: availableEquipments,
  } = R.prop(value, transportationModeGroupingList);

  const filter = (current: any, available: Object) => {
    if (G.isAnyTrue(
      R.isEmpty(available),
      G.isNilOrEmpty(current),
      R.includes(current, available),
    )) return current;

    const filtered = R.filter((item: string) => R.includes(item, available), current);

    return G.ifElse(R.isEmpty(filtered), filtered, null);
  };

  const newValues = R.mergeRight(
    values,
    {
      [GC.FIELD_MODE]: value,
      [GC.FIELD_SERVICES]: filter(services, availableServices),
      [GC.FIELD_LOAD_EQUIPMENT]: filter(equipment, availableEquipments),
    },
  );

  return setValues(newValues);
};

export const mainCustomerRateFields = [
  {
    ...commonProps8,
    type: 'select',
    isRequired: true,
    fieldName: GC.FIELD_CURRENCY,
    options: GC.CURRENCY_OPTIONS,
    label: G.getWindowLocale('titles:currency', 'Currency'),
  },
  {
    ...commonProps8,
    type: 'select',
    isRequired: true,
    options: GC.FIELD_MODE,
    fieldName: GC.FIELD_MODE,
    shouldCustomChange: true,
    label: G.getWindowLocale('titles:mode', 'Mode'),
    customChangeHandler: handleChangeTransportationMode,
  },
  {
    ...commonProps8,
    type: 'select',
    options: GC.FIELD_SERVICE_TYPE,
    fieldName: GC.FIELD_SERVICE_TYPE,
    label: G.getWindowLocale('titles:service-type', 'Service Type'),
  },
  {
    width: 170,
    type: 'text',
    inputWrapperStyles: { mr: 0 },
    fieldName: GC.FIELD_SERVICE_DAYS,
    label: G.getWindowLocale('titles:service-days', 'Service Days'),
  },
];

export const equipmentServicesFields = [
  {
    type: 'reactSelect',
    options: 'equipmentOptions',
    shouldScrollIntoView: false,
    fieldName: GC.FIELD_LOAD_EQUIPMENT,
    label: G.getWindowLocale('titles:equipment', 'Equipment'),
    inputWrapperStyles: {
      mt: 25,
      mr: 20,
      zIndex: 12,
      width: 280,
    },
  },
  {
    isMulti: true,
    type: 'reactSelect',
    options: 'serviceOptions',
    shouldScrollIntoView: false,
    fieldName: GC.FIELD_SERVICES,
    label: G.getWindowLocale('titles:services', 'Services'),
    inputWrapperStyles: {
      mr: 0,
      mt: 25,
      zIndex: 12,
      width: 430,
    },
  },
];

export const totalCustomerRateFields = [
  {
    ...commonProps9,
    type: 'text',
    isRequired: true,
    fieldName: GC.FIELD_TOTAL_TRIP_DISTANCE,
    label: G.getWindowLocale('titles:total-distance', 'Total Distance'),
  },
  {
    ...commonProps10,
    type: 'select',
    isRequired: true,
    fieldName: GC.FIELD_TOTAL_TRIP_DISTANCE_UOM,
    options: DEFAULT_DISTANCE_RATE_UNIT_OPTIONS,
    label: G.getWindowLocale('titles:distance-uom', 'Distance UOM'),
  },
  {
    ...commonProps9,
    type: 'text',
    isRequired: true,
    fieldName: GC.FIELD_TOTAL_TRIP_WEIGHT,
    label: G.getWindowLocale('titles:total-weight', 'Total Weight'),
  },
  {
    ...commonProps10,
    type: 'select',
    isRequired: true,
    options: DEFAULT_ITEM_WEIGHT_UOM_OPTIONS,
    fieldName: GC.FIELD_TOTAL_TRIP_WEIGHT_UOM,
    label: G.getWindowLocale('titles:weight-uom', 'Weight UOM'),
  },
  {
    ...commonProps9,
    type: 'text',
    fieldName: GC.FIELD_TOTAL_PICKUP_QUANTITY,
    label: G.getWindowLocale('titles:pickup-quantity', 'Pickup Quantity'),
  },
  {
    width: 116,
    type: 'select',
    inputWrapperStyles: { mr: 0 },
    fieldName: GC.FIELD_PACKAGE_TYPE,
    options: DEFAULT_QUANTITY_RATE_UNIT_OPTIONS,
    label: G.getWindowLocale('titles:package-type', 'Package Type'),
  },
];

export const contactCustomerRateFields = [
  {
    ...commonProps8,
    type: 'text',
    fieldName: GC.FIELD_CONTACT_NAME,
    label: G.getWindowLocale('titles:contact-name', 'Contact Name'),
  },
  {
    ...commonProps8,
    type: 'phoneNumber',
    fieldName: GC.FIELD_PHONE,
    label: G.getWindowLocale('titles:phone', 'Phone'),
  },
  {
    ...commonProps8,
    type: 'number',
    fieldName: GC.FIELD_PHONE_EXTENSION,
    label: G.getWindowLocale('titles:phone-extension', 'Phone Extension'),
  },
  {
    width: 170,
    type: 'text',
    fieldName: GC.FIELD_EMAIL,
    inputWrapperStyles: { mr: 0 },
    label: G.getWindowLocale('titles:email', 'Email'),
  },
  {
    width: 170,
    type: 'text',
    fieldName: GC.FIELD_FAX,
    label: G.getWindowLocale('titles:fax-number', 'Fax Number'),
    inputWrapperStyles: {
      mt: 25,
      mr: 15,
    },
  },
  {
    width: 540,
    type: 'text',
    inputWrapperStyles: {
      mr: 0,
      mt: 25,
    },
    fieldName: GC.FIELD_COMMENTS,
    label: G.getWindowLocale('titles:comments', 'Comments'),
  },
];

export const maxTripRateFields = R.of(Array, {
  ...commonProps8,
  type: 'text',
  fieldName: GC.FIELD_TRIP_MAX_PAY,
  label: G.getWindowLocale('titles:trip-max-pay', 'Trip Max Pay'),
});

export const allCustomerRateFields = [
  ...mainCustomerRateFields,
  ...totalCustomerRateFields,
  ...contactCustomerRateFields,
];

// contacts
export const getDefaultContactFields = () => ({
  [GC.FIELD_FAX]: null,
  [GC.FIELD_EMAIL]: null,
  [GC.FIELD_PHONE]: null,
  [GC.FIELD_ID]: G.genShortId(),
  [GC.FIELD_CONTACT_NAME]: null,
  [GC.FIELD_PHONE_EXTENSION]: null,
});

export const contactFieldSettings = [
  {
    ...commonProps3,
    width: 240,
    type: 'text',
    fieldName: GC.FIELD_CONTACT_NAME,
    label: G.getWindowLocale('titles:contact-name', 'Contact Name'),
  },
  {
    width: 200,
    type: 'phoneNumber',
    fieldName: GC.FIELD_PHONE,
    inputWrapperStyles: { mr: 20 },
    label: G.getWindowLocale('titles:phone', 'Phone'),
  },
  {
    ...commonProps3,
    width: 100,
    type: 'number',
    fieldName: GC.FIELD_PHONE_EXTENSION,
    label: G.getWindowLocale('titles:phone-extension', 'Phone Extension'),
  },
  {
    width: 240,
    type: 'text',
    fieldName: GC.FIELD_EMAIL,
    label: G.getWindowLocale('titles:email', 'Email'),
    inputWrapperStyles: {
      mr: 20,
      mt: 30,
    },
  },
  {
    width: 200,
    type: 'text',
    fieldName: GC.FIELD_FAX,
    label: G.getWindowLocale('titles:fax-number', 'Fax Number'),
    inputWrapperStyles: {
      mr: 20,
      mt: 30,
    },
  },
];
