import * as R from 'ramda';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// utilities
import { sendRequest } from '../../utilities/http';
import endpointsMap from '../../utilities/endpoints';
// feature payroll
import * as C from './constants';
//////////////////////////////////////////////////

export const getInitialDates = () => ({
  dateTo: G.getCurrentDay(),
  dateFrom: G.getStartOfMonthDay(),
});

export const loadInvoices = async function (props: Object, driver: Object) {
  const data = {
    invoiceGuids: driver.invoiceGuids,
    [GC.FIELD_BRANCH_GUID]: props.branchGuid,
  };
  const res = await sendRequest('post', endpointsMap.driverPayrollInvoices, { data });
  let driverState = driver;
  if (G.isResponseSuccess(res.status)) {
    const initialInvoicesProps = [{
      name: 'selected',
      value: G.isTrue(driver.selected),
    }];
    driverState = R.mergeRight(
      driverState,
      {
        invoices: R.indexBy(
          R.prop(GC.FIELD_GUID),
          G.setPropsWithValuesToArrayItems(
            res.data,
            initialInvoicesProps,
          ),
        ),
        invoicesLoading: false,
      },
    );
  } else {
    driverState = R.mergeRight(
      driverState,
      {
        invoicesError: true,
        invoicesLoading: false,
      },
    );
  }
  props.updateDrivers(R.assoc(driver.driverGuid, driverState, props.drivers));
};

export const loadVendorInvoices = async function (props: Object, vendor: Object) {
  const data = {
    invoiceGuids: vendor.invoiceGuids,
    [GC.FIELD_BRANCH_GUID]: props.branchGuid,
  };
  const res = await sendRequest('post', endpointsMap.vendorPayrollInvoices, { data });
  let vendorState = vendor;
  if (G.isResponseSuccess(res.status)) {
    const initialInvoicesProps = [{
      name: 'selected',
      value: G.isTrue(vendor.selected),
    }];
    vendorState = R.mergeRight(
      vendorState,
      {
        invoices: R.indexBy(
          R.prop(GC.FIELD_GUID),
          G.setPropsWithValuesToArrayItems(
            res.data,
            initialInvoicesProps,
          ),
        ),
        invoicesLoading: false,
      },
    );
  } else {
    vendorState = R.mergeRight(
      vendorState,
      {
        invoicesError: true,
        invoicesLoading: false,
      },
    );
  }
  props.updateVendors(R.assoc(R.prop(GC.FIELD_FLEET_VENDOR_GUID, vendor), vendorState, props.vendors));
};

export const getPayrollsOnAllTogglePayrollSelect = (drivers: Object, checked: boolean, propName: string) => R.indexBy(
  R.prop(propName), R.map(
    (driver: Object) => ({
      ...driver,
      'selected': R.not(checked),
    }),
    R.values(drivers),
));

export const getPayrollsOnTogglePayrollSelect = (driver: Object, drivers: Object, propName: string) => R.assocPath(
  [R.prop(propName, driver), 'selected'],
  R.not(driver.selected),
  drivers,
);

export const getDriverOnToggleInvoiceSelect = (driver: Object, invoice: Object) => {
  const driverData = R.assocPath(
    ['invoices', invoice.guid, 'selected'],
    R.not(invoice.selected),
    driver,
  );
  const payrollCurrency = R.pathOr(
    GC.DEFAULT_UI_CURRENCY,
    [GC.FIELD_CURRENCY],
    driverData,
  );

  return R.mergeRight(
    driverData,
    {
      allSelected: R.all(
        R.prop('selected'),
        R.values(driverData.invoices),
      ),
      invoicesTotal: R.sum(R.map(
        (invoice: Object) => {
          const { total, currency, selected } = invoice;
          if (R.not(selected)) return 0;

          if (G.notEquals(currency, payrollCurrency)) {
            return G.convertCurrencyByExchangeRate(total, currency, payrollCurrency);
          }

          return total;
        },
        R.values(driverData.invoices),
      )),
    },
  );
};

export const getUpdatedInvoices = (driver: Object, updatedInvoice: Object) => R.map(
  (invoice: Object) => {
    if (R.eqProps(GC.FIELD_GUID, invoice, updatedInvoice)) {
      return R.mergeRight(
        invoice,
        R.pick(
          [
            GC.FIELD_MODE,
            GC.FIELD_CURRENCY,
            GC.FIELD_INVOICE_DATE,
            GC.FIELD_CHARGE_TOTAL,
            GC.FIELD_INVOICE_STATUS,
            GC.FIELD_TOTAL_TRIP_WEIGHT,
            GC.FIELD_DEADHEAD_DISTANCE,
            GC.FIELD_TOTAL_TRIP_DISTANCE,
            GC.FIELD_DEADHEAD_DISTANCE_UOM,
            GC.FIELD_TOTAL_TRIP_WEIGHT_UOM,
            GC.FIELD_TOTAL_TRIP_DISTANCE_UOM,
          ],
          updatedInvoice,
        ),
      );
    }

    return invoice;
  },
  driver.invoices,
);

export const recalculateDriverInvoices = (drivers: Array, updatedInvoice: Object) => R.map(
  (driver: Object) => {
    if (driver.expanded) {
      const invoices = getUpdatedInvoices(driver, updatedInvoice);
      const invoicesTotal = R.sum(R.map(
        (inv: Object) => G.ifElse(
          inv.selected,
          inv.total,
          0,
        ),
        R.values(invoices),
      ));

      return R.mergeRight(driver, { invoices, invoicesTotal });
    }
    return driver;
  },
  drivers,
);

export const convertPayroll = (payroll: Object) => (
  R.assoc(
    'invoiceGuids',
    G.ifElse(
      payroll.allSelected,
      payroll.invoiceGuids,
      R.map(
        (p: Object) => p.guid,
        R.filter(
          (payroll: Object) => payroll.selected,
          R.values(payroll.invoices),
        ),
      ),
    ),
    R.pick([
      GC.FIELD_DATE_TO,
      GC.FIELD_CURRENCY,
      GC.FIELD_DATE_FROM,
      GC.FIELD_DRIVER_GUID,
      GC.FIELD_PAYROLL_NUMBER,
    ], payroll),
  )
);

export const getPayrollsData = (payrolls: Object) => (
  R.map(convertPayroll, payrolls)
);

export const convertVendorPayroll = (payroll: Object) => (
  R.assoc(
    'invoiceGuids',
    G.ifElse(
      payroll.allSelected,
      payroll.invoiceGuids,
      R.map(
        (payroll: Object) => G.getGuidFromObject(payroll),
        R.filter(
          (payroll: Object) => payroll.selected,
          R.values(payroll.invoices),
        ),
      ),
    ),
    R.pick([
      GC.FIELD_CURRENCY,
      GC.FIELD_PAYROLL_NUMBER,
      GC.FIELD_PAYROLL_DATE_TO,
      GC.FIELD_PAYROLL_DATE_FROM,
      GC.FIELD_FLEET_VENDOR_GUID,
    ], payroll),
  )
);

export const getVendorPayrollsData = (payrolls: Object) => (
  R.map(convertVendorPayroll, payrolls)
);

export const validateCreatePayroll = (payrolls: Object, autogenerate: boolean) => (
  G.ifElse(
    autogenerate,
    true,
    R.all(
      (payroll: Object) => G.isNotEmpty(payroll.payrollNumber),
      payrolls,
    ),
  )
);

export const getUpdatePayrollRequestData = (payroll: Object) => (
  {
    [GC.FIELD_PAYROLL_GUID]: G.getGuidFromObject(payroll),
    invoiceGuids: R.map(
      (invoice: Object) => G.getGuidFromObject(invoice),
      R.filter(
        (invoice: Object) => invoice.selected,
        R.values(payroll.invoices),
      ),
    ),
  }
);

export const getExportPayrollReportFields = (filterParams: Array) => filterParams.map((prop: Object, i: number) => ({
  sequence: i,
  name: prop.value,
  displayName: prop.name,
}));

export const composeObjectPropsToString = (
  omitFields: Array = [],
  withoutValues: Array = [],
  joiner: string = '',
) => R.compose(
  R.toLower(),
  R.join(joiner),
  R.without(withoutValues),
  R.values(),
  R.omit(omitFields),
);

export const mapListObjectPropsToPropString = (filterPropName: string, composeProps: Object) => (
    R.map(
  (item: Object) => (
      R.assoc(
    filterPropName,
    composeObjectPropsToString(
      R.prop('omitFields', composeProps),
      R.prop('withoutValues', composeProps),
      R.prop('joiner', composeProps),
    )(R.values(item)),
    item,
  ))));

export const filterListOfObjectsByPropContains = (
  filterString: string,
  filterPropName: string,
) => R.filter(
  (item: Object) => R.propSatisfies((prop: string) => R.includes(R.toLower(filterString), prop), filterPropName, item),
);

export const getInvoiceChargesFromResponse = (charges: Array = []) => ({
  mainChargesTotal: G.getChargeTotalFromCharges(charges, GC.CHARGE_TYPE_MAIN),
  fuelChargesTotal: G.getChargeTotalFromCharges(charges, GC.CHARGE_TYPE_FUEL),
  additionalChargesTotal: G.getChargeTotalFromCharges(charges, GC.CHARGE_TYPE_ADDITIONAL),
});

const getChargesGuids = R.compose(
  R.map(G.getGuidFromObject),
  R.filter(({ removed }: Object) => R.not(removed)),
);

const getExcludedChargesGuids = R.compose(
  R.map(G.getGuidFromObject),
  R.filter(({ selected, removed }: Object) => R.and(R.not(selected), R.not(removed))),
);

export const getChargesData = (charges: Array) => {
  const guids = getChargesGuids(charges);
  const excludedGuids = getExcludedChargesGuids(charges);
  return { guids, excludedGuids };
};

const getExcludedLineGuids = R.compose(
  R.map(G.getGuidFromObject),
  R.filter(({ selected }: Object) => R.not(selected)),
  R.reduce((acc: Array, fuelCard: Object) => R.concat(acc, fuelCard.details), []),
  R.filter(({ removed, selected }: Object) => R.and(selected, R.not(removed))),
);

export const getFuelCardsData = (fuelCards: Array) => {
  const { guids, excludedGuids } = getChargesData(fuelCards);
  const excludedLineGuids = getExcludedLineGuids(fuelCards);
  return {
    guids,
    excludedGuids,
    excludedLineGuids,
  };
};

export const getDriverPayrollsData = (driverPayrolls: Array) => ({
  guids: R.compose(
    R.map(G.getGuidFromObject),
    R.filter(R.prop('selected')),
  )(driverPayrolls),
});

// UI
export const getTabs = (activeTab: number) => [
  {
    withCount: R.equals(activeTab, 0),
    text: G.getWindowLocale('titles:driver-payrolls', 'Driver Payrolls'),
  },
  {
    withCount: R.equals(activeTab, 1),
    text: `${G.getWindowLocale('titles:pivot', 'Pivot')} ${
      G.getWindowLocale('titles:payrolls', 'Payrolls')}`,
  },
  {
    withCount: R.equals(activeTab, 2),
    text: `${G.getWindowLocale('titles:payroll', 'Payroll')} ${
      G.getWindowLocale('titles:charges', 'Charges')}`,
  },
  {
    withCount: R.equals(activeTab, 3),
    text: G.getWindowLocale('titles:vendor-payrolls', 'Vendor Payrolls'),
  },
  {
    withCount: R.equals(activeTab, 4),
    text: `${G.getWindowLocale('titles:pivot', 'Pivot')} ${
      G.getWindowLocale('titles:vendor-payrolls', 'Vendor Payrolls')}`,
  },
  {
    withCount: R.equals(activeTab, 5),
    text: `${G.getWindowLocale('titles:vendor-payroll', 'Vendor Payroll')} ${
      G.getWindowLocale('titles:charges', 'Charges')}`,
  },
];

const getSubTotal = (arr: Array = [], payrollCurrency: string) => R.compose(
  G.mathRoundNumber,
  R.sum,
  R.map(({ total, currency }: Object) => G.convertCurrencyByExchangeRate(total, currency, payrollCurrency)),
)(arr);

const currencies = ['USD', 'CAD', 'MXN', 'EUR'];

const getInvoicesTotals = R.compose(
  R.map(({ currency, invoicesTotal }: Object) => ({
    currency,
    total: invoicesTotal,
  })),
  R.filter(({ invoicesTotal }: Object) => R.and(G.isNotNilAndNotEmpty(invoicesTotal), G.isNotZero(invoicesTotal))),
);

const getMainChargesTotals = R.compose(
  R.map(({ currency, mainChargesTotal }: Object) => ({
    currency,
    total: mainChargesTotal,
  })),
  R.filter(({ mainChargesTotal }: Object) => R.and(
    G.isNotZero(mainChargesTotal),
    G.isNotNilAndNotEmpty(mainChargesTotal),
  )),
);

const getFuelCardsTotals = (fuelCards: Array) => R.compose(
  R.filter(({ total }: Object) => G.isNotZero(total)),
  R.map((currency: string) => ({
    currency,
    total: R.compose(
      G.mathRoundNumber,
      G.calculateFuelCardsTotal,
      R.filter(R.propEq(currency, GC.FIELD_CURRENCY)),
    )(fuelCards),
  })),
)(currencies);

const getTollChargesTotals = (tollCharges: Array) => R.compose(
  R.filter(({ total }: Object) => G.isNotZero(total)),
  R.map((currency: string) => ({
    currency,
    [GC.FIELD_TOTAL]: R.compose(
      G.mathRoundNumber,
      G.calculateTollChargesTotal,
      R.filter(R.propEq(currency, GC.FIELD_CURRENCY)),
    )(tollCharges),
  })),
)(currencies);

const getDriverOrVendorDeductionTotals = (charges: Array, payrollCurrency: string) => R.compose(
  R.filter(({ total }: Object) => G.isNotZero(total)),
  R.map((currency: string) => ({
    currency,
    total: R.compose(
      G.mathRoundNumber,
      G.calculatePayrollDriverDeduction,
      R.filter((charge: Object) => R.or(
        R.propEq(currency, GC.FIELD_CURRENCY, charge),
        R.and(R.equals(currency, payrollCurrency), G.isNilOrEmpty(R.prop(GC.FIELD_CURRENCY, charge))),
      )),
    )(charges),
  })),
)(currencies);

const getAdditionalPayrollPayTotals = (charges: Array, payrollCurrency: string) => R.compose(
  R.filter(({ total }: Object) => G.isNotZero(total)),
  R.map((currency: string) => ({
    currency,
    total: R.compose(
      G.mathRoundNumber,
      G.calculateChargesTotalExcludeDeductions,
      R.filter((charge: Object) => R.or(
        R.propEq(currency, GC.FIELD_CURRENCY, charge),
        R.and(R.equals(currency, payrollCurrency), G.isNilOrEmpty(R.prop(GC.FIELD_CURRENCY, charge))),
      )),
    )(charges),
  })),
)(currencies);

const getExpensesTotals = (expenses: Array) => R.compose(
  R.filter(({ total }: Object) => G.isNotZero(total)),
  R.map((currency: string) => ({
    currency,
    [GC.FIELD_TOTAL]: R.compose(
      G.mathRoundNumber,
      G.calculateExpensesTotal,
      R.filter(R.propEq(currency, GC.FIELD_CURRENCY)),
    )(expenses),
  })),
)(currencies);

const getGrandTotals = (incomeSubtotals: Array, deductionsTotals: Array) => R.compose(
  R.filter(({ total }: Object) => G.isNotZero(total)),
  R.map((currency: string) => {
    const income = R.find(R.propEq(currency, GC.FIELD_CURRENCY), incomeSubtotals);
    const deduction = R.find(R.propEq(currency, GC.FIELD_CURRENCY), deductionsTotals);
    const total = G.mathRoundNumber(R.subtract(
      R.pathOr(0, [GC.FIELD_TOTAL], income),
      R.pathOr(0, [GC.FIELD_TOTAL], deduction),
    ));

    return { total, currency };
  }),
)(currencies);

const getDriverPayrollIncomeSubtotals = (
  useMainCharges: boolean,
  mainChargesTotals: Array,
  invoicesTotals: Array,
  additionalPayrollPayTotals: Array,
) => R.compose(
  R.filter(({ total }: Object) => G.isNotZero(total)),
  R.map((currency: string) => {
    const arrayToUse = G.ifElse(useMainCharges, mainChargesTotals, invoicesTotals);
    const additionalPayrollPay = R.find(R.propEq(currency, GC.FIELD_CURRENCY), additionalPayrollPayTotals);
    const total = R.compose(
      G.mathRoundNumber,
      R.add(R.pathOr(0, [GC.FIELD_TOTAL], additionalPayrollPay)),
      R.pathOr(0, [GC.FIELD_TOTAL]),
      R.find(R.propEq(currency, GC.FIELD_CURRENCY)),
    )(arrayToUse);

    return { total, currency };
  }),
)(currencies);

const getDeductionsTotals = (totals: Array) => R.compose(
  R.filter(({ total }: Object) => G.isNotZero(total)),
  R.map((currency: string) => ({
    currency,
    total: R.compose(
      G.mathRoundNumber,
      R.sum,
      R.map(
        (entity: Array) => {
          const exists = R.find(R.propEq(currency, GC.FIELD_CURRENCY), entity);

          return R.pathOr(0, [GC.FIELD_TOTAL], exists);
        },
      ),
    )(totals),
  })),
)(currencies);

const getAvailableCurrencies = R.compose(
  R.uniq,
  R.map(R.prop(GC.FIELD_CURRENCY)),
  R.flatten,
  R.values,
);

export const getDriverPayrollTotalsByCurrency = (props: Object) => {
  const {
    values,
    expenses,
    fuelCards,
    tollCharges,
    useMainCharges,
    advancePayments,
    asyncInitialData,
  } = props;

  const payrollTotals = R.pathOr([], ['data', 'payrollTotals'], asyncInitialData);

  const payrollCurrency = R.pathOr(
    GC.DEFAULT_UI_CURRENCY,
    [GC.FIELD_CURRENCY],
    values,
  );

  const charges = R.pathOr([], [GC.FIELD_PAYROLL_CHARGES], values);
  const invoicesTotals = getInvoicesTotals(payrollTotals);
  const mainChargesTotals = getMainChargesTotals(payrollTotals);
  const fuelCardsTotals = getFuelCardsTotals(fuelCards);
  const tollChargesTotals = getTollChargesTotals(tollCharges);
  const advancedPaymentsTotal = G.calculateAdvancePaymentsTotal(advancePayments);
  const expensesTotals = getExpensesTotals(expenses);

  const advancePaymentsTotals = G.ifElse(
    G.isZero(advancedPaymentsTotal),
    [],
    R.of(Array, {
      currency: payrollCurrency,
      total: advancedPaymentsTotal,
    }),
  );

  const driverDeductionTotals = getDriverOrVendorDeductionTotals(charges, payrollCurrency);
  const additionalPayrollPayTotals = getAdditionalPayrollPayTotals(charges, payrollCurrency);

  const incomeSubtotals = getDriverPayrollIncomeSubtotals(
    useMainCharges,
    mainChargesTotals,
    invoicesTotals,
    additionalPayrollPayTotals,
  );

  const deductionsTotals = getDeductionsTotals([
    fuelCardsTotals,
    tollChargesTotals,
    advancePaymentsTotals,
    driverDeductionTotals,
    expensesTotals,
  ]);

  const grandTotals = getGrandTotals(incomeSubtotals, deductionsTotals);

  const currencyTotals = {
    grandTotals,
    invoicesTotals,
    expensesTotals,
    fuelCardsTotals,
    incomeSubtotals,
    deductionsTotals,
    mainChargesTotals,
    tollChargesTotals,
    advancePaymentsTotals,
    driverDeductionTotals,
    additionalPayrollPayTotals,
  };

  const payrollCurrencyTotals = {
    grandTotal: getSubTotal(grandTotals, payrollCurrency),
    invoicesTotal: getSubTotal(invoicesTotals, payrollCurrency),
    expensesTotal: getSubTotal(expensesTotals, payrollCurrency),
    fuelCardsTotal: getSubTotal(fuelCardsTotals, payrollCurrency),
    incomeSubtotal: getSubTotal(incomeSubtotals, payrollCurrency),
    deductionsTotal: getSubTotal(deductionsTotals, payrollCurrency),
    mainChargesTotal: getSubTotal(mainChargesTotals, payrollCurrency),
    tollChargesTotal: getSubTotal(tollChargesTotals, payrollCurrency),
    advancePaymentsTotal: getSubTotal(advancePaymentsTotals, payrollCurrency),
    driverDeductionTotal: getSubTotal(driverDeductionTotals, payrollCurrency),
    additionalPayrollPayTotal: getSubTotal(additionalPayrollPayTotals, payrollCurrency),
  };

  const availableCurrencies = getAvailableCurrencies(currencyTotals);

  return {
    availableCurrencies,
    payrollCurrencyTotals,
    currencyTotals: R.map(R.indexBy(R.prop(GC.FIELD_CURRENCY)), currencyTotals),
  };
};

const getDriverPayrollsTotals = (driverPayrolls: Array) => R.compose(
  R.filter(({ total }: Object) => G.isNotZero(total)),
  R.map((currency: string) => ({
    currency,
    total: R.compose(
      G.mathRoundNumber,
      R.sum,
      R.map(({ useMainCharges, invoiceTotal, mainChargeTotal }: Object) => G.ifElse(
        useMainCharges,
        R.or(mainChargeTotal, 0),
        R.or(invoiceTotal, 0),
      )),
      R.filter(R.propEq(currency, GC.FIELD_CURRENCY)),
      R.reduce((acc: Array, totalCurrencies: Array) => R.concat(acc, totalCurrencies), []),
      R.map(({ totalCurrencies, mainChargesTotal }: Object) => {
        const useMainCharges = G.isNotNilAndNotZero(mainChargesTotal);

        return R.map(R.assoc('useMainCharges', useMainCharges), totalCurrencies);
      }),
      R.filter(R.prop('selected')),
    )(driverPayrolls),
  })),
)(currencies);

const getVendorPayrollIncomeSubtotals = (invoicesTotals: Array, additionalPayrollPayTotals: Array) => R.compose(
  R.filter(({ total }: Object) => G.isNotZero(total)),
  R.map((currency: string) => {
    const additionalPayrollPay = R.find(R.propEq(currency, GC.FIELD_CURRENCY), additionalPayrollPayTotals);
    const total = R.compose(
      G.mathRoundNumber,
      R.add(R.pathOr(0, [GC.FIELD_TOTAL], additionalPayrollPay)),
      R.pathOr(0, [GC.FIELD_TOTAL]),
      R.find(R.propEq(currency, GC.FIELD_CURRENCY)),
    )(invoicesTotals);

    return { total, currency };
  }),
)(currencies);

export const getVendorPayrollTotalsByCurrency = (props: Object) => {
  const {
    values,
    expenses,
    fuelCards,
    tollCharges,
    driverPayrolls,
    asyncInitialData,
  } = props;

  const payrollTotals = R.pathOr([], ['data', 'payrollTotals'], asyncInitialData);

  const payrollCurrency = R.pathOr(
    GC.DEFAULT_UI_CURRENCY,
    [GC.FIELD_CURRENCY],
    values,
  );

  const charges = R.pathOr([], [GC.FIELD_PAYROLL_CHARGES], values);
  const invoicesTotals = getInvoicesTotals(payrollTotals);
  const fuelCardsTotals = getFuelCardsTotals(fuelCards);
  const tollChargesTotals = getTollChargesTotals(tollCharges);
  const vendorDeductionTotals = getDriverOrVendorDeductionTotals(charges, payrollCurrency);
  const additionalPayrollPayTotals = getAdditionalPayrollPayTotals(charges, payrollCurrency);
  const expensesTotals = getExpensesTotals(expenses);

  const incomeSubtotals = getVendorPayrollIncomeSubtotals(
    invoicesTotals,
    additionalPayrollPayTotals,
  );

  const driverPayrollsTotals = getDriverPayrollsTotals(driverPayrolls);

  const deductionsTotals = getDeductionsTotals([
    fuelCardsTotals,
    tollChargesTotals,
    driverPayrollsTotals,
    vendorDeductionTotals,
    expensesTotals,
  ]);

  const grandTotals = getGrandTotals(incomeSubtotals, deductionsTotals);

  const currencyTotals = {
    grandTotals,
    invoicesTotals,
    expensesTotals,
    fuelCardsTotals,
    incomeSubtotals,
    deductionsTotals,
    tollChargesTotals,
    driverPayrollsTotals,
    vendorDeductionTotals,
    additionalPayrollPayTotals,
  };

  const payrollCurrencyTotals = {
    grandTotal: getSubTotal(grandTotals, payrollCurrency),
    invoicesTotal: getSubTotal(invoicesTotals, payrollCurrency),
    expensesTotal: getSubTotal(expensesTotals, payrollCurrency),
    fuelCardsTotal: getSubTotal(fuelCardsTotals, payrollCurrency),
    incomeSubtotal: getSubTotal(incomeSubtotals, payrollCurrency),
    deductionsTotal: getSubTotal(deductionsTotals, payrollCurrency),
    tollChargesTotal: getSubTotal(tollChargesTotals, payrollCurrency),
    driverPayrollsTotal: getSubTotal(driverPayrollsTotals, payrollCurrency),
    vendorDeductionTotal: getSubTotal(vendorDeductionTotals, payrollCurrency),
    additionalPayrollPayTotal: getSubTotal(additionalPayrollPayTotals, payrollCurrency),
  };

  const availableCurrencies = getAvailableCurrencies(currencyTotals);

  return {
    availableCurrencies,
    payrollCurrencyTotals,
    currencyTotals: R.map(R.indexBy(R.prop(GC.FIELD_CURRENCY)), currencyTotals),
  };
};

export const getPayrollModalWithContent = (component: any) => ({
  p: '0',
  component,
  options: {
    top: 35,
    minWidth: 600,
    height: 'auto',
    maxWidth: '95vw',
    boxShadow: 'none',
    width: 'fit-content',
    backgroundColor: 'none',
  },
});

export const getUpdatePayrollInvoicesModalWithContent = (component: any) => ({
  p: '0',
  component,
  options: {
    top: 35,
    minWidth: 600,
    height: 'auto',
    maxWidth: '95vw',
    maxHeight: '95vh',
    boxShadow: 'none',
    width: 'fit-content',
    backgroundColor: 'none',
  },
});

export const getPayrollStatisticListAndCurrency = (resData: Object) => {
  const { data, currency } = resData;

  let statisticList = [];

  if (G.isNotNilAndNotEmpty(data)) {
    statisticList = R.map(
      (item: Object) => {
        const chargeFields = R.compose(
          R.fromPairs,
          R.map(([key, value]: Array) => [`sumByAssessorial.${key}`, value]),
          R.toPairs,
          R.pathOr({}, [GC.FIELD_CHARGES]),
        )(item);

        return R.mergeRight(item, chargeFields);
      },
      data,
    );
  }

  return { statisticList, statisticCurrency: currency };
};

