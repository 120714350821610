import * as R from 'ramda';
import React from 'react';
// components
import { TextComponent } from '../../../components/text';
// features
import { AuthWrapper } from '../../permission';
import PC from '../../permission/role-permission';
// helpers/constants
import * as G from '../../../helpers';
// ui
import { Box, Flex } from '../../../ui';
// feature new-do
import SectionDivider from './section-divider';
import {
  mapReferencesWithRefTypes,
  getMappedServicesFromConfigsByOptionGuid,
  getMappedEquipmentFromConfigsByOptionGuid,
} from '../helpers';
//////////////////////////////////////////////////

const InfoSection = ({ infoText, dividerText }: Object) => (
  <Box width='100%'>
    <SectionDivider text={dividerText} />
    <Box px={15} mb={15} wordBreak='break-all'>{infoText}</Box>
  </Box>
);

const ArraySection = ({ items, dividerText }: Object) => (
  <Box width='100%'>
    <SectionDivider text={dividerText} />
    {
      items.map((item: Object, index: Object) => (
        <Flex mb='5px' px={15} key={`${item}${index}`}>
          <TextComponent title={item} withEllipsis={true}>
            {item}
          </TextComponent>
        </Flex>
      ))
    }
  </Box>
);

const RateInfo = ({ title, total }: Object) => (
  <Box>
    <Flex>
      <Box mr='5px' fontWeight={600}>
        {title}
      </Box>
      <Box>{total}</Box>
    </Flex>
  </Box>
);

const getChargesTotalFromRateWithCurrency = (rate: Object) => (
  `
    ${G.getCurrencySymbol(G.getCurrencyFromRate(rate))}
    ${G.getCalcRateChargesTotal(G.getChargesFromObject(rate), G.getCurrencyFromObject2(rate))}
  `
);

const RateSection = (props: Object) => {
  const { rate, driverRate, carrierRate } = props;

  return (
    <Flex
      p={15}
      mt={15}
      width='100%'
      bg={G.getTheme('colors.veryLightGrey')}
      justifyContent={G.ifElse(R.or(G.isNotNil(driverRate), G.isNotNil(carrierRate)), 'space-around', 'start')}
    >
      {
        rate &&
        <RateInfo
          total={getChargesTotalFromRateWithCurrency(rate)}
          title={`${G.getWindowLocale('titles:order', 'Order', { caseAction: 'upperCase' })}
            ${G.getWindowLocale('titles:rate-total', 'Rate Total', { caseAction: 'upperCase' })}:`}
        />
      }
      {
        carrierRate &&
        <AuthWrapper has={[PC.FLEET_RATE_READ, PC.FLEET_RATE_WRITE]}>
          <RateInfo
            total={G.getTelChargesTotalInfo(carrierRate)}
            title={`${G.getWindowLocale('titles:carrier-rate', 'Carrier Rate', { caseAction: 'upperCase' })}
              ${G.getWindowLocale('titles:total', 'Total', { caseAction: 'upperCase' })}:`}
          />
        </AuthWrapper>
      }
      {
        driverRate &&
        <AuthWrapper has={[PC.CARRIER_RATE_READ, PC.CARRIER_RATE_WRITE]}>
          <RateInfo
            total={G.getTelChargesTotalInfo(driverRate)}
            title={`${G.getWindowLocale('titles:driver-rate', 'Driver Rate', { caseAction: 'upperCase' })}
              ${G.getWindowLocale('titles:total', 'Total', { caseAction: 'upperCase' })}:`}
          />
        </AuthWrapper>
      }
    </Flex>
  );
};

const getReferences = (props: Object) => {
  const { branchRefTypes, referenceFormData, customerRefTypeName, customerReferenceValue } = props;

  const { references } = referenceFormData;
  const mappedReferences = R.map(
    (item: Object) => `${item.name}: ${item.value}`,
    R.reject(
      (item: Object) => R.or(G.isNilOrEmpty(item.value), G.isNilOrEmpty(item.name)),
      mapReferencesWithRefTypes(references, branchRefTypes),
    ),
  );

  if (G.isNotNilAndNotEmpty(customerReferenceValue)) {
    return R.prepend(`${customerRefTypeName}: ${customerReferenceValue}`, mappedReferences);
  }

  return mappedReferences;
};

const AdditionalSummary = (props: Object) => {
  const { rate, branchConfigs, referenceFormData } = props;

  const { equipment, services } = R.or(rate, {});

  const { specialInstructions, internalInstructions } = referenceFormData;


  const mappedEquipment = G.getDisplayedValueFromObject(
    getMappedEquipmentFromConfigsByOptionGuid(equipment, branchConfigs),
  );
  const mappedServices = R.map(
    (item: Object) => G.getDisplayedValueFromObject(item),
    getMappedServicesFromConfigsByOptionGuid(services, branchConfigs),
  );
  const mappedReferences = getReferences(props);

  return (
    <Flex flexDirection='column'>
      {
        G.isNotNilAndNotEmpty(mappedReferences) &&
        <ArraySection
          items={mappedReferences}
          dividerText={G.getWindowLocale(
            'titles:references',
            'References',
            { caseAction: 'upperCase' },
          )}
        />
      }
      {
        specialInstructions &&
        <InfoSection
          infoText={specialInstructions}
          dividerText={G.getWindowLocale(
            'titles:special-instructions',
            'Special Instructions',
            { caseAction: 'upperCase' },
          )}
        />
      }
      {
        internalInstructions &&
        <InfoSection
          infoText={internalInstructions}
          dividerText={G.getWindowLocale(
            'titles:internal-instructions',
            'Internal Instructions',
            { caseAction: 'upperCase' },
          )}
        />
      }
      {
        equipment &&
        <InfoSection
          infoText={mappedEquipment}
          dividerText={G.getWindowLocale(
            'titles:equipment',
            'Equipment',
            { caseAction: 'upperCase' },
          )}
        />
      }
      {
        G.isNotNilAndNotEmpty(mappedServices) &&
        <ArraySection
          items={mappedServices}
          dividerText={G.getWindowLocale(
            'titles:services',
            'Services',
            { caseAction: 'upperCase' },
          )}
        />
      }
      <RateSection {...props} />
    </Flex>
  );
};

export default AdditionalSummary;
