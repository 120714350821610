import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { OuterClick } from 'react-outer-click';
import { createStructuredSelector } from 'reselect';
import {
  compose,
  withState,
  withHandlers,
} from 'react-recompose';
// components
import { openModal, closeModal } from '../../../../components/modal/actions';
// features
import { makeSelectStyling } from '../../../styling/selectors';
import NotificationModal from '../../../notification/components/popup';
import { makeSelectUnreadCount } from '../../../notification/selectors';
// helpers/constants
import * as G from '../../../../helpers';
// icons
import * as I from '../../../../svgs';
// ui
import { RelativeBox, AbsoluteBox } from '../../../../ui';
// feature navbar-menu
import NavbarButton from './navbar-button';
//////////////////////////////////////////////////

const enhance = compose(
  withState('showPopup', 'setShowPopup', false),
  withHandlers({
    onShowPopup: ({ setShowPopup }: Object) => (e: Event) => {
      G.stopPropagation(e);
      setShowPopup(true);
    },
    onHidePopup: ({ setShowPopup }: Object) => (e: Event) => {
      G.stopPropagation(e);
      setShowPopup(false);
    },
  }),
);

const Notifications = ({
  styling,
  showPopup,
  onShowPopup,
  onHidePopup,
  unreadNotificationsCount,
}: Object) => {
  const iconsColor = R.pathOr(G.getTheme('colors.light.mainLight'), ['textColor'], styling);

  return (
    <React.Fragment>
      <RelativeBox
        cursor='pointer'
        width='max-content'
        height='max-content'
        onMouseDown={onShowPopup}
        title={G.getWindowLocale('titles:notifications', 'Notifications')}
      >
        <NavbarButton
          icon={I.bellIcon}
          iconsColor={iconsColor}
          text={G.getWindowLocale('titles:notifications', 'Notifications')}
        />
        <AbsoluteBox
          p='2px'
          top='-4px'
          left='20px'
          zIndex={10}
          height='18px'
          fontSize='9px'
          minWidth='18px'
          border='1px solid'
          color={iconsColor}
          borderRadius='50px'
          width='max-content'
          pointerEvents='none'
          justifyContent='center'
          borderColor={iconsColor}
          bg={styling.backgroundColor}
        >
          {unreadNotificationsCount}
        </AbsoluteBox>
      </RelativeBox>
      { showPopup &&
        <OuterClick onOuterClick={onHidePopup}>
          <NotificationModal
            toggleShowPopup={onHidePopup}
          />
        </OuterClick>
      }
    </React.Fragment>
  );
};

const mapStateToProps = (state: Object) => (createStructuredSelector({
  styling: makeSelectStyling(state),
  unreadNotificationsCount: makeSelectUnreadCount(state),
}));

export default connect(mapStateToProps, {
  openModal,
  closeModal,
})(enhance(Notifications));
