import * as R from 'ramda';
import React from 'react';
// helpers/constants
import * as G from '../../../helpers';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex, FlexHovered } from '../../../ui';
//////////////////////////////////////////////////

const PrevBtn = (props: Object) => (
  <FlexHovered
    p='5px 10px'
    fontSize={15}
    cursor='pointer'
    borderRadius='8px'
    display='inline-flex'
    transition='all 0.5s'
    justifyContent='flex-end'
    onClick={props.handleClickPrev}
    bgColor={G.getTheme('colors.bgBlue')}
    color={G.getTheme('colors.dark.blue')}
  >
    <Box transform='rotate(180deg)'>
      {I.renderSimpleRightArrowIcon(G.getTheme('colors.dark.blue'))}
    </Box>
    <Box ml={10}>
      {G.getWindowLocale('actions:previous', 'Previous')}
    </Box>
  </FlexHovered>
);

const getNextBtnText = (text: string) => G.ifElse(
  G.isNotNilAndNotEmpty(text),
  text,
  G.getWindowLocale('actions:next', 'Next'),
);

const NextBtn = (props: Object) => (
  <FlexHovered
    p='5px 10px'
    fontSize={15}
    cursor='pointer'
    borderRadius='8px'
    display='inline-flex'
    transition='all 0.5s'
    justifyContent='flex-end'
    onClick={props.submitForm}
    bgColor={G.getTheme('colors.bgBlue')}
    color={G.getTheme('colors.dark.blue')}
  >
    <Box mr={10}>
      {getNextBtnText(props.nextBtnText)}
    </Box>
    {I.renderSimpleRightArrowIcon(G.getTheme('colors.dark.blue'))}
  </FlexHovered>
);

const PrevNextAction = (props: Object) => (
  <Flex
    p={10}
    borderTop='1px solid'
    mt={R.or(props.mt, 0)}
    borderColor={G.getTheme('colors.dark.grey')}
    justifyContent={G.ifElse(props.handleClickPrev, 'space-between', 'flex-end')}
  >
    {props.handleClickPrev && <PrevBtn {...props} />}
    {props.showNext && <NextBtn {...props} />}
  </Flex>
);

export default PrevNextAction;
