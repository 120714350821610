import * as R from 'ramda';
import { call, put, race, select, take, takeLatest } from 'redux-saga/effects';
// common
import { initialDataLoadFail, initialDataLoadSuccess } from '../../common/actions';
// components
import { closeLoader, openLoader } from '../../components/loader/actions';
// features
import PC from '../permission/role-permission';
import { makeSelectCurrentUserGuid } from '../auth/selectors';
import { makeSelectAccessToPagesList, makeSelectInitialDataLoadedStatus } from '../permission/selectors';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// sagas
import { visitPageSaga } from '../../sagas';
// utilities
import { sendRequest } from '../../utilities/http';
import endpointsMap from '../../utilities/endpoints';
// feature user-settings
import * as A from './actions';
import { makeSelectUserSettings } from './selectors';
//////////////////////////////////////////////////

function* getCurrentUserSettingsSaga() {
  try {
    const guid = yield select(makeSelectCurrentUserGuid());
    const url = endpointsMap.getUserWithProfileEndpoint(guid);
    const res = yield call(sendRequest, 'get', url);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getCurrentUserSettingsSuccess(data));
    } else {
      yield put(A.getCurrentUserSettingsFail());
      yield call(G.handleFailResponse, res, 'getCurrentUserSettingsSaga fail');
    }
  } catch (error) {
    yield put(A.getCurrentUserSettingsFail());
    yield call(G.handleException, error, 'getCurrentUserSettingsSaga exception');
  }
}

function* changePinnedMenuItemsSaga({ payload }: Object) {
  const enterpriseGuid = G.getCurrentBranchGuid();

  try {
    yield put(openLoader({ showDimmer: true }));

    const res = yield call(sendRequest, 'put', endpointsMap.changePinnedMenuItems, {
      data: payload,
      params: {
        enterpriseGuid,
      },
    });

    const { status, data } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.changePinnedMenuItemsSuccess(payload));
    } else {
      yield call(G.handleException, data.error, 'changeUserPinnedItems fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield call(G.handleException, error, 'changeUserPinnedItems fail');

    yield put(closeLoader());
  }
}

function* getUserReferencesSaga(guid: string) {
  try {
    if (G.hasNotAmousCurrentUserPermissions([PC.ROLES_READ, PC.ROLES_WRITE])) return false;

    const options = {
      params: {
        userGuid: guid,
      },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.userReferenceList, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getUserReferencesSuccess(R.indexBy(R.prop('guid'), data)));
    } else {
      yield put(A.getUserReferencesFail());
      if (G.notEquals(data.error, GC.ACCESS_DENIED)) {
        yield call(G.handleFailResponse, res, 'getUserReferencesSaga fail');
      }
    }
  } catch (error) {
    yield put(A.getUserReferencesFail());
    yield call(G.handleException, error, 'getUserReferencesSaga exception');
  }
}

function* getUserProfileRolesSagas(guid: string) {
  try {
    if (G.hasNotAmousCurrentUserPermissions([PC.ROLES_READ, PC.ROLES_WRITE])) return false;

    const options = {
      params: {
        userGuid: guid,
      },
    };
    const res = yield call(sendRequest, 'get', endpointsMap.userRoles, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getUserProfileRolesSuccess(R.indexBy(R.prop('guid'), data)));
    } else {
      yield put(A.getUserProfileRolesFail());
      if (G.notEquals(data.error, GC.ACCESS_DENIED)) {
        yield call(G.handleFailResponse, res, 'getUserProfileRolesSagas fail');
      }
    }
  } catch (error) {
    yield put(A.getUserProfileRolesFail());
    yield call(G.handleException, error, 'getUserProfileRolesSagas exception');
  }
}

function* handleGetAvailableByUserTypeRoleListRequest({ payload }: Object) {
  try {
    if (G.hasNotAmousCurrentUserPermissions([PC.ROLES_READ, PC.ROLES_WRITE])) return false;

    const res = yield call(sendRequest, 'get', endpointsMap.availableRolesByType, { params: payload });
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getAvailableByUserTypeRoleListSuccess(data));
    } else {
      yield call(G.handleException, 'error', 'handleGetAvailableByUserTypeRoleListRequest exception');
    }
  } catch (error) {
    yield call(G.handleException, error, 'handleGetAvailableByUserTypeRoleListRequest exception');
  }
}

function* getUserSettingsSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    yield call(getUserReferencesSaga, payload);
    yield call(getUserProfileRolesSagas, payload);
    const url = yield call(endpointsMap.getUserWithProfileEndpoint, payload);
    const res = yield call(sendRequest, 'get', url);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      const userSettings = G.ifElse(
        R.propEq(GC.USER_ROLE_TYPE_CARRIER, GC.FIELD_USER_TYPE, data),
        R.assoc(GC.FIELD_DEFAULT_ROUTE_TYPE, GC.FIELD_TEL, data),
        data,
      );
      yield put(A.getUserSettingsSuccess(userSettings));
      yield call(
        handleGetAvailableByUserTypeRoleListRequest,
        {
          payload: {
            [GC.BRANCH_GUID]: R.prop(GC.BRANCH_GUID, data),
            [GC.FIELD_ROLE_ROLE_TYPE]: R.propOr(GC.USER_ROLE_TYPE_GENERAL, GC.FIELD_USER_TYPE, data)
          },
        },
      );
    } else {
      yield put(A.getUserSettingsFail());
      yield call(G.handleFailResponse, res, 'getUserSettingsSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield put(A.getUserSettingsFail());
    yield call(G.handleException, error, 'getUserSettingsSaga exception');
  }
}

const makeDataForUpdateSettings = ({ payload }: Object) => {
  const keysMap = {
    guid: 'userGuid',
    profileGuid: 'guid',
    profileVersion: 'version',
  };
  return G.renameKeys(keysMap, payload.values);
};

function* handleUpdateSettingsRequest(action: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const data = yield call(makeDataForUpdateSettings, action);
    const { userGuid } = data;
    const options = { data };
    const res = yield call(sendRequest, 'put', endpointsMap.updateUserSettings, options);
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getUserSettingsRequest(userGuid));
      yield put(A.getCurrentUserSettingsRequest());
      yield call(G.showToastrMessage, 'success', 'messages:profile-updated-success');
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateSettingsRequest fail', true);
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleUpdateSettingsRequest exception');
  }
}

function* handleCreateUserReferenceSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const options = { data: payload };
    const res = yield call(sendRequest, 'post', endpointsMap.userReference, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.createUserReferenceSuccess(data[0]));
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateUserReferenceSaga fail', true);
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleCreateUserReferenceSaga exception');
  }
}

function* handleUpdateUserReferenceSaga(action: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const options = {
      data: action.payload,
    };
    const res = yield call(sendRequest, 'put', endpointsMap.userReference, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.updateUserReferenceSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateUserReferenceSaga fail', true);
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleUpdateUserReferenceSaga exception');
  }
}

function* handleDeleteUserReferenceSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const url = yield call(endpointsMap.getRemoveUserRefEndpoint, payload);
    const res = yield call(sendRequest, 'delete', url);
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.deleteUserReferenceSuccess(payload));
    } else {
      yield call(G.handleFailResponse, res, 'handleDeleteUserReferenceSaga fail', true);
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleDeleteUserReferenceSaga exception');
  }
}

function* handleAssignUserProfileRolesSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const res = yield call(sendRequest, 'post', endpointsMap.assignRoles, { data: payload });
    const { status } = res;
    const currentUserGuid = yield select(makeSelectCurrentUserGuid());
    if (G.isResponseSuccess(status)) {
      yield call(getUserProfileRolesSagas, R.head(payload.usersGuids));
      if (R.equals(currentUserGuid, R.head(payload.usersGuids))) {
        yield call(G.showToastrMessage, 'info', 'messages:login:applied');
      }
    } else {
      yield call(G.handleFailResponse, res, 'handleAssignUserProfileRolesSaga fail', true);
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleAssignUserProfileRolesSaga exception');
  }
}

function* handleRevokeUserProfileRolesSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const res = yield call(
      sendRequest,
      'post',
      endpointsMap.revokeRoles,
      { data: payload },
    );
    const { status } = res;
    const currentUserGuid = yield select(makeSelectCurrentUserGuid());
    if (G.isResponseSuccess(status)) {
      yield call(getUserProfileRolesSagas, R.head(payload.usersGuids));
      if (R.equals(currentUserGuid, R.head(payload.usersGuids))) {
        yield call(G.showToastrMessage, 'info', 'messages:login:applied');
      }
    } else {
      yield call(G.handleFailResponse, res, 'handleRevokeUserProfileRolesSaga fail', true);
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleRevokeUserProfileRolesSaga exception');
  }
}

function* updateUserPasswordSaga(options: Object) {
  const res = yield call(sendRequest, 'post', endpointsMap.updatePassword, options);
  const { status } = res;
  if (G.isResponseSuccess(status)) {
    yield call(G.showToastrMessage, 'success', 'messages:update:success');
  } else {
    yield call(G.handleFailResponse, res, 'updateUserPasswordSaga fail', true);
  }
}

function* resetUserPasswordSaga(options: Object) {
  const res = yield call(sendRequest, 'put', endpointsMap.resetPassword, options);
  const { status } = res;
  if (G.isResponseSuccess(status)) {
    yield call(G.showToastrMessage, 'success', 'messages:update:success');
  } else {
    yield call(G.handleFailResponse, res, 'resetUserPasswordSaga fail', true);
  }
}

function* handleChangeUserPasswordSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const settings = yield select(makeSelectUserSettings());
    const userGuid = R.path(['guid'], settings);
    const options = {
      data: {
        userGuid,
        ...payload,
      },
    };
    if (R.isNil(payload.oldPassword)) {
      yield call(resetUserPasswordSaga, options);
    } else {
      yield call(updateUserPasswordSaga, options);
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleChangeUserPasswordSaga exception');
  }
}

function* handleChangeUserSuspendedStatusSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const { guid, active } = payload;
    let url;
    if (active) {
      url = endpointsMap.getUserSuspendEndpoint(guid);
    } else {
      url = endpointsMap.getUserResumeEndpoint(guid);
    }
    const res = yield call(sendRequest, 'put', url);
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.changeUserSuspendedStatusSuccess());
      yield call(
        G.showToastrMessage, 'success', 'messages:update:success');
    } else {
      yield call(G.handleFailResponse, res, 'handleChangeUserSuspendedStatusSaga fail', true);
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleChangeUserSuspendedStatusSaga exception');
  }
}

function* handlePinBranchSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const params = { [GC.FIELD_BRANCH_GUID]: payload.guid };
    const endpoint = G.ifElse(
      payload.pinned,
      endpointsMap.unpinBranch,
      endpointsMap.pinBranch,
    );
    const res = yield call(sendRequest, 'put', endpoint, { params });
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.pinBranchSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handlePinBranchSaga');
    }
    yield put(closeLoader());
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handlePinBranchSaga exception');
  }
}

function* handleVisitUserEditSaga({ payload }: Object) {
  while (true) { // eslint-disable-line
    const { route, guid } = payload;

    const accessToPagesList = yield select(makeSelectAccessToPagesList());
    const isInitialDataLoaded = yield select(makeSelectInitialDataLoadedStatus());
    const isAllowed = R.includes(route, accessToPagesList);
    if (R.not(isInitialDataLoaded)) {
      yield race({
        fail: take(initialDataLoadFail),
        success: take(initialDataLoadSuccess),
      });
    }

    if (R.not(isAllowed)) {
      yield put({ type: GC.CHECK_VISIT_USER_EDIT_PAGE, payload: route });
      yield take(GC.CHECK_VISIT_USER_EDIT_PAGE_ALLOWED);
    }

    yield put(A.getUserSettingsFail());

    yield call(getUserSettingsSaga, { payload: guid });
    break;
  }
}

function* handleVisitCurrentUserProfile({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield call(visitPageSaga, payload, GC.CHECK_VISIT_USER_PROFILE_PAGE);

    yield put(A.getUserSettingsFail());

    const currentUserGuid = yield select(makeSelectCurrentUserGuid());

    yield call(getUserSettingsSaga, { payload: currentUserGuid });
    break;
  }
}

export function* userSettingsWatcherSaga() {
  yield takeLatest(A.sendPinBranchRequest, handlePinBranchSaga);
  yield takeLatest(A.getUserSettingsRequest, getUserSettingsSaga);
  yield takeLatest(GC.VISIT_USER_EDIT_PAGE, handleVisitUserEditSaga);
  yield takeLatest(A.getUserReferencesRequest, getUserReferencesSaga);
  yield takeLatest(A.updateUserSettingsRequest, handleUpdateSettingsRequest);
  yield takeLatest(A.changePinnedMenuItemsRequest, changePinnedMenuItemsSaga);
  yield takeLatest(A.changeUserPasswordRequest, handleChangeUserPasswordSaga);
  yield takeLatest(A.getCurrentUserSettingsRequest, getCurrentUserSettingsSaga);
  yield takeLatest(A.createUserReferenceRequest, handleCreateUserReferenceSaga);
  yield takeLatest(A.updateUserReferenceRequest, handleUpdateUserReferenceSaga);
  yield takeLatest(A.deleteUserReferenceRequest, handleDeleteUserReferenceSaga);
  yield takeLatest(GC.VISIT_CURRENT_USER_PROFILE, handleVisitCurrentUserProfile);
  yield takeLatest(A.assignUserProfileRolesRequest, handleAssignUserProfileRolesSaga);
  yield takeLatest(A.revokeUserProfileRolesRequest, handleRevokeUserProfileRolesSaga);
  yield takeLatest(A.changeUserSuspendedStatusRequest, handleChangeUserSuspendedStatusSaga);
}

export default userSettingsWatcherSaga;
