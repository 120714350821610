import * as R from 'ramda';
import titleCase from 'voca/title_case';
import React, { useState, useEffect } from 'react';
// components
import { TextComponent } from '../../../components/text';
// icons
import * as I from '../../../svgs';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// utilities
import routesMap from '../../../utilities/routes';
// ui
import { Flex, Badge, BoxHovered } from '../../../ui';
// feature notification2
import { hidePopup, getItemRoute } from '../helpers';
//////////////////////////////////////////////////

const lightBlack = G.getTheme('colors.light.black');
const darkGrey = G.getTheme('colors.dark.darkGrey');
const greenColor = G.getTheme('colors.light.green');
const blackColor = G.getTheme('colors.light.black');

export const IconStatusComponent = (props: Object) => {
  const { warningType, type } = props;

  const icons = {
    INFO: 'default',
    CRITICAL: 'red',
    WARNING: 'yellow',
  };

  if (R.equals(type, 'DRIVER')) {
    return (
      <Flex
        width={32}
        height={32}
        fontWeight='bold'
        borderRadius='50%'
        border='1px solid'
        color={lightBlack}
        justifyContent='center'
        borderColor={lightBlack}
      >
        {'AK'}
      </Flex>
    );
  }

  return <Badge variant={icons[warningType]}>{titleCase(warningType)}</Badge>;
};

const GroupComponent = (props: Object) => {
  const {
    name,
    type,
    objectGuid,
    actionHide,
    setExternalIdFilter,
  } = props;

  const typeGroup = {
    [GC.NOTIFICATION_OBJECT_TYPE_TEL]: {
      locale: ['titles:tel', 'TEL'],
      route: routesMap.dispatchDetailsLoad,
      color: G.getTheme('colors.light.red'),
    },
    [GC.NOTIFICATION_OBJECT_TYPE_CLO]: {
      color: greenColor,
      locale: ['titles:clo', 'CLO'],
      route: routesMap.dispatchDetailsOrder,
    },
    [GC.NOTIFICATION_OBJECT_TYPE_EDI]: {
      color: greenColor,
      route: routesMap.ediList,
      locale: ['titles:edi', 'EDI'],
    },
    [GC.NOTIFICATION_OBJECT_TYPE_DRIVER]: {
      color: blackColor,
      locale: ['titles:driver', 'Driver'],
      route: routesMap[G.getFleetProfileRoutePathNameByFleetType()],
    },
    [GC.NOTIFICATION_OBJECT_TYPE_IFTA]: {
      color: blackColor,
      route: routesMap.iftaReport,
      locale: ['titles:ifta', 'IFTA'],
    },
    [GC.NOTIFICATION_OBJECT_TYPE_TRUCK]: {
      color: blackColor,
      locale: ['titles:truck', 'Truck'],
      route: routesMap[G.getFleetProfileRoutePathNameByFleetType('truck')],
    },
    [GC.NOTIFICATION_OBJECT_TYPE_TRAILER]: {
      color: blackColor,
      locale: ['titles:trailer', 'Trailer'],
      route: routesMap[G.getFleetProfileRoutePathNameByFleetType('trailer')],
    },
    [GC.NOTIFICATION_OBJECT_TYPE_CARRIER]: {
      color: blackColor,
      route: routesMap.carrierEdit,
      locale: ['titles:carrier', 'CARRIER'],
    },
    [GC.NOTIFICATION_OBJECT_TYPE_ORDER_QUOTE]: {
      color: blackColor,
      route: routesMap.orderQuotes,
      locale: ['titles:order-quote', 'Order Quote'],
    },
  };

  const groupLocale = R.pathOr([], [type, 'locale'], typeGroup);
  const groupText = G.getWindowLocale(...groupLocale);

  const handleClick = (e: Object) => {
    if (R.equals(type, GC.NOTIFICATION_OBJECT_TYPE_EDI)) setExternalIdFilter(name);

    hidePopup(actionHide, e);
    G.goToRoute(getItemRoute(type, objectGuid, typeGroup));
  };

  return (
    <Flex flexDirection='column'>
      <BoxHovered
        cursor='pointer'
        borderRadius={30}
        onClick={handleClick}
        hoverBackgroundColor='#d6d6d6'
        transition='background-color 0.8s'
      >
        <TextComponent
          width={150}
          title={name}
          p='4px 10px'
          fontSize={14}
          display='block'
          textAlign='center'
          withEllipsis={true}
          color={R.pathOr(greenColor, [type, 'color'], typeGroup)}
        >
          {`${groupText}: ${name}`}
        </TextComponent>
      </BoxHovered>
    </Flex>
  );
};

export const NotificationItem = (props: Object) => {
  const {
    read,
    message,
    displayId,
    actionHide,
    objectGuid,
    objectType,
    createdDate,
    borderStyle,
    withoutGroup,
    warningLevel,
    setExternalIdFilter,
    markAsReadNotification,
    notificationItemHeight,
  } = props;
  const [dateTimeFormat, setDateTimeFormat] = useState('');

  const handleMarkAsReadNotification = () => {
    G.callFunctionWithArgs(markAsReadNotification, props);
  };

  useEffect(() => {
    const dateTimeFormatFromConfig = G.getDateTimeFormatFromConfig();

    const format = G.ifElse(
      R.equals(dateTimeFormatFromConfig, GC.DEFAULT_US_MILITARY_DATE_TIME_FORMAT),
      GC.DEFAULT_MILITARY_TIME_FORMAT,
      GC.DEFAULT_TIME_FORMAT,
    );

    setDateTimeFormat(format);
  }, []);

  return (
    <Flex
      p='18px 15px'
      borderBottom={borderStyle}
      height={notificationItemHeight}
      width={G.ifElse(G.isTrue(withoutGroup), 550, 700)}
      bg={G.getThemeByCond(G.isFalse(read), 'colors.bgLightBlue', 'colors.white')}
    >
      <Flex
        mr={10}
        minWidth={95}
        color='darkGrey'
      >
        <Flex mr='9px'>
          {I.time(darkGrey, 20, 20)}
        </Flex>
        <TextComponent>
          {G.createLocalDateTimeFromInstanceOrISOString(createdDate, dateTimeFormat)}
        </TextComponent>
      </Flex>
      <Flex mr={10} minWidth={75}>
        <IconStatusComponent warningType={warningLevel} type={objectType} />
      </Flex>
      {
        R.not(withoutGroup) &&
        <GroupComponent
          name={displayId}
          type={objectType}
          driverGuid={false}
          actionHide={actionHide}
          objectGuid={objectGuid}
          setExternalIdFilter={setExternalIdFilter}
        />
      }
      <TextComponent
        mx={10}
        maxWidth={385}
        display='block'
        title={message}
        withEllipsis={true}
        p='10px 0 10px 10px'
        borderLeft='1px solid'
        color={G.getTheme('colors.light.black')}
        cursor={G.isFalse(read) ? 'pointer' : 'default'}
        borderColor={G.getTheme('colors.light.middleGrey')}
        onClick={G.isFalse(read) ? handleMarkAsReadNotification : null}
      >
        {message}
      </TextComponent>
    </Flex>
  );
};

export default NotificationItem;
