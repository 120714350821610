import * as R from 'ramda';
import * as P from 'plow-js';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { pure, compose, withState, withHandlers, lifecycle } from 'react-recompose';
// components
import { openModal, closeModal } from '../../../components/modal/actions';
import { openLoader, closeLoader } from '../../../components/loader/actions';
// features
import { withDispatchCarrierRate } from '../../mail-sending/hocs';
import { createDriverOnDriversCardRequest } from '../../drivers-card/actions';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// forms
import { LocationTemplateSearch } from '../../../forms/forms/template-search/components/location-template-search';
// hocs
import { withAsyncDistanceCalc, withAsyncSearchTemplateAutocomplete } from '../../../hocs';
// utilities
import { sendRequest } from '../../../utilities/http';
import endpointsMap from '../../../utilities/endpoints';
// feature rate
import { carrierOptionFields } from '../carrier/settings';
import IntegrationErrors from '../components/integration-errors';
import {
  createTelRateActionRequest,
  updateTelRateActionRequest,
  createTelCarrierRateActionRequest,
  updateTelCarrierRateActionRequest,
} from '../actions';
import {
  mapDriversToOptions,
  mapEquipmentsToOptions,
  mapCarrierRatesWithProperQuantity,
} from '../helpers';
//////////////////////////////////////////////////
import { withFleetAssignment } from './with-fleet-assigment';
//////////////////////////////////////////////////

class CarrierRates extends Component {
  constructor(props: Object) {
    super(props);
    this.state = {
      loading: true,
      rateInfo: null,
      carrierRates: [],
    };
    this.getAutoRatesRequest = this.getAutoRatesRequest.bind(this);
    this.getTelRateCarrierRatingInfoRequest = this.getTelRateCarrierRatingInfoRequest.bind(this);
  }

  async getAutoRatesRequest(props: Object) {
    const {
      telGuid,
      loadData,
      equipments,
      branchGuid,
      openLoader,
      serviceType,
      closeLoader,
      transportationMode,
      customerEnterpriseGuid,
    } = props;

    const currentUserBranchGuid = G.getAmousCurrentUserBranchGuidFromWindow();
    const options = {
      data: {
        loadData,
        equipments,
        serviceType,
        loadGuid: telGuid,
        transportationMode,
        customerEnterpriseGuid,
        [GC.BRANCH_GUID]: R.or(branchGuid, currentUserBranchGuid),
      },
    };
    G.callFunction(openLoader);

    const res = await sendRequest('post', endpointsMap.telAvailableCarriersRate, options);

    const { data, status } = res;

    if (R.and(G.isResponseSuccess(status), G.isNotEmpty(data))) {
      const rates = data.rateData.map((data: Object) => R.mergeRight(data, R.prop(GC.SYSTEM_OBJECT_CARRIER_SNAPSHOT, data)));
      const mapped = mapCarrierRatesWithProperQuantity(rates);
      const newState = P.$all(
        P.$set('loading', false),
        P.$set('rateInfo', data),
        P.$set('carrierRates', mapped),
        this.state,
      );
      this.setState(newState);
    } else {
      this.setState(P.$set('loading', false, this.state));
      G.handleFailResponseSimple(
        res,
        true,
        'withAsyncCarrierRates -> getAutoRatesRequest',
      );
    }

    G.callFunction(closeLoader);
  }

  async getTelRateCarrierRatingInfoRequest(props: Object) {
    const {
      telGuid,
      loadData,
      equipments,
      branchGuid,
      openLoader,
      serviceType,
      closeLoader,
      transportationMode,
      customerEnterpriseGuid,
    } = props;

    const currentUserBranchGuid = G.getAmousCurrentUserBranchGuidFromWindow();
    const options = {
      data: {
        loadData,
        equipments,
        serviceType,
        loadGuid: telGuid,
        transportationMode,
        customerEnterpriseGuid,
        [GC.BRANCH_GUID]: R.or(branchGuid, currentUserBranchGuid),
      },
    };
    G.callFunction(openLoader);

    const res = await sendRequest('post', endpointsMap.telRateCarrierRatingInfo, options);

    const { data, status } = res;

    if (R.and(G.isResponseSuccess(status), G.isNotEmpty(data))) {
      const newState = P.$all(
        P.$set('loading', false),
        P.$set('rateInfo', data),
        this.state,
      );
      this.setState(newState);
    } else {
      this.setState(P.$set('loading', false, this.state));
      G.handleFailResponseSimple(
        res,
        false,
        'withAsyncCarrierRates -> getTelRateCarrierRatingInfoRequest',
      );
    }

    G.callFunction(closeLoader);
  }

  render() {
    return (
      <div>
        {
          this.props.render(
            this.state,
            this.getAutoRatesRequest,
            this.getTelRateCarrierRatingInfoRequest,
          )
        }
      </div>
    );
  }
}

const getTelGuid = (props: Object) => R.or(
  props.telGuid,
  R.path([GC.FIELD_TEL, GC.FIELD_GUID], props),
);

const getTelBranchGuid = (props: Object) => R.or(
  R.or(props.branchGuid, R.path([GC.FIELD_TEL, GC.FIELD_BRANCH_GUID], props)),
  R.path([GC.FIELD_TEL, GC.FIELD_BRANCH, GC.FIELD_GUID], props),
);

export const withAsyncCarrierRates = (Component: any) => {
  return class extends React.Component {
    render() {
      const telGuid = getTelGuid(this.props);
      const branchGuid = getTelBranchGuid(this.props);

      return (
        <CarrierRates
          telGuid={telGuid}
          branchGuid={branchGuid}
          loadData={this.props.loadData}
          openLoader={this.props.openLoader}
          equipments={this.props.equipments}
          closeLoader={this.props.closeLoader}
          serviceType={this.props.serviceType}
          transportationMode={this.props.transportationMode}
          customerEnterpriseGuid={this.props.customerEnterpriseGuid}
          render={(
            parentState: Object,
            getAutoRatesRequest: Function,
            getTelRateCarrierRatingInfoRequest: Function,
          ) => (
            <Component
              {...this.props}
              loading={parentState.loading}
              rateInfo={parentState.rateInfo}
              carrierRates={parentState.carrierRates}
              getAutoRatesRequest={getAutoRatesRequest}
              getTelRateCarrierRatingInfoRequest={getTelRateCarrierRatingInfoRequest}
            />
          )}
        />
      );
    }
  };
};

const setSearchCriteria = (props: Object, searchText: string = null) => {
  const defaultFilter = [
    {
      operation: 'equal',
      dataType: 'boolean',
      booleanValue: true,
      propertyName: GC.FIELD_CARRIER_ACTIVE,
    },
  ];

  if (G.isNotNil(searchText)) {
    const searchFilter = {
      dataType: 'string',
      operation: 'contain',
      stringValue: searchText,
      propertyName: props.item.fieldName,
    };

    return R.concat(defaultFilter, R.of(Array, searchFilter));
  }

  return defaultFilter;
};

const setRequestParams = (props: Object, isPagination: boolean = false, searchText: string) => ({
  [GC.FIELD_CURRENT_BRANCH]: props.branchGuid,
  limit: 10,
  offset: G.ifElse(
    R.and(isPagination, G.isNotEmpty(props.options)),
    R.length(props.options),
    0,
  ),
  orderFields: [
    {
      sequence: 1,
      reference: false,
      name: GC.FIELD_CARRIER_NAME,
      order: 'ASC',
    },
  ],
  searchCriteria: setSearchCriteria(props, searchText),
  fields: carrierOptionFields.map(({ name, collection }: Object, sequence: number) => ({
    name,
    sequence,
    freezed: false,
    reference: false,
    collection: false,
  })),
});

async function loadCarrierOptions(props: Object) {
  const reqData = setRequestParams(props);
  const res = await sendRequest('post', endpointsMap.carrierListParentAndCurrent, { data: reqData });
  const { data } = res;
  props.setCarrierOptions(data.results);
  props.setTotalCount(data.totalCount);
  props.setLoadingStatus(false);
}

export async function loadTerminalOptions(
  setTerminalOptions: Function,
  carrierGuid: string,
  setLoadingStatus: Function,
  setFieldValue: Function,
) {
  const params = { carrierGuid };
  const res = await sendRequest('get', endpointsMap.carrierRateTerminalList, { params });
  const { data } = res;
  R.forEach(
    (terminal: Object) => {
      if (terminal.primary) {
        setFieldValue(`${GC.FIELD_PREFIX_CARRIER_ASSIGNMENT}.${GC.FIELD_CARRIER_TERMINAL_GUID}`, terminal.guid);
      }
    },
    data,
  );
  setTerminalOptions(data);
  setLoadingStatus(false);
}

async function paginationLoadOptions(props: Object) {
  const reqData = setRequestParams(props, true);
  const res = await sendRequest('post', endpointsMap.carrierListParentAndCurrent, { data: reqData });
  const { data } = res;
  const newOptions = R.concat(props.entityList, R.or(data.results, []));
  props.setCarrierOptions(newOptions);
  props.setLoadingStatus(false);
}

async function asyncSearch(props: Object, searchText: string) {
  const reqData = setRequestParams(props, false, searchText);
  const res = await sendRequest('post', endpointsMap.carrierListParentAndCurrent, { data: reqData });
  const { data } = res;
  props.setCarrierOptions(data.results);
  props.setTotalCount(data.totalCount);
  props.setLoadingStatus(false);
}

export const withAsyncCarrierSelect = compose(
  withState('isLoading', 'setLoadingStatus', false),
  withState('searchText', 'setText', null),
  withHandlers({
    handleScroll: (props: Object) => () => {
      if (R.gt(props.totalCount, props.entityList.length)) {
        props.setLoadingStatus(true);
        paginationLoadOptions(props);
      }
    },
    handleGetCarrierOptions: (props: Object) => () => {
      if (R.isNil(props.totalCount)) {
        props.setLoadingStatus(true);
        loadCarrierOptions(props);
      }
    },
    handleSearch: (props: Object) => (search: string) => {
      if (R.equals(props.field.name, `${GC.FIELD_PREFIX_CARRIER_ASSIGNMENT}.${GC.FIELD_CARRIER_TERMINAL_GUID}`)) return;
      const cond = R.not(R.and(G.isNilOrEmpty(props.searchText), R.isEmpty(search)));
      if (cond) {
        props.setLoadingStatus(true);
        asyncSearch(props, search, props.field);
        props.setText(search);
      }
    },
    handlerSelect: (props: Object) => (option: Object) => {
      props.handler(R.or(option, {}), props.field.name);
      if (R.and(
        G.isNotNil(option),
        G.notEquals(props.field.name, `${GC.FIELD_PREFIX_CARRIER_ASSIGNMENT}.${GC.FIELD_CARRIER_TERMINAL_GUID}`),
      )) {
        loadTerminalOptions(
          props.setTerminalOptions,
          option.value,
          props.setLoadingStatus,
          props.setFieldValue,
        );
      }
      props.setText(null);
    },
  }),
  pure,
);

export const enhanceRateCharges = (fromPage: string='default') => compose(
  connect(
    null,
    {
      createTelRateActionRequest,
      updateTelRateActionRequest,
      createDriverOnDriversCardRequest,
      createTelCarrierRateActionRequest,
      updateTelCarrierRateActionRequest,
    },
  ),
  withHandlers({
    handleCreateTelRate: (props: Object) => (values: Object) => {
      const {
        createTelRateActionRequest,
        createLoadDriverRateRequest,
        createDriverOnDriversCardRequest,
      } = props;

      const actionMap = {
        default: createTelRateActionRequest,
        driversCard: createDriverOnDriversCardRequest,
        rateLoadDetailsNew: createLoadDriverRateRequest,
      };

      actionMap[fromPage](values);
    },
    handleCreateCarrierTelRate: (props: Object) => (values: Object) => {
      const {
        createLoadCarrierRateRequest,
        createTelCarrierRateActionRequest,
      } = props;

      const actionMap = {
        default: createTelCarrierRateActionRequest,
        rateLoadDetailsNew: createLoadCarrierRateRequest,
      };

      actionMap[fromPage](values);
    },
    handleUpdateTelRate: (props: Object) => (values: Object) => {
      const {
        updateTelRateActionRequest,
        updateLoadDriverRateRequest,
      } = props;

      const actionMap = {
        default: updateTelRateActionRequest,
        rateLoadDetailsNew: updateLoadDriverRateRequest,
      };

      actionMap[fromPage](values);
    },
    handleUpdateCarrierTelRate: (props: Object) => (values: Object) => {
      const {
        updateLoadCarrierRateRequest,
        updateTelCarrierRateActionRequest,
      } = props;

      const actionMap = {
        default: updateTelCarrierRateActionRequest,
        rateLoadDetailsNew: updateLoadCarrierRateRequest,
      };

      actionMap[fromPage](values);
    },
  }),
);

class CloAvailableFleetInfo extends Component {
  constructor(props: Object) {
    super(props);
    this.state = {
      trucksOptions: [],
      trailersOptions: [],
      assignmentInfo: null,
      primaryDriversOptions: [],
      secondaryDriversOptions: [],
    };
    this.getFleetRequest = this.getFleetRequest.bind(this);
    this.getAvailableTrucks = this.getAvailableTrucks.bind(this);
    this.getAvailableDrivers = this.getAvailableDrivers.bind(this);
    this.getAvailableTrailers = this.getAvailableTrailers.bind(this);
  }

  getFleetRequest(cloGuid: string) {
    this.getAvailableDrivers(cloGuid);
    this.getAvailableTrailers(cloGuid);
    this.getAvailableTrucks(cloGuid);
  }

  async getAvailableDrivers(cloGuid: string) {
    const res = await sendRequest(
      'get',
      endpointsMap.cloAvailableDrivers,
      G.setParamToRequestQuery('cloGuid', cloGuid),
    );
    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const newState = P.$all(
        P.$set('secondaryDriversOptions', mapDriversToOptions(data)),
        P.$set('primaryDriversOptions', mapDriversToOptions(data, true)),
        this.state,
      );
      this.setState(newState);
    }
  }

  async getAvailableTrailers(cloGuid: string) {
    const res = await sendRequest(
      'get',
      endpointsMap.cloAvailableTrailers,
      G.setParamToRequestQuery('cloGuid', cloGuid),
    );
    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const newState = P.$set('trailersOptions', mapEquipmentsToOptions(data), this.state);
      this.setState(newState);
    }
  }

  async getAvailableTrucks(cloGuid: string) {
    const res = await sendRequest(
      'get',
      endpointsMap.cloAvailableTrucks,
      G.setParamToRequestQuery('cloGuid', cloGuid),
    );

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const newState = P.$set('trucksOptions', mapEquipmentsToOptions(data), this.state);
      this.setState(newState);
    } else {
      G.handleFailResponseSimple(res);
    }
  }

  render() {
    return (
      <div>
        {this.props.render(this.state, this.getFleetRequest)}
      </div>
    );
  }
}

const optionsToPick = [
  'trucksOptions',
  'trailersOptions',
  'primaryDriversOptions',
  'secondaryDriversOptions',
];

export const withCloAvailableFleetInfo = (Component: any) => {
  return class extends React.Component {
    render() {
      return (
        <CloAvailableFleetInfo
          render={(parentState: Object, getFleetRequest: Function) => (
            <Component
              {...this.props}
              rateInfo={parentState.rateInfo}
              getFleetRequest={getFleetRequest}
              assignmentInfo={parentState.assignmentInfo}
              fleetOptionsForSelect={R.pick(optionsToPick, parentState)}
            />
          )}
        />
      );
    }
  };
};

export const withDispatchActions = compose(
  withHandlers({
    handleDispatchCarrierTelRate: (props: Object) => (values: Object) => {
      const { dispatchCarrierTelRateRequest } = props;

      const data = {
        ...values,
        mailTo: values[GC.FIELD_MAIL_SENDING_TO],
        telGuid: R.path([GC.FIELD_TEL, GC.FIELD_GUID], props),
      };

      dispatchCarrierTelRateRequest(R.omit([GC.FIELD_MAIL_SENDING_TO], data));
    },
  }),
  withDispatchCarrierRate,
  withHandlers({
    handleDispatchTelRate: (props: Object) => () => {
      const {
        tel,
        dispatchTelRateRequest,
        handleDispatchCarrierRate,
        handleDispatchCarrierTelRate,
      } = props;

      const telGuid = R.prop(GC.FIELD_GUID, tel);
      const rate = R.path([GC.FIELD_TEL, GC.FIELD_RATE], props);

      const handleDispatchTelRate = () => {
        const dispatchData = {
          [GC.FIELD_GUID]: telGuid,
          dispatchAction: GC.DISPATCH_ACTION,
        };

        dispatchTelRateRequest(dispatchData);
      };

      if (R.equals(rate[GC.FIELD_TYPE], GC.RATE_TYPE_CARRIER_RATE)) {
        const emailsSuspended = R.prop(GC.FIELD_EMAILS_SUSPENDED, rate);

        if (emailsSuspended) return G.callFunction(handleDispatchTelRate);

        const templateOptions = R.compose(
          R.map(({ name, guid }: Object) => ({ label: name, value: guid })),
          R.pathOr([], ['documentTemplates', GC.DOCUMENT_PRINTABLE_SECTION_CARRIER_DISPATCH_DOCUMENTS]),
        )(props);

        const documents = R.pathOr(
          R.pathOr([], ['asyncInitialData', 'data'], props),
          [GC.FIELD_TEL, GC.FIELD_DOCUMENTS],
          props,
        );

        const options = {
          tel,
          isMulti: true,
          templateOptions,
          selectedRate: rate,
          withDispatchDocs: true,
          asyncInitialData: { data: documents },
          customHandleSubmit: handleDispatchCarrierTelRate,
          branchGuid: G.getPropFromObject(GC.BRANCH_GUID, tel),
          handleDispatchCarrierRateWithTerminalData: handleDispatchTelRate,
        };

        return handleDispatchCarrierRate(options);
      }

      G.callFunction(handleDispatchTelRate);
    },
    handleCancelDispatchTelRate: ({ tel, dispatchTelRateRequest }: Object) => () => (
      dispatchTelRateRequest({
        guid: tel[GC.FIELD_GUID],
        dispatchAction: GC.CANCEL_DISPATCH_ACTION,
      })
    ),
  }),
  pure,
);

export const withAsyncContacts = compose(
  withState('contactList', 'setContactList', []),
  withHandlers({
    handleGetContacts: (props: Object) => async () => {
      const { branchGuid, setContactList } = props;

      const currentUserBranchGuid = G.getAmousCurrentUserBranchGuidFromWindow();
      const options = {
        params: { [GC.BRANCH_GUID]: R.or(branchGuid, currentUserBranchGuid) },
      };

      const res = await sendRequest('post', endpointsMap.contactBookList, options);

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        const contactList = R.map(
          ({ email, lastName, firstName }: Object) => ({
            [GC.FIELD_EMAIL]: email,
            [GC.FIELD_LAST_NAME]: R.or(lastName, []),
            [GC.FIELD_FIRST_NAME]: R.or(firstName, []),
          }),
          R.or(data, []),
        );
        setContactList(contactList);
      } else {
        G.handleFailResponseSimple(res);
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.handleGetContacts();
    },
  }),
);

const deadheadCallback = (props: Object) => (callbackProps: Object) => {
  const { newValues, setValues } = props;

  const { startPointLocation, finishPointLocation } = newValues;

  const totalDistance = R.path(['data', 'totalDistance'], callbackProps);
  const totalUnit = R.path(['data', 'unit'], callbackProps);
  const stopResults = R.path(['data', 'stopResults'], callbackProps);

  if (G.isAllTrue(
    G.isNotNilAndNotEmpty(totalDistance),
    G.isNotNilAndNotEmpty(startPointLocation),
    G.isNotNilAndNotEmpty(finishPointLocation),
  )) {
    const start = R.head(stopResults);
    const finish = R.last(stopResults);
    const deadhead = G.toFixed(R.add(R.prop('distance', start), R.prop('distance', finish)));

    return setValues({
      ...newValues,
      [GC.FIELD_DEADHEAD_DISTANCE]: deadhead,
      [GC.FIELD_DEADHEAD_DISTANCE_UOM]: totalUnit,
    });
  }

  if (G.isAllTrue(
    G.isNotNilAndNotEmpty(totalDistance),
    G.isNilOrEmpty(startPointLocation),
    G.isNotNilAndNotEmpty(finishPointLocation),
  )) {
    const finish = R.last(stopResults);
    const deadhead = G.toFixed(R.prop('distance', finish));

    return setValues({
      ...newValues,
      [GC.FIELD_DEADHEAD_DISTANCE]: deadhead,
      [GC.FIELD_DEADHEAD_DISTANCE_UOM]: totalUnit,
    });
  }

  if (G.isAllTrue(
    G.isNotNilAndNotEmpty(totalDistance),
    G.isNotNilAndNotEmpty(startPointLocation),
    G.isNilOrEmpty(finishPointLocation),
  )) {
    const start = R.head(stopResults);
    const deadhead = G.toFixed(R.prop('distance', start));

    return setValues({
      ...newValues,
      [GC.FIELD_DEADHEAD_DISTANCE]: deadhead,
      [GC.FIELD_DEADHEAD_DISTANCE_UOM]: totalUnit,
    });
  }
};

const setStartFinishPointLocationsWithDeadhead = (
  props: Object,
  fieldName: string,
  newValues: Object,
  mappedLocation: Object,
) => {
  const {
    values,
    setValues,
    availableDriversData,
    getAsyncDistanceCalcWithCallback,
  } = props;

  const branchGuid = G.getAmousCurrentBranchGuidFromWindow();

  const { startPointLocation, finishPointLocation } = values;

  const firstEventCoordinates = R.path(['firstEventCoordinates'], availableDriversData);
  const lastEventCoordinates = R.path(['lastEventCoordinates'], availableDriversData);

  if (R.equals(fieldName, GC.FIELD_START_POINT_LOCATION)) {
    let stopPoints = [
      mappedLocation,
      firstEventCoordinates,
      lastEventCoordinates,
    ];

    if (G.isNotNilAndNotEmpty(finishPointLocation)) {
      stopPoints = R.append(finishPointLocation, stopPoints);
    }

    getAsyncDistanceCalcWithCallback({
      stopPoints,
      branchGuid,
      callback: deadheadCallback({ newValues, fieldName, setValues, getAsyncDistanceCalcWithCallback }),
    });
  }

  if (R.equals(fieldName, GC.FIELD_FINISH_POINT_LOCATION)) {
    let stopPoints = [
      firstEventCoordinates,
      lastEventCoordinates,
      mappedLocation,
    ];

    if (G.isNotNilAndNotEmpty(startPointLocation)) {
      stopPoints = R.prepend(startPointLocation, stopPoints);
    }

    getAsyncDistanceCalcWithCallback({
      stopPoints,
      branchGuid,
      callback: deadheadCallback({ newValues, fieldName, setValues, getAsyncDistanceCalcWithCallback }),
    });
  }
};

const setSourceToStartPoint = (values: Object, sourceType: string, sourceName: string) => {
  const startPoint = R.path([GC.FIELD_START_POINT_LOCATION], values);

  if (R.not(G.isObject(values))) return values;

  if (G.isNilOrEmpty(startPoint)) return values;

  const newStartPoint = {
    ...startPoint,
    sourceType,
    sourceName,
  };

  return R.assoc(GC.FIELD_START_POINT_LOCATION, newStartPoint, values);
};

export const withSearchLocation = compose(
  connect(
    null,
    {
      openModal, closeModal, openLoader, closeLoader,
    },
  ),
  withAsyncSearchTemplateAutocomplete(),
  withHandlers({
    handleSearchLocation: (props: Object) => (fieldProps: Object) => {
      const { fieldName, fieldType } = fieldProps;
      const { values, openModal, setValues, closeModal } = props;

      const branchGuid = G.getAmousCurrentBranchGuidFromWindow();

      const searchTemplateRequest = (location: Object) => {
        const mappedLocation = G.mapSearchedLocation(location);

        const mappedLocationWithNewAddress1 = R.assoc(
          GC.FIELD_ADDRESS_1,
          G.concatLocationFields(mappedLocation),
          mappedLocation,
        );

        if (R.equals(fieldType, 'locationObject')) {
          let newValues = R.assoc(fieldName, mappedLocationWithNewAddress1, R.or(values, {}));

          if (R.equals(fieldName, GC.FIELD_START_POINT_LOCATION)) {
            newValues = setSourceToStartPoint(
              newValues,
              GC.START_POINT_SOURCE_TYPE_MANUAL,
              G.getAmousCurrentUserLoginIdFromWindow(),
            );
          }

          setValues(newValues);

          setStartFinishPointLocationsWithDeadhead(props, fieldName, newValues, mappedLocation);

          return closeModal();
        }

        setValues(R.mergeRight(values, mappedLocationWithNewAddress1));

        closeModal();
      };

      const modalContent = (
        <LocationTemplateSearch
          branchGuid={branchGuid}
          closeModal={closeModal}
          searchTemplateRequest={searchTemplateRequest}
        />
      );

      const modal = G.createCommonModalOptions(modalContent);

      openModal(modal);
    },
    handleSelectLocation: (props: Object) => (fromInputData: Object) => {
      const { location, fieldName, newValues } = fromInputData;

      let valuesToUse = newValues;

      if (R.equals(fieldName, GC.FIELD_START_POINT_LOCATION)) {
        valuesToUse = setSourceToStartPoint(
          newValues,
          GC.START_POINT_SOURCE_TYPE_MANUAL,
          G.getAmousCurrentUserLoginIdFromWindow(),
        );
      }

      return setStartFinishPointLocationsWithDeadhead(props, fieldName, valuesToUse, location);
    },
    handleChangeSelectLocation: (props: Object) => (fromInputData: Object) => {
      const { value, fieldProps } = fromInputData;

      if (G.isNotNilAndNotEmpty(value)) return;

      const { values, setValues } = props;
      const { id } = fieldProps;

      const defaultUomSystem = G.getConfigGeneralUomCalcDefaultUomSystemFromWindow();
      const deadheadUom = R.pathOr(GC.UOM_MILE, [defaultUomSystem], GC.uomSystemToDistanceUomMap);

      const newValues = {
        ...values,
        [id]: value,
        [GC.FIELD_DEADHEAD_DISTANCE]: 0,
        [GC.FIELD_DEADHEAD_DISTANCE_UOM]: deadheadUom,
      };

      setValues(newValues);
    },
  }),
  pure,
);

export const withAsyncDeadheadMiles = compose(
  withAsyncDistanceCalc,
  pure,
);

export const withShowIntegrationErrorsOnAvailableRates = compose(
  withState('animationDuration', 'setAnimationDuration', '.6s'),
  withHandlers({
    handleShowIntegrationErrors: (props: Object) => () => {
      const { openModal, setAnimationDuration } = props;

      const component = <IntegrationErrors {...props} />;

      const modal = {
        p: 15,
        component,
        options: {
          width: 'auto',
          height: 'auto',
          outsideCloseButton: true,
          title: G.getWindowLocale('titles:integration-errors', 'Integration Errors'),
        },
      };

      openModal(modal);
      setAnimationDuration('0s');
    },
  }),
);

export {
  withFleetAssignment,
};
