import React from 'react';
import * as R from 'ramda';
// components
import { ActionBox } from '../../../components/action-box';
// features
import { columnSettings as expensesCS } from '../../fleet-profile/driver/settings/expenses';
import ExpenseDocument from '../../fleet-profile/driver/components/expense-document';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box } from '../../../ui';
// feature fuel-cards
import * as C from '../../fuel-cards/constants';
// feature payroll
import FuelCardDetails from '../components/fuel-card-details';
//////////////////////////////////////////////////

export const fuelCardsTableSettings = {
  tableRowHeight: 40,
  titleRowHeight: 30,
  allowSelectAll: true,
  checkBoxCellWidth: 70,
  expandableItems: true,
  expandedDetailsComponent: FuelCardDetails,
};

export const fuelCardsReport = {
  fields: [
    { sequence: 0, freezed: true, name: 'removeRestoreColumn' },
    { sequence: 1, name: GC.FIELD_FUEL_CARDS_CARD_ID },
    { sequence: 2, name: GC.FIELD_FUEL_CARDS_SOURCE },
    { sequence: 3, name: GC.FIELD_FUEL_CARDS_TRANSACTION_NUMBER },
    { sequence: 4, name: GC.FIELD_FUEL_CARDS_TRANSACTION_DATE },
    { sequence: 5, name: GC.FIELD_TRIP_ID },
    { sequence: 6, name: GC.FIELD_TOTAL },
    { sequence: 7, name: GC.FIELD_CURRENCY },
    { sequence: 8, name: C.RC.FUEL_CARDS_TRANSACTION_STOP_LOCATION_NAME },
    { sequence: 9, name: C.RC.FUEL_CARDS_TRANSACTION_STOP_CITY },
    { sequence: 10, name: C.RC.FUEL_CARDS_TRANSACTION_STOP_STATE },
    { sequence: 11, name: GC.FIELD_TIME_ZONE },
  ],
};

export const tollTableSettings = {
  tableRowHeight: 40,
  titleRowHeight: 30,
  allowSelectAll: true,
  checkBoxCellWidth: 40,
};

export const tollReport = {
  fields: [
    { sequence: 0, freezed: true, name: 'removeRestoreColumn' },
    { sequence: 1, name: GC.FIELD_TOLL_CHARGES_SOURCE },
    { sequence: 2, name: GC.FIELD_TOLL_CHARGES_AMOUNT },
    { sequence: 3, name: GC.FIELD_CURRENCY },
    { sequence: 4, name: GC.FIELD_TOLL_CHARGES_AGENCY },
    { sequence: 5, name: GC.FIELD_TOLL_CHARGES_POSITING_DATE },
    { sequence: 6, name: GC.FIELD_TOLL_CHARGES_LICENCE_PLATE },
    { sequence: 7, name: GC.FIELD_TOLL_CHARGES_LOCATION_NAME },
    { sequence: 8, name: GC.FIELD_TOLL_CHARGES_TRANSPONDER_ID },
    { sequence: 9, name: GC.FIELD_TOLL_CHARGES_TRANSACTION_DATE },
    { sequence: 10, name: GC.FIELD_TOLL_CHARGES_TRANSACTION_TYPE },
  ],
};

export const driverPayrollReport = {
  fields: [
    { sequence: 1, name: GC.FIELD_PAYROLL_NUMBER },
    { sequence: 2, name: 'driverFirstName' },
    { sequence: 3, name: 'driverLastName' },
    { sequence: 4, name: GC.FIELD_PAYROLL_DATE_FROM },
    { sequence: 5, name: GC.FIELD_PAYROLL_DATE_TO },
    { sequence: 6, name: GC.FIELD_INVOICES_TOTAL },
    { sequence: 7, name: GC.FIELD_MAIN_CHARGES_TOTAL },
    { sequence: 8, name: GC.FIELD_PAYROLL_GRAND_TOTAL},
    { sequence: 9, name: GC.FIELD_CURRENCY },
  ],
};

export const addDriverPayrollReport = {
  fields: R.prepend({}, driverPayrollReport.fields),
};

export const driverPayrollColumnSettings = {
  [GC.FIELD_PAYROLL_NUMBER]: {
    width: 150,
    name: 'titles:payroll-number',
  },
  driverFirstName: {
    width: 150,
    name: ['titles:driver', 'titles:first-name'],
  },
  driverLastName: {
    width: 150,
    name: ['titles:driver', 'titles:last-name'],
  },
  [GC.FIELD_PAYROLL_DATE_FROM]: {
    width: 120,
    name: 'titles:date-from',
  },
  [GC.FIELD_PAYROLL_DATE_TO]: {
    width: 120,
    name: 'titles:date-to',
  },
  [GC.FIELD_INVOICES_TOTAL]: {
    width: 100,
    name: 'titles:invoices-total',
  },
  [GC.FIELD_PAYROLL_GRAND_TOTAL]: {
    width: 100,
    name: 'titles:gross-total',
  },
  [GC.FIELD_CURRENCY]: {
    width: 100,
    name: 'titles:currency',
  },
  [GC.FIELD_MAIN_CHARGES_TOTAL]: {
    width: 120,
    name: 'titles:main-charges-total',
  },
};

export const advancePaymentColumnSettings = {
  [GC.FIELD_ADVANCE_PAYMENT_CHECK_AMOUNT]: {
    width: 150,
    name: 'titles:check-amount',
  },
  [GC.FIELD_ADVANCE_PAYMENT_CHECK_FEE]: {
    width: 150,
    name: 'titles:check-fee',
  },
  [GC.FIELD_ADVANCE_PAYMENT_TRIP_NUMBER]: {
    width: 150,
    name: 'titles:trip-number',
  },
  [GC.FIELD_ADVANCE_PAYMENT_CHECK_NUMBER]: {
    width: 200,
    name: 'titles:check-number',
  },
  [GC.FIELD_ADVANCE_PAYMENT_CHECK_BALANCE]: {
    width: 150,
    name: 'titles:check-balance',
  },
  [GC.FIELD_ADVANCE_PAYMENT_TAG]: {
    width: 100,
    name: 'titles:tag',
  },
  [GC.FIELD_ADVANCE_PAYMENT_STATUS]: {
    width: 150,
    name: 'titles:status',
  },
  [GC.FIELD_ADVANCE_PAYMENT_CHANGE_STATUS_REASON]: {
    width: 300,
    name: 'titles:status-reason',
  },
  [GC.FIELD_ADVANCE_PAYMENT_INTEGRATION_TYPE]: {
    width: 150,
    name: 'titles:integration-type',
  },
  [GC.FIELD_ADVANCE_PAYMENT_LAST_USE_CITY]: {
    width: 200,
    name: 'titles:last-use-city',
  },
  [GC.FIELD_ADVANCE_PAYMENT_LAST_USE_LOCATION_CODE]: {
    width: 200,
    name: 'titles:last-use-location-code',
  },
  [GC.FIELD_ADVANCE_PAYMENT_LAST_USE_DATE]: {
    width: 200,
    name: 'titles:last-use-date',
  },
  [GC.FIELD_CREATED_DATE]: {
    width: 200,
    name: 'titles:created-on',
  },
  [GC.FIELD_CREATED_BY]: {
    width: 150,
    name: 'titles:created-by',
  },
  [GC.FIELD_COMMENTS]: {
    width: 400,
    name: 'titles:comments',
  },
};

export const advancePaymentReport = {
  fields: [
    { sequence: 0, freezed: true, name: 'removeRestoreColumn' },
    { sequence: 1, name: GC.FIELD_ADVANCE_PAYMENT_CHECK_AMOUNT },
    { sequence: 2, name: GC.FIELD_ADVANCE_PAYMENT_CHECK_FEE },
    { sequence: 3, name: GC.FIELD_ADVANCE_PAYMENT_TRIP_NUMBER },
    { sequence: 4, name: GC.FIELD_ADVANCE_PAYMENT_CHECK_NUMBER },
    { sequence: 5, name: GC.FIELD_ADVANCE_PAYMENT_CHECK_BALANCE },
    { sequence: 6, name: GC.FIELD_ADVANCE_PAYMENT_TAG },
    { sequence: 7, name: GC.FIELD_ADVANCE_PAYMENT_STATUS },
    { sequence: 8, name: GC.FIELD_ADVANCE_PAYMENT_CHANGE_STATUS_REASON },
    { sequence: 9, name: GC.FIELD_ADVANCE_PAYMENT_INTEGRATION_TYPE },
    { sequence: 10, name: GC.FIELD_ADVANCE_PAYMENT_LAST_USE_CITY },
    { sequence: 11, name: GC.FIELD_ADVANCE_PAYMENT_LAST_USE_LOCATION_CODE },
    { sequence: 12, name: GC.FIELD_ADVANCE_PAYMENT_LAST_USE_DATE },
    { sequence: 13, name: GC.FIELD_CREATED_DATE },
    { sequence: 14, name: GC.FIELD_CREATED_BY },
    { sequence: 15, name: GC.FIELD_COMMENTS },
  ],
};

export const dailyHoursTableSettings = {
  allowEditBtn: true,
  checkBoxCellWidth: 0,
  expandableItems: false,
};

export const dailyHoursColumnSettings = {
  [GC.FIELD_LOG_DATE]: {
    width: 150,
    name: 'titles:date',
  },
  [GC.FIELD_ON_DUTY_MINUTES]: {
    width: 150,
    name: 'titles:on-duty-hours',
    customComponent: ({ data }: Object) => {
      const { shortString } = G.convertMinutesToHoursAndMinutes(G.getPropFromObject(GC.FIELD_ON_DUTY_MINUTES, data));

      return (
        <Box>{shortString}</Box>
      );
    },
  },
  [GC.FIELD_DRIVING_MINUTES]: {
    width: 150,
    name: 'titles:driving-hours',
    customComponent: ({ data }: Object) => {
      const { shortString } = G.convertMinutesToHoursAndMinutes(G.getPropFromObject(GC.FIELD_DRIVING_MINUTES, data));

      return (
        <Box>{shortString}</Box>
      );
    },
  },
  [GC.FIELD_TOTAL_DISTANCE]: {
    width: 150,
    name: 'titles:total-distance',
  },
  [GC.FIELD_TOTAL_DISTANCE_UOM]: {
    width: 150,
    name: 'titles:total-distance-uom',
  },
  [GC.FIELD_CREATED_BY]: {
    width: 150,
    name: 'titles:created-by',
  },
};

export const dailyHoursReport = {
  fields: [
    { sequence: 0, name: GC.FIELD_LOG_DATE },
    { sequence: 1, name: GC.FIELD_ON_DUTY_MINUTES },
    { sequence: 2, name: GC.FIELD_DRIVING_MINUTES },
    { sequence: 3, name: GC.FIELD_TOTAL_DISTANCE },
    { sequence: 4, name: GC.FIELD_TOTAL_DISTANCE_UOM },
    { sequence: 5, name: GC.FIELD_CREATED_BY },
  ],
};

export const payrollInvoicesTableSettings = {
  maxHeight: 400,
  tableRowHeight: 40,
  titleRowHeight: 30,
  checkBoxCellWidth: 80,
  expandableItems: false,
  allowSelectItems: false,
  checkBoxCellJustifyContent: 'center',
};

export const payrollInvoicesColumnSettings = {
  telPrimaryReferenceValue: {
    width: 100,
    name: 'titles:trip',
    customComponent: (props: Object) => {
      const { data, callbackData } = props;

      const { telGuid, telPrimaryReferenceValue } = data;

      const { handleOpenTrip } = callbackData;

      return (
        <ActionBox
          text={telPrimaryReferenceValue}
          action={() => handleOpenTrip(telGuid)}
        />
      );
    },
  },
  truckUnitId: {
    width: 100,
    name: 'titles:truck',
  },
  trailersUnitIds: {
    width: 200,
    name: 'titles:trailers',
  },
  firstEventCity: {
    width: 150,
    name: ['titles:first-event'],
    customComponent: ({ data }: Object) => {
      const { firstEventCity, firstEventState } = data;

      const text = `${firstEventCity}, ${firstEventState}`;

      return (
        <Box>{text}</Box>
      );
    },
  },
  lastEventCity: {
    width: 150,
    name: ['titles:last-event'],
    customComponent: ({ data }: Object) => {
      const { lastEventCity, lastEventState } = data;

      const text = `${lastEventCity}, ${lastEventState}`;

      return (
        <Box>{text}</Box>
      );
    },
  },
  invoiceNumber: {
    width: 100,
    name: 'titles:invoice-number',
  },
  invoiceDate: {
    width: 100,
    name: 'titles:invoice-date',
  },
  status: {
    width: 100,
    name: 'titles:invoice-status',
  },
  total: {
    width: 100,
    name: 'titles:total',
  },
  fuelChargesTotal: {
    width: 100,
    name: 'titles:fuel',
  },
  mainChargesTotal: {
    width: 100,
    name: 'titles:main-charges',
  },
  additionalChargesTotal: {
    width: 100,
    name: 'titles:other-charges',
  },
  currency: {
    width: 100,
    name: 'titles:currency',
  },
  transportationMode: {
    width: 100,
    name: 'titles:mode',
  },
  glCode: {
    width: 100,
    name: 'titles:gl',
  },
  paymentDueDate: {
    width: 100,
    name: 'titles:due-date',
  },
  totalTripDistance: {
    width: 100,
    name: 'titles:distance',
  },
  totalTripDistanceUom: {
    width: 100,
    name: 'titles:distance-uom',
  },
  totalTripWeight: {
    width: 100,
    name: 'titles:weight',
  },
  totalTripWeightUom: {
    width: 100,
    name: 'titles:weight-uom',
  },
};

export const chargesFilterProps = [
  {
    type: 'number',
    value: GC.FIELD_CHARGE_TOTAL,
    name: G.getWindowLocale('titles:total', 'Total'),
  },
  {
    type: 'string',
    value: GC.FIELD_CURRENCY,
    name: G.getWindowLocale('titles:currency', 'Currency'),
  },
  {
    type: 'string',
    value: GC.FIELD_CHARGE_TYPE,
    name: G.getWindowLocale('titles:type', 'Type'),
  },
  {
    type: 'number',
    value: GC.FIELD_CHARGE_QUANTITY,
    name: G.getWindowLocale('titles:quantity', 'Quantity'),
  },
  {
    type: 'string',
    value: GC.FIELD_DISPLAYED_NAME,
    name: G.getWindowLocale('titles:charge-name', 'Charge Name'),
  },
  {
    type: 'number',
    value: GC.FIELD_CHARGE_RATE,
    name: G.getWindowLocale('titles:rate', 'Rate'),
  },
  {
    type: 'string',
    value: GC.FIELD_CHARGE_RATE_TYPE,
    name: G.getWindowLocale('titles:rate-type', 'Rate Type'),
  },
  {
    type: 'string',
    value: GC.FIELD_CHARGE_RATE_UNIT,
    name: G.getWindowLocale('titles:rate-unit', 'Rate Unit'),
  },
  {
    type: 'string',
    name: G.getWindowLocale('titles:gl-code', 'GL Code'),
    value: `${GC.FIELD_GL_CODE}.${GC.FIELD_DISPLAYED_VALUE}`,
  },
  {
    type: 'boolean',
    value: GC.FIELD_CHARGE_DEDUCTION,
    name: G.getWindowLocale('titles:deduction', 'Deduction'),
  },
  {
    type: 'boolean',
    value: GC.FIELD_CHARGE_NON_TAXABLE,
    name: G.getWindowLocale('titles:non-taxable', 'Non Taxable'),
  },
  {
    type: 'string',
    value: GC.GRC.PAYROLL_NUMBER,
    name: G.getWindowLocaleArr('titles:payroll-number', 'Payroll Number'),
  },
  {
    type: 'date',
    value: GC.GRC.PAYROLL_DATE_TO,
    name: `${G.getWindowLocale('titles:payroll', 'Payroll')} ${G.getWindowLocaleArr('titles:date-to', 'Date To')}`,
  },
  {
    type: 'date',
    value: GC.GRC.PAYROLL_DATE_FROM,
    name: `${G.getWindowLocale('titles:payroll', 'Payroll')} ${G.getWindowLocaleArr('titles:date-from', 'Date From')}`,
  },
];

const driverTxtLocale = () => G.getWindowLocale('titles:driver', 'Driver');

export const driverChargesFilterProps = [
  ...chargesFilterProps,
  {
    type: 'string',
    value: GC.GRC.PAYROLL_DRIVER_LOGIN_ID,
    name: `${driverTxtLocale()} ${G.getWindowLocale('titles:user-id', 'User ID')}`,
  },
  {
    type: 'string',
    value: GC.GRC.PAYROLL_DRIVER_FIRST_NAME,
    name: `${driverTxtLocale()} ${G.getWindowLocale('titles:first-name', 'First Name')}`,
  },
  {
    type: 'string',
    value: GC.GRC.PAYROLL_DRIVER_LAST_NAME,
    name: `${driverTxtLocale()} ${G.getWindowLocale('titles:last-name', 'Last Name')}`,
  },
];

const vendorTxtLocale = () => G.getWindowLocale('titles:vendor', 'Vendor');

export const vendorChargesFilterProps = [
  ...chargesFilterProps,
  {
    type: 'string',
    value: GC.GRC.PAYROLL_FLEET_VENDOR_COMPANY_NAME,
    name: `${vendorTxtLocale()}: ${G.getWindowLocale('titles:company-name', 'Company Name')}`,
  },
  {
    type: 'string',
    value: GC.GRC.PAYROLL_FLEET_VENDOR_OWNER_NAME,
    name: `${vendorTxtLocale()}: ${G.getWindowLocale('titles:owner-name', 'Owner Name')}`,
  },
  {
    type: 'string',
    value: GC.GRC.PAYROLL_FLEET_VENDOR_MC_NUMBER,
    name: `${vendorTxtLocale()}: ${G.getWindowLocale('titles:mc-number', 'MC Number')}`,
  },
  {
    type: 'string',
    value: GC.GRC.PAYROLL_FLEET_VENDOR_ACCOUNT_NUMBER,
    name: `${vendorTxtLocale()}: ${G.getWindowLocale('titles:account-number', 'Account Number')}`,
  },
];

export const chargesColumnSettings = {
  [GC.FIELD_CHARGE_TOTAL]: {
    width: 100,
    pivotType: 'number',
    name: 'titles:total',
  },
  [GC.FIELD_CURRENCY]: {
    width: 100,
    name: 'titles:currency',
  },
  [GC.FIELD_CHARGE_TYPE]: {
    width: 100,
    name: 'titles:type',
  },
  [GC.FIELD_CHARGE_QUANTITY]: {
    width: 100,
    pivotType: 'number',
    name: 'titles:quantity',
  },
  [GC.FIELD_DISPLAYED_NAME]: {
    width: 100,
    name: 'titles:charge-name',
  },
  [GC.FIELD_CHARGE_RATE]: {
    width: 100,
    name: 'title:rate',
    pivotType: 'number',
  },
  [GC.FIELD_CHARGE_RATE_TYPE]: {
    width: 100,
    name: 'titles:rate-type',
  },
  [GC.FIELD_CHARGE_RATE_UNIT]: {
    width: 100,
    name: 'titles:rate-unit',
  },
  [`${GC.FIELD_GL_CODE}.${GC.FIELD_DISPLAYED_VALUE}`]: {
    width: 100,
    name: 'titles:gl-code',
  },
  [GC.FIELD_CHARGE_DEDUCTION]: {
    width: 100,
    name: 'titles:deduction',
  },
  [GC.FIELD_CHARGE_NON_TAXABLE]: {
    width: 100,
    name: 'titles:non-taxable',
  },
  [GC.GRC.PAYROLL_NUMBER]: {
    width: 100,
    name: 'titles:payroll-number',
  },
  [GC.GRC.PAYROLL_DATE_FROM]: {
    width: 150,
    pivotType: GC.PIVOT_TYPE_YQMD,
    name: ['titles:payroll', 'titles:date-from'],
  },
  [GC.GRC.PAYROLL_DATE_TO]: {
    width: 150,
    pivotType: GC.PIVOT_TYPE_YQMD,
    name: ['titles:payroll', 'titles:date-to'],
  },
  [GC.GRC.PAYROLL_DRIVER_LOGIN_ID]: {
    width: 100,
    name: ['titles:driver', 'titles:user-id'],
  },
  [GC.GRC.PAYROLL_DRIVER_FIRST_NAME]: {
    width: 100,
    name: ['titles:driver', 'titles:first:name'],
  },
  [GC.GRC.PAYROLL_DRIVER_LAST_NAME]: {
    width: 100,
    name: ['titles:driver', 'titles:last:name'],
  },
  [GC.GRC.PAYROLL_FLEET_VENDOR_COMPANY_NAME]: {
    type: 'string',
    name: ['titles:vendor', 'titles:company-name'],
  },
  [GC.GRC.PAYROLL_FLEET_VENDOR_OWNER_NAME]: {
    type: 'string',
    name: ['titles:vendor', 'titles:owner-name'],
  },
  [GC.GRC.PAYROLL_FLEET_VENDOR_MC_NUMBER]: {
    type: 'string',
    name: ['titles:vendor', 'titles:mc-number'],
  },
  [GC.GRC.PAYROLL_FLEET_VENDOR_ACCOUNT_NUMBER]: {
    type: 'string',
    name: ['titles:vendor', 'titles:account-number'],
  },
};

export const payrollStatisticTableSettings = {
  tableRowHeight: 40,
  titleRowHeight: 30,
  checkBoxCellWidth: 0,
  allowSelectItems: false,
};

export const expensesColumnSettings = R.compose(
  R.assoc(GC.FIELD_FILE_NAME, {
    width: 200,
    name: 'titles:file',
    customComponent: ({ data }: Object) => <ExpenseDocument {...data} hideDelete={true} />,
  }),
  R.omit([
    GC.FIELD_STATUS,
    GC.FIELD_FILE_NAME,
    GC.FIELD_CREATED_BY,
    GC.FIELD_CREATED_DATE,
    GC.FIELD_LAST_MODIFIED_BY,
    GC.FIELD_LAST_MODIFIED_DATE,
  ]),
)(expensesCS);

export const expensesReport = {
  fields: [
    { sequence: 0, freezed: true, name: 'removeRestoreColumn' },
    ...R.compose(
      G.mapIndexed((name: string, i: number) => ({ name, sequence: R.inc(i) })),
      R.keys,
    )(expensesColumnSettings),
  ],
};
