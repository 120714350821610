import React from 'react';
import * as R from 'ramda';
import { withFormik } from 'formik';
import { OuterClick } from 'react-outer-click';
import { pure, compose, withProps, withHandlers } from 'react-recompose';
// components
import { InfoPair } from '../../../components/info-pair';
import { TextBtn1 } from '../../../components/text-btns';
import { OrderChargesTotal } from '../../../components/charge/components';
import { DOChargeComponent } from '../../../components/charge/formik/charges-array';
import { withRecalculateAllChargesId, getSharedAccessorialsApplyTo } from '../../../components/charge/hocs';
import { withAsyncChargeAccessorials } from '../../../components/charge/hocs/with-async-charge-accessorials';
// features
import { cloRateEmptyFields } from '../../rate/customer/settings';
import { validationSchemaCustomerRate } from '../../rate/customer';
import ChargesSection from '../../rate/components/charges-section';
import { withHandleCloRateAvailableRates } from '../../rate/customer/hocs';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import { asyncGetAccessorialsList } from '../../../helpers/api-async';
// hocs
import {
  withConnectModalAndLoaderActions,
  withComponentDidUpdatePropCallback,
} from '../../../hocs';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex, Form } from '../../../ui';
// feature new-do
import FormTab from './form-tab';
import { Fieldset } from './formik/fieldset';
import PrevNextAction from './validate-action';
import { withAsyncLoadDistance } from '../hocs';
import { isValidCustomerRateForm } from '../validation';
import SectionDivider from '../components/section-divider';
import { FORM_BOX_SHADOW, TAB_NAME_REFERENCE } from '../constants';
import { isPageCloTemplate, setDefaultCustomerRateValues } from '../helpers';
import {
  maxTripRateFields,
  mainCustomerRateFields,
  totalCustomerRateFields,
  equipmentServicesFields,
  contactCustomerRateFields,
} from '../settings/fields-settings';
//////////////////////////////////////////////////

const CustomerCharges = (props: Object) => {
  const {
    values,
    branchGuid,
    isEditMode,
    isCreateMode,
    stopsQuantity,
    recalculateAllChargesId,
    defaultOrderAccessorials,
    sharedAccessorialsApplyTo,
    defaultOrderFuelAccessorial,
  } = props;

  const commonTotalProps = G.getTotalsFromValues(props);

  const currency = R.pathOr('', ['values', GC.FIELD_CURRENCY], props);
  const serviceDays = R.pathOr('', ['values', GC.FIELD_SERVICE_DAYS], props);
  const totalTripVolume = R.pathOr(0, ['itemsVolume', GC.ITEMS_VOLUME], props);
  const totalTripVolumeUom = R.pathOr('', ['itemsVolume', GC.ITEMS_VOLUME_UOM], props);
  const normalizedTotal = R.pathOr({}, ['values', GC.SYSTEM_OBJECT_NORMALIZED_TOTAL], props);

  const totalQuantities = {
    [R.path(['values', GC.FIELD_PACKAGE_TYPE], props)]: R.path(['values', GC.FIELD_TOTAL_PICKUP_QUANTITY], props),
  };

  return (
    <div>
      <DOChargeComponent
        {...commonTotalProps}
        rateProps={props}
        isCustomer={true}
        currency={currency}
        branchGuid={branchGuid}
        loadType={GC.FIELD_CLO}
        isEditMode={isEditMode}
        serviceDays={serviceDays}
        isCreateMode={isCreateMode}
        stopsQuantity={stopsQuantity}
        totalQuantities={totalQuantities}
        normalizedTotal={normalizedTotal}
        totalTripVolume={totalTripVolume}
        chargesArrayName={GC.FIELD_CHARGES}
        totalTripVolumeUom={totalTripVolumeUom}
        recalculateAllChargesId={recalculateAllChargesId}
        services={R.propOr([], GC.FIELD_SERVICES, values)}
        defaultOrderAccessorials={defaultOrderAccessorials}
        sharedAccessorialsApplyTo={sharedAccessorialsApplyTo}
        defaultOrderFuelAccessorial={defaultOrderFuelAccessorial}
      />
    </div>
  );
};

const getFormData = (values: Object) => {
  const isValid = isValidCustomerRateForm(values);

  return R.assoc('isValid', isValid, values);
};

const fieldsWrapperStyles = { px: 15 };

const CustomerRateForm = (props: Object) => {
  const {
    values,
    pageType,
    submitForm,
    branchConfigs,
    handleClickPrev,
    handleCleanForm,
    handleOuterClick,
    handleUpdateCloTemplate,
    handleOpenAutoRateModal,
    transportationModeGroupingList,
    handleSetRecalculateAllChargesId,
    handleGetSharedAccessorialsApplyTo,
  } = props;

  const charges = G.getChargesFromObject(values);
  const useTripMaxPay = G.getConfigValueFromStore(GC.RATE_ENGINE_USE_TRIP_MAX_PAY, branchConfigs);

  return (
    <OuterClick onOuterClick={handleOuterClick}>
      <Form width='100%'>
        <FormTab {...props} />
        <Flex
          p='7px 10px'
          justifyContent='space-between'
          color={G.getTheme('colors.black')}
          bg={G.getTheme('colors.light.teaGreen')}
        >
          <Box>
            {G.getWindowLocale('titles:customer-rate', 'Customer Rate')}
          </Box>
          <Flex>
            <TextBtn1
              onClickHandler={handleSetRecalculateAllChargesId}
              text={G.getWindowLocale('titles:recalc-all-charges', 'Recalc All Charges')}
            />
            <TextBtn1
              onClickHandler={handleGetSharedAccessorialsApplyTo}
              text={G.getWindowLocale('titles:update-auto-acc', 'Update Auto Acc.')}
            />
            <TextBtn1
              onClickHandler={handleOpenAutoRateModal}
              text={G.getWindowLocale('titles:show-rates', 'Show Rates')}
            />
            <Box
              mx='7px'
              cursor='pointer'
              onClick={handleCleanForm}
              title={G.getWindowLocale('titles:clean-form', 'Clean Form')}
            >
              {I.clean(G.getTheme('colors.dark.blue'))}
            </Box>
          </Flex>
        </Flex>
        <Box boxShadow={FORM_BOX_SHADOW}>
          <Box pt={20}>
            <Fieldset
              {...props}
              fields={mainCustomerRateFields}
              fieldsWrapperStyles={fieldsWrapperStyles}
              {...G.getModeServiceFromBranchConfigsForRateSelect(props)}
              transportationModeGroupingList={transportationModeGroupingList}
            />
            <Fieldset
              {...props}
              {...G.makeTransportationGroupingOptions(
                R.prop(GC.FIELD_MODE, values),
                transportationModeGroupingList,
                branchConfigs,
                R.pick([GC.FIELD_LOAD_SERVICES, GC.FIELD_LOAD_EQUIPMENT], values),
              )}
              fields={equipmentServicesFields}
            />
            <SectionDivider
              text={G.getWindowLocale('titles:totals', 'Totals', { caseAction: 'upperCase' })}
            />
            <Fieldset
              {...props}
              fields={totalCustomerRateFields}
              fieldsWrapperStyles={fieldsWrapperStyles}
            />
            <SectionDivider
              text={G.getWindowLocale('titles:contact-info', 'Contact Info', { caseAction: 'upperCase' })}
            />
            <Fieldset
              {...props}
              fields={contactCustomerRateFields}
              fieldsWrapperStyles={fieldsWrapperStyles}
            />
            {
              useTripMaxPay &&
              <SectionDivider />
            }
            {
              useTripMaxPay &&
              <Fieldset
                {...props}
                fields={maxTripRateFields}
                fieldsWrapperStyles={fieldsWrapperStyles}
              />
            }
            <CustomerCharges {...props} />
            <OrderChargesTotal {...props} charges={charges} />
            <PrevNextAction
              showNext={true}
              handleClickPrev={handleClickPrev}
              nextBtnText={G.getWindowLocale('actions:submit', 'Submit')}
              submitForm={G.ifElse(isPageCloTemplate(pageType), handleUpdateCloTemplate, submitForm)}
            />
          </Box>
        </Box>
      </Form>
    </OuterClick>
  );
};

const infoPairStyles = {
  fontSize: 14,
  margin: '6px 15px',
  width: 'max-content',
};

export const CustomerTotalsSection = ({
  totalTripWeight,
  totalTripDistance,
  totalTripWeightUom = '',
  totalTripDistanceUom = '',
}: Object) => (
  <Flex flexWrap='wrap'>
    <InfoPair
      {...infoPairStyles}
      text={`${totalTripDistance} ${totalTripDistanceUom}`}
      label={G.getWindowLocale('titles:total-trip-distance', 'Total Trip Distance')}
    />
    <InfoPair
      {...infoPairStyles}
      text={`${totalTripWeight} ${totalTripWeightUom}`}
      label={G.getWindowLocale('titles:total-trip-weight', 'Total Trip Weight')}
    />
  </Flex>
);

const defaultEnhance = compose(
  withAsyncLoadDistance,
  withConnectModalAndLoaderActions,
  withAsyncChargeAccessorials({ isCustomer: true, isCreateMode: true }),
  withFormik({
    enableReinitialize: true,
    validationSchema: validationSchemaCustomerRate,
    mapPropsToValues: (props: Object) => setDefaultCustomerRateValues(props),
    handleSubmit: (values: Object, { props }: Object) => {
      const { handleClickSave, setFormDataToStore } = props;

      setFormDataToStore({ dataName: 'rate', formData: getFormData(values) });
      setTimeout(() => handleClickSave(), 500);
    },
    displayName: 'CustomerRateForm',
  }),
  withHandlers({
    onClickTabCallback: (props: Object) => (activeName: Object) => {
      const { values, setActiveLeftTab, setFormDataToStore } = props;

      setFormDataToStore({ dataName: 'rate', formData: getFormData(values) });
      setActiveLeftTab(activeName);
    },
    handleClickPrev: (props: Object) => () => {
      const { values, setActiveLeftTab, setFormDataToStore } = props;

      setFormDataToStore({ dataName: 'rate', formData: getFormData(values) });
      setActiveLeftTab(TAB_NAME_REFERENCE);
    },
    handleOuterClick: (props: Object) => () => {
      const { values, setFormDataToStore } = props;

      setFormDataToStore({ dataName: 'rate', formData: getFormData(values) });
    },
    handleSetCustomerRateFromAvailableRates: ({ setFormDataToStore }: Object) => (values: Object) =>
      setFormDataToStore({ dataName: 'rate', formData: getFormData(values) }),
    handleUpdateCloTemplate: (props: Object) => () => {
      const { values, templateData, setFormDataToStore, saveAsTemplateRequest } = props;

      setFormDataToStore({ dataName: 'rate', formData: getFormData(values) });

      saveAsTemplateRequest({
        mode: 'updateTemplate',
        [GC.FIELD_TEMPLATE_NAME]: R.path([GC.FIELD_TEMPLATE_NAME], templateData),
      });
    },
  }),
  withProps(({ values, events }: Object) => {
    const equipment = R.prop(GC.FIELD_LOAD_EQUIPMENT, values);
    const equipments = G.ifElse(G.isNotNilAndNotEmpty(equipment), R.of(Array, equipment), []);

    return {
      equipments,
      loadData: {
        events,
        [GC.FIELD_SERVICES]: R.propOr([], GC.FIELD_SERVICES, values),
      },
    };
  }),
  withHandleCloRateAvailableRates,
  pure,
);

export const LockedCustomerRateForm = defaultEnhance((props: Object) => {
  const {
    values,
    pageType,
    submitForm,
    handleSubmit,
    branchConfigs,
    handleClickPrev,
    handleOuterClick,
    handleUpdateCloTemplate,
    handleOpenAutoRateModal,
    transportationModeGroupingList,
  } = props;

  const charges = G.getChargesFromObject(values);
  const groupedCharges = R.groupBy(R.prop(GC.FIELD_TYPE), charges);

  return (
    <OuterClick onOuterClick={handleOuterClick}>
      <form onSubmit={handleSubmit}>
        <FormTab {...props} />
        <Flex
          p='7px 10px'
          justifyContent='space-between'
          color={G.getTheme('colors.black')}
          bg={G.getTheme('colors.light.teaGreen')}
        >
          <Box>
            {G.getWindowLocale('titles:customer-rate', 'Customer Rate')}
          </Box>
          <TextBtn1
            onClickHandler={handleOpenAutoRateModal}
            text={G.getWindowLocale('titles:show-rates', 'Show Rates')}
          />
        </Flex>
        <Box boxShadow={FORM_BOX_SHADOW}>
          <Box pt={20}>
            <Fieldset
              {...props}
              fields={R.drop(1, mainCustomerRateFields)}
              {...G.getModeServiceFromBranchConfigsForRateSelect(props)}
            />
            <Fieldset
              {...props}
              {...G.makeTransportationGroupingOptions(
                R.prop(GC.FIELD_MODE, values),
                transportationModeGroupingList,
                branchConfigs,
                R.pick([GC.FIELD_LOAD_SERVICES, GC.FIELD_LOAD_EQUIPMENT], values),
              )}
              fields={equipmentServicesFields}
              transportationModeGroupingList={transportationModeGroupingList}
            />
            <SectionDivider
              text={G.getWindowLocale('titles:totals', 'Totals', { caseAction: 'upperCase' })}
            />
            <CustomerTotalsSection
              {...R.pick(
                [
                  GC.FIELD_TOTAL_TRIP_WEIGHT,
                  GC.FIELD_TOTAL_TRIP_DISTANCE,
                  GC.FIELD_TOTAL_TRIP_WEIGHT_UOM,
                  GC.FIELD_TOTAL_TRIP_DISTANCE_UOM,
                ],
                R.or(values, {}),
              )}
            />
            <SectionDivider
              text={G.getWindowLocale('titles:contact-info', 'Contact Info', { caseAction: 'upperCase' })}
            />
            <Fieldset
              {...props}
              fields={contactCustomerRateFields}
            />
            <ChargesSection
              useChargeCurrency={true}
              groupedCharges={groupedCharges}
              currency={G.getCurrencyFromObject(values)}
            />
            <OrderChargesTotal {...props} charges={charges} />
            <PrevNextAction
              showNext={true}
              handleClickPrev={handleClickPrev}
              nextBtnText={G.getWindowLocale('actions:submit', 'Submit')}
              submitForm={G.ifElse(isPageCloTemplate(pageType), handleUpdateCloTemplate, submitForm)}
            />
          </Box>
        </Box>
      </form>
    </OuterClick>
  );
});

const customerRateEnhance = compose(
  withRecalculateAllChargesId,
  defaultEnhance,
  withHandlers({
    handleGetSharedAccessorialsApplyTo: (props: Object) => async () => {
      const { stops, values, branchGuid, setFormDataToStore, handleSetRecalculateAllChargesId } = props;

      const accessorials = await asyncGetAccessorialsList(branchGuid);
      const sharedApplyTo = await getSharedAccessorialsApplyTo(
        { ...props, accessorials, stopCount: R.length(R.values(stops)) },
        branchGuid,
      );

      const charges = G.getChargesFromObject(values);
      const newCharges = G.replaceAutoChargesWithNewSharedAssessorialsApplyTo(charges, sharedApplyTo);
      const data = R.assoc(GC.FIELD_CHARGES, newCharges, values);

      setFormDataToStore({ dataName: 'rate', formData: getFormData(data) });
      handleSetRecalculateAllChargesId();
    },
    handleCleanForm: ({ values, setValues }: Object) => () => setValues(R.mergeRight(values, cloRateEmptyFields)),
  }),
  withHandlers({
    handleTotalTripDistanceChange: (props: Object) => (name: string, currProps: Object) => {
      const { values, setValues } = props;

      if (G.isNilOrEmpty(values)) return;

      const totalTripDistance = R.path([GC.FIELD_TOTAL_TRIP_DISTANCE], currProps);
      const totalTripDistanceUom = R.path([GC.FIELD_TOTAL_TRIP_DISTANCE_UOM], currProps);

      setValues(R.mergeRight(values, { totalTripDistance, totalTripDistanceUom }));
    },
  }),
  withComponentDidUpdatePropCallback({
    propName: 'totalTripDistance',
    callbackName: 'handleTotalTripDistanceChange',
  }),
);

export default customerRateEnhance(CustomerRateForm);
