import * as R from 'ramda';
import React from 'react';
// features
import PC from '../../permission/role-permission';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box, Flex, Text } from '../../../ui';
// feature new-do
import DropForm from '../forms/drop-form';
import PickupForm from '../forms/pickup-form';
import BillToForm from '../forms/bill-to-form';
import TripPricing from '../forms/trip-pricing';
import ReferenceForm from '../forms/reference-form';
import CustomerRateForm, { LockedCustomerRateForm } from '../forms/customer-rate-form';
import {
  TAB_NAME_PRICING,
  TAB_NAME_BILL_TO,
  TAB_NAME_REFERENCE,
  WAITING_PICTURE_SRC,
  TAB_NAME_TRIP_PRICING,
} from '../constants';
import {
  makeReqStops,
  getDataFromStops,
  getLeftSectionWidth,
  getItemsForDropOptions,
  renderLeftRightContent,
  renderLeftRightEmptyInfo,
  getContainersForDropOptions,
  getContainersForPickupItemOptions,
} from '../helpers';
//////////////////////////////////////////////////

const renderForm = (props: Object) => {
  const {
    rate,
    stops,
    billTo,
    loadType,
    branchGuid,
    loadTotals,
    branchConfigs,
    leftActiveTad,
    branchRefTypes,
    stopPointsStrings,
    referenceFormData,
    transportationModeGroupingList,
    recalculateLoadDistancesSuccess,
  } = props;

  const stop = stops[leftActiveTad];
  const referenceTypes = G.mapNameGuidToLabelValue(R.pathOr([], [GC.REF_SCOPE_NAME_CLO], branchRefTypes));
  const services = R.prop(GC.FIELD_LOAD_SERVICES, referenceFormData);

  if (G.isNotNilAndNotEmpty(stop)) {
    const { id, order, formData, eventType } = stop;

    if (R.equals(eventType, GC.EVENT_TYPE_PICKUP)) {
      const dropContainerOptions = getContainersForDropOptions(stops, stop);
      const itemContainerOptions = G.addEmptyOptionToDropDown(getContainersForPickupItemOptions(stops, stop));

      return (
        <PickupForm
          {...props}
          stop={stop}
          stopId={id}
          stopOrder={order}
          eventType={eventType}
          initialValues={formData}
          referenceTypes={referenceTypes}
          dropContainerOptions={dropContainerOptions}
          itemContainerOptions={itemContainerOptions}
        />
      );
    }

    const items = getItemsForDropOptions(stops, formData.items);
    const dropContainerOptions = getContainersForDropOptions(stops, stop);

    return (
      <DropForm
        {...props}
        stop={stop}
        stopId={id}
        items={items}
        stopOrder={order}
        eventType={eventType}
        initialValues={formData}
        referenceTypes={referenceTypes}
        dropContainerOptions={dropContainerOptions}
      />
    );
  }

  if (R.equals(leftActiveTad, TAB_NAME_REFERENCE)) {
    return (
      <ReferenceForm
        {...props}
        referenceTypes={referenceTypes}
        initialValues={referenceFormData}
      />
    );
  }

  if (R.equals(leftActiveTad, TAB_NAME_BILL_TO)) {
    return (
      <BillToForm
        {...props}
        initialValues={billTo}
      />
    );
  }

  if (R.equals(leftActiveTad, TAB_NAME_PRICING)) {
    const { totalWeight, totalVolume, totalDistance } = loadTotals;

    const totalTripDistance = R.path([GC.FIELD_TOTAL_TRIP_DISTANCE], totalDistance);
    const totalTripDistanceUom = R.path([GC.FIELD_TOTAL_TRIP_DISTANCE_UOM], totalDistance);

    const { pickedItems, pickedUpContainers } = getDataFromStops(stops);

    const totalPickupQuantity = G.makeTotalPickupQuantityObjectFromItems(pickedItems);

    const stopsQuantity = R.length(R.values(stops));

    const events = makeReqStops(stops, branchRefTypes, pickedItems, pickedUpContainers, true);

    const equipment = R.prop(GC.FIELD_LOAD_EQUIPMENT, referenceFormData);
    const equipments = G.ifElse(G.isNilOrEmpty(equipment), [], R.of(Array, equipment));

    const getAsyncLoadDistancesData = {
      stops, loadType, branchGuid, branchConfigs, stopPointsStrings, asyncLoadDistancesCallback: recalculateLoadDistancesSuccess,
    };

    const charges = G.getPropFromObject(GC.FIELD_CHARGES, rate);

    const customerRateDefaultProps = {
      ...props,
      events,
      services,
      equipment,
      equipments,
      totalWeight,
      totalDistance,
      stopsQuantity,
      totalTripDistance,
      totalPickupQuantity,
      totalTripDistanceUom,
      itemsVolume: totalVolume,
      stopCount: stopsQuantity,
      getAsyncLoadDistancesData,
      transportationModeGroupingList,
    };

    const customerRateProps = G.ifElse(
      R.or(G.isNilOrEmpty(rate), G.isNilOrEmpty(charges)),
      R.assoc('isCreateMode', true, customerRateDefaultProps),
      R.mergeRight({ isEditMode: true, initialValues: rate }, customerRateDefaultProps),
    );

    if (G.hasNotAmousCurrentUserPermissions(PC.CLO_RATE_WRITE)) {
      return <LockedCustomerRateForm {...customerRateProps} />;
    }

    return <CustomerRateForm {...customerRateProps} />;
  }

  if (R.equals(leftActiveTad, TAB_NAME_TRIP_PRICING)) {
    const { pickedItems, pickedUpContainers } = getDataFromStops(stops);

    const loadData = {
      [GC.FIELD_SERVICES]: R.propOr(services, GC.FIELD_SERVICES, rate),
      events: makeReqStops(stops, branchRefTypes, pickedItems, pickedUpContainers, true),
    };

    const totalPickupQuantity = G.makeTotalPickupQuantityObjectFromItems(pickedItems);

    return (
      <TripPricing {...props} loadData={loadData} totalPickupQuantity={totalPickupQuantity} />
    );
  }

  return null;
};

export const LeftSectionEmptyInfo = () => (
  <Box p='50px' boxShadow='0 2px 5px 0 rgba(0, 0, 0, 0.2)'>
    <Flex my='50px' justifyContent='center'>
      <img width={150} src={WAITING_PICTURE_SRC} alt='Waiting For Information' />
    </Flex>
    <Flex flexDirection='column'>
      <Text fontSize='16px' color={G.getTheme('colors.dark.grey')}>
        {G.getWindowLocale('messages:select-branch', 'Please, select Branch')}
      </Text>
    </Flex>
  </Box>
);

const LeftSection = (props: Object) => {
  const { leftActiveTad } = props;

  const width = getLeftSectionWidth(leftActiveTad);

  return (
    <Box
      mb={20}
      mr={25}
      width={width}
      flexShrink={0}
      borderRadius='4px'
      bg={G.getTheme('colors.white')}
    >
      {renderLeftRightEmptyInfo(props) && <LeftSectionEmptyInfo />}
      {renderLeftRightContent(props) && renderForm(props)}
    </Box>
  );
};

export default LeftSection;
