import * as R from 'ramda';
import { createLogic } from 'redux-logic';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// utilities
import routesMap from '../../utilities/routes';
// feature permission
import RoutePermission from './route-permission';
//////////////////////////////////////////////////

export const handleRoutePermissionLogic = createLogic({
  type: [
    // styling
    GC.CHECK_VISIT_STYLING_PAGE,
    // user
    GC.CHECK_VISIT_USER_EDIT_PAGE,
    GC.CHECK_VISIT_USERS_LIST_PAGE,
    GC.CHECK_VISIT_USER_CREATE_PAGE,
    // role
    GC.CHECK_VISIT_ROLE_EDIT_PAGE,
    GC.CHECK_VISIT_ROLES_LIST_PAGE,
    GC.CHECK_VISIT_ROLE_CREATE_PAGE,
    // branch
    GC.CHECK_VISIT_BRANCH_EDIT_PAGE,
    GC.CHECK_VISIT_BRANCH_LIST_PAGE,
    GC.CHECK_VISIT_BRANCH_CREATE_PAGE,
    GC.CHECK_VISIT_BRANCH_DETAILS_PAGE,
    // configs
    GC.CHECK_VISIT_CONFIG_UI_PAGE,
    GC.CHECK_VISIT_CONFIG_CLO_PAGE,
    GC.CHECK_VISIT_CONFIG_TEL_PAGE,
    GC.CHECK_VISIT_CONFIG_TRUCK_PAGE,
    GC.CHECK_VISIT_CONFIG_AUDIT_PAGE,
    GC.CHECK_VISIT_CONFIG_DRIVER_PAGE,
    GC.CHECK_VISIT_CONFIG_RATING_PAGE,
    GC.CHECK_VISIT_CONFIG_TRAILER_PAGE,
    GC.CHECK_VISIT_CONFIG_CARRIER_PAGE,
    GC.CHECK_VISIT_CONFIG_GENERAL_PAGE,
    GC.CHECK_VISIT_CONFIG_OPTIONAL_PAGE,
    GC.CHECK_VISIT_CONFIG_PASSWORD_PAGE,
    GC.CHECK_VISIT_CONFIG_INVOICES_PAGE,
    GC.CHECK_VISIT_CONFIG_TEMPLATES_PAGE,
    GC.CHECK_VISIT_CONFIG_MOBILEAPP_PAGE,
    GC.CHECK_VISIT_CONFIG_INTEGRATION_PAGE,
    GC.CHECK_VISIT_CONFIG_COMMUNICATION_PAGE,
    GC.CHECK_VISIT_CONFIG_FLEET_GENERAL_PAGE,
    GC.CHECK_VISIT_CONFIG_SERVICE_VENDOR_PAGE,
    GC.CHECK_VISIT_CONFIG_TASK_MANAGEMENT_PAGE,
    GC.CHECK_VISIT_CONFIG_CLAIM_MANAGEMENT_PAGE,
    // item
    GC.CHECK_VISIT_ITEMS_LIST_PAGE,
    // quotes
    GC.CHECK_VISIT_QUOTES_LIST_PAGE,
    // order quotes
    GC.CHECK_VISIT_ORDER_QUOTES_LIST_PAGE,
    // edi
    GC.CHECK_VISIT_EDI_LIST_PAGE,
    // carrier edi
    GC.CHECK_VISIT_CARRIER_EDI_LIST_PAGE,
    // public clo
    GC.CHECK_VISIT_PUBLIC_CLO_LIST_PAGE,
    // location
    GC.CHECK_VISIT_LOCATIONS_LIST_PAGE,
    // reference
    GC.CHECK_VISIT_REFERENCE_TYPES_LIST_PAGE,
    // sequence
    GC.CHECK_VISIT_SEQUENCE_EDIT_PAGE,
    GC.CHECK_VISIT_SEQUENCES_LIST_PAGE,
    GC.CHECK_VISIT_SEQUENCE_CREATE_PAGE,
    // fleet
    GC.CHECK_VISIT_FLEET_VENDOR_EDIT_PAGE,
    GC.CHECK_VISIT_FLEET_VENDOR_LIST_PAGE,
    GC.CHECK_VISIT_FLEET_TRUCK_EDIT_PAGE,
    GC.CHECK_VISIT_FLEET_DRIVER_EDIT_PAGE,
    GC.CHECK_VISIT_FLEET_TRUCKS_LIST_PAGE,
    GC.CHECK_VISIT_FLEET_TRAILER_EDIT_PAGE,
    GC.CHECK_VISIT_FLEET_DRIVERS_LIST_PAGE,
    GC.CHECK_VISIT_FLEET_TRAILERS_LIST_PAGE,
    GC.CHECK_VISIT_FLEET_SHARED_COMPONENT_LIST_PAGE,
    GC.CHECK_VISIT_FLEET_EQUIPMENT_SERVICE_LIST_PAGE,
    GC.CHECK_VISIT_FLEET_DRIVER_PAYROLL_ACCESSORIAL_LIST_PAGE,
    GC.CHECK_VISIT_FLEET_VENDOR_PAYROLL_ACCESSORIAL_LIST_PAGE,
    // fleet-profile
    GC.CHECK_VISIT_FLEET_PROFILE_TRUCK_PAGE,
    GC.CHECK_VISIT_FLEET_PROFILE_DRIVER_PAGE,
    GC.CHECK_VISIT_FLEET_PROFILE_VENDOR_PAGE,
    GC.CHECK_VISIT_FLEET_PROFILE_TRAILER_PAGE,
    // carrier
    GC.CHECK_VISIT_CARRIER_EDIT_PAGE,
    GC.CHECK_VISIT_CARRIER_LIST_PAGE,
    GC.CHECK_VISIT_CARRIER_PROFILE_PAGE,
    GC.CHECK_VISIT_CARRIER_CONTRACT_DETAILS_PAGE,
    // loads
    GC.CHECK_VISIT_CLO_PAGE,
    GC.CHECK_VISIT_TEL_PAGE,
    GC.CHECK_VISIT_NEW_DO_PAGE,
    GC.CHECK_VISIT_CLO_LIST_PAGE,
    GC.CHECK_VISIT_TEL_LIST_PAGE,
    GC.CHECK_VISIT_ROUTE_LIST_PAGE,
    GC.CHECK_VISIT_CREATE_LOAD_PAGE,
    GC.CHECK_VISIT_LOAD_PLANNER_PAGE,
    GC.CHECK_VISIT_ROUTE_BUILDER_PAGE,
    GC.CHECK_VISIT_ROUTE_REPORT_LIST_PAGE,
    GC.CHECK_VISIT_DISPATCH_BOARD_TEL_PAGE,
    GC.CHECK_VISIT_DISPATCH_BOARD_CLO_PAGE,
    GC.CHECK_VISIT_DISPATCH_DETAILS_LOAD_PAGE,
    GC.CHECK_VISIT_DISPATCH_DETAILS_ORDER_PAGE,
    // invoice
    GC.CHECK_VISIT_DRIVER_PAYROLL_PAGE,
    GC.CHECK_VISIT_INVOICE_VENDOR_LIST_PAGE,
    GC.CHECK_VISIT_INVOICE_CARRIER_LIST_PAGE,
    GC.CHECK_VISIT_INVOICE_CUSTOMER_LIST_PAGE,
    GC.CHECK_VISIT_CUSTOMER_MASTER_INVOICES_PAGE,
    GC.CHECK_VISIT_INVOICE_DRIVER_PAYROLL_LIST_PAGE,
    GC.CHECK_VISIT_INVOICE_SERVICE_VENDOR_LIST_PAGE,
    // analytic
    GC.CHECK_VISIT_ANALYTICS_PAGE,
    GC.CHECK_VISIT_DASHBOARDS_PAGE,
    GC.CHECK_VISIT_ANALYTIC_MAPS_PAGE,
    // dashboards
    GC.CHECK_VISIT_DASHBOARDS_PAYROLL_PAGE,
    // chart
    GC.CHECK_VISIT_CHART_LIST_PAGE,
    GC.CHECK_VISIT_CHART_EDIT_PAGE,
    GC.CHECK_VISIT_CHART_CREATE_PAGE,
    // notification
    GC.CHECK_VISIT_NOTIFICATION_LIST_PAGE,
    // load-boars
    GC.CHECK_VISIT_LOAD_BOARD_PAGE,
    // report
    GC.CHECK_VISIT_REPORTS_LIST_PAGE,
    // profile
    GC.CHECK_VISIT_USER_PROFILE_PAGE,
    // import
    GC.CHECK_VISIT_IMPORT_PAGE,
    // fuel cards
    GC.CHECK_VISIT_FUEL_CARDS_PAGE,
    // toll charges
    GC.CHECK_VISIT_TOLL_CHARGES_PAGE,
    // ifta report
    GC.CHECK_VISIT_IFTA_REPORT_PAGE,
    // templates
    GC.CHECK_VISIT_DO_TEMPLATE_LIST_PAGE,
    GC.CHECK_VISIT_TEMPLATE_DETAILS_PAGE,
    GC.CHECK_VISIT_CONTAINER_TYPE_LIST_PAGE,
    GC.CHECK_VISIT_ROUTE_TEMPLATE_LIST_PAGE,
    GC.CHECK_VISIT_LITE_TEMPLATE_DETAILS_PAGE,
    GC.CHECK_VISIT_CONTAINER_TEMPLATE_LIST_PAGE,
    GC.CHECK_VISIT_COMPENSATION_TEMPLATE_LIST_PAGE,
    // load-planner-template
    GC.CHECK_VISIT_LOAD_PLANNER_TEMPLATE_LIST_PAGE,
    // contact-book
    GC.CHECK_VISIT_CONTACT_BOOK_PAGE,
    // shared-accessorials
    GC.CHECK_VISIT_SHARED_ACCESORIALS_PAGE,
    // average-fuel-price
    GC.CHECK_VISIT_AVERAGE_FUEL_PRICE_PAGE,
    // carrier contracts
    GC.CHECK_VISIT_CARRIER_CONTRACTS_PAGE,
    // customer contracts
    GC.CHECK_VISIT_CUSTOMER_CONTRACTS_PAGE,
    // rating geo fencing zone
    GC.CHECK_VISIT_RATING_GEO_FENCING_ZONE_PAGE,
    // report geo fencing zone
    GC.CHECK_VISIT_REPORT_GEO_FENCING_ZONE_PAGE,
    // drivers card
    GC.CHECK_VISIT_DRIVERS_CARD_PAGE,
    // dispatch-group
    GC.CHECK_VISIT_DISPATCH_GROUP_PAGE,
    // normalize currency list
    GC.CHECK_VISIT_NORMALIZE_CURRENCY_LIST_PAGE,
    // commission assignment
    GC.CHECK_VISIT_COMMISSION_ASSIGNMENT_PAGE,
    // carrier-rating-report
    GC.CHECK_VISIT_CARRIER_RATING_REPORT_LIST_PAGE,
    // customer-portal
    GC.CHECK_VISIT_CUSTOMER_PORTAL_PAGE,
    // import-report
    GC.CHECK_VISIT_IMPORT_REPORT_PAGE,
    // charge-report
    GC.CHECK_VISIT_CLO_RATE_CHARGE_REPORT_PAGE,
    GC.CHECK_VISIT_TEL_RATE_CHARGE_REPORT_PAGE,
    // quote
    GC.CHECK_VISIT_NEW_DO_QUOTE_PAGE,
    // service-vendor
    GC.CHECK_VISIT_SERVICE_VENDOR_EDIT_PAGE,
    GC.CHECK_VISIT_SERVICE_VENDOR_LIST_PAGE,
    // rate shop
    GC.CHECK_VISIT_RATE_SHOP_PAGE,
    // route mass create report
    GC.CHECK_VISIT_ROUTE_MASS_CREATE_REPORT_PAGE,
    // los-subscription
    GC.CHECK_VISIT_ROUTE_LOS_SUBSCRIPTION_PAGE,
    // task-management
    GC.CHECK_VISIT_TASK_MANAGEMENT_PAGE,
    // available-driver
    GC.CHECK_VISIT_ROUTE_AVAILABLE_DRIVER_PAGE,
    // claim-management
    GC.CHECK_VISIT_CLAIM_MANAGEMENT_LIST_PAGE,
    GC.CHECK_VISIT_CLAIM_MANAGEMENT_CLAIM_DETAILS_PAGE,
  ],
  validate({ getState, action }: Object, allow: void, reject: void) {
    const state = getState();
    const type = action.type;
    const route = action.payload;
    const authorities = state.permissions.authorities;
    const permissions = R.pathOr([], [route], RoutePermission);
    const isAccess = G.ifElse(R.isEmpty(permissions), true, G.checkStringsContains(permissions, authorities));
    if (R.not(isAccess)) {
      const msg = G.getWindowLocale(
        'messages:roles:page-access-denied',
        'Sorry, you cannot access this page',
      );
      G.showToastrMessageSimple('info', msg);
      G.goToRoute(routesMap.homePage);
      reject();
    }
    const allowedType = `${type}_ALLOWED`;
    allow({ type: GC[allowedType], payload: route });
  },
  process({ getState, action }: Object, dispatch: void, done: void) {
    dispatch({ type: GC.ADD_ACCESS_TO_PAGE_REQUEST, payload: action.payload });
    dispatch(action);
    done();
  },
});

export default [
  handleRoutePermissionLogic,
];
