import * as R from 'ramda';
import { put, call, select, takeLatest } from 'redux-saga/effects';
// components
import { openLoader, closeLoader } from '../../components/loader/actions';
// features
import { makeSelectCurrentBranchGuid } from '../branch/selectors';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// sagas
import { visitPageSaga } from '../../sagas';
// utilities
import { sendRequest } from '../../utilities/http';
import endpointsMap from '../../utilities/endpoints';
// feature public-clo
import * as A from './actions';
import { makeSelectPagination, makeSelectUsedReport } from './selectors';
//////////////////////////////////////////////////

export function* handleGetReportListRequest() {
  try {
    yield put(A.setListLoading(true));
    const usedReport = yield select(makeSelectUsedReport());
    const pagination = yield select(makeSelectPagination());
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
    const reqBody = {
      ...pagination,
      ...usedReport,
      fields: [],
      orderFields: [],
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
    };
    const res = yield call(
      sendRequest,
      'post',
      endpointsMap.exchangeRateList,
      { data: reqBody },
    );
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getReportListSuccess(data));
      yield put(A.setListLoading(false));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetReportListRequest fail');
      yield put(A.setListLoading(false));
    }
  } catch (error) {
    yield call(G.handleException, error, 'handleGetReportListRequest exception');
    yield put(A.setListLoading(false));
  }
}

export function* handleVisitPageSaga({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield call(visitPageSaga, payload, GC.CHECK_VISIT_NORMALIZE_CURRENCY_LIST_PAGE);
    yield put(openLoader({ showDimmer: true }));
    yield put(A.setInitialState());
    yield call(handleGetReportListRequest, { payload: true });
    yield put(closeLoader());
    break;
  }
}

function* pageWatcherSaga() {
  yield takeLatest(A.getReportListRequest, handleGetReportListRequest);
  yield takeLatest(GC.VISIT_NORMALIZE_CURRENCY_LIST_PAGE, handleVisitPageSaga);
}

export default pageWatcherSaga;
