import * as R from 'ramda';
import * as P from 'plow-js';
import { createReducer } from 'redux-act';
// features
import { updateNotificationsStore } from '../sockets/actions';
// helpers/constants
import * as G from '../../helpers';
// actions
import * as A from './actions';
//////////////////////////////////////////////////

export const initialState = {
  wsCount: 0,
  totalCount: 0,
  unreadCount: 0,
  loading: false,
  itemList: null,
  pagination: {
    limit: 20,
    offset: 0,
  },
};

const setInitialState = () => (
  initialState
);

const setListLoading = (state: Object, loading: boolean) => (
  P.$set('loading', loading, state)
);

const resetListAndPagination = (state: Object) => (
  P.$all(
    P.$set('wsCount', 0),
    P.$set('itemList', null),
    P.$set('pagination', initialState.pagination),
    state,
  )
);

const markAllAsReadSuccess = (state: Object, data: Object) => {
  const checkRead = R.map(
    (item: Object) => {
      if (R.includes(item.mainGuid, data.guids)) {
        return R.mergeRight(item, {read: data.read});
      }
      return item;
    },
    state.itemList);
  return P.$set('itemList', checkRead, state);
};

const getItemListSuccess = (state: Object, data: Object) => {
  const { itemList, pagination } = state;
  const newOffset = R.add(pagination.offset, pagination.limit);
  const indexAdditional = G.ifElse(
    R.isNil(itemList),
    0,
    R.length(R.values(itemList)),
  );
  const newNotifications = data.results.map((item: Object, index: number) => R.mergeRight(
    item,
    {
      selected: false,
      index: R.add(index, indexAdditional),
    },
  ));
  const newList = R.mergeRight(itemList, R.indexBy(R.prop('mainGuid'), newNotifications));

  return P.$all(
    P.$set('itemList', newList),
    P.$set('pagination.limit', 10),
    P.$set('totalCount', data.totalCount),
    P.$set('wsCount', G.ifElse(R.gt(state.wsCount, data.totalCount), state.wsCount, data.totalCount)),
    P.$set(
      'pagination.offset',
      G.ifElse(
        R.gt(data.totalCount, newOffset),
        newOffset,
        data.totalCount,
      ),
    ),
    state,
  );
};

const selectItem = (state: Object, id: string) => (
  P.$set(
    'itemList',
    R.indexBy(R.prop('mainGuid'), G.selectItem(R.values(state.itemList), id)),
    state,
  )
);

const getCurrentUnreadCount = (state: Object, count: number) => (
  P.$set('unreadCount', count, state)
);

const updateNotificationItemList = (state: Object, data: Object) => {
  const notification = {
    ...data.notification,
    index: 0,
    read: data.read,
    selected: false,
    mainGuid: data.guid,
    createdDate: G.fromNow(data.createdDate),
  };
  const newItemsList = R.assoc(
    data.guid,
    notification,
    R.map((item: Object) => R.assoc('index', R.inc(item.index), item), state.itemList),
  );
  return P.$all(
    P.$set('itemList', newItemsList),
    P.$set('wsCount', R.inc(state.wsCount)),
    P.$set('unreadCount', R.inc(state.unreadCount)),
    state,
  );
};

export default createReducer({
  [A.selectItem]: selectItem,
  [A.setListLoading]: setListLoading,
  [A.setInitialState]: setInitialState,
  [A.getItemListSuccess]: getItemListSuccess,
  [A.markAllAsReadSuccess]: markAllAsReadSuccess,
  [A.getUnreadCountSuccess]: getCurrentUnreadCount,
  [A.resetListAndPagination]: resetListAndPagination,
  [updateNotificationsStore]: updateNotificationItemList,
}, initialState);
