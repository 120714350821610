import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, branch, compose, withState, withHandlers, renderNothing } from 'react-recompose';
// components
import { EditReport } from '../../../components/edit-report';
import { openModal, closeModal } from '../../../components/modal/actions';
import ReportFormatPanel from '../../../components/edit-report/components/report-format-panel';
import { PopupWithReportAndQuickFilters } from '../../../components/filter/report-and-quick-filters';
// features
import { makeSelectCurrentBranchGuid } from '../../branch/selectors';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Flex } from '../../../ui';
// utilities
import routesMap from '../../../utilities/routes';
// feature pivot-table
import { makeSelectPivotTableData } from '../selectors';
import { filterPropsMap } from '../settings/filter-params';
import {
  updateReportRequest,
  createReportRequest,
  changeDefaultReportRequest,
} from '../actions';
//////////////////////////////////////////////////

const enhance = compose(
  withState('pivotState', 'setPivotState', null),
  withHandlers({
    handleEditReport: (props: Object) => () => {
      const {
        openModal,
        setUsedReport,
        selectedReport,
        requestPending,
        disableRefFilter,
        createReportRequest,
        updateReportRequest,
        getPivotTableDataRequest,
      } = props;

      const fields = R.prop(selectedReport.type, filterPropsMap);

      const component = (
        <EditReport
          maxFields={8}
          fields={fields}
          setReport={setUsedReport}
          disableSortByFields={true}
          usedReport={selectedReport}
          requestPending={requestPending}
          disableRefFilter={disableRefFilter}
          onReportSet={getPivotTableDataRequest}
          createReportRequest={createReportRequest}
          updateReportRequest={updateReportRequest}
        />
      );

      const modal = G.getDefaultReportModal(component, 1150);

      openModal(modal);
    },
    handleClickPivotCancel: () => G.goToRoute(routesMap.analyticsDashboard),
  }),
  branch(
    (props: Object) => R.isNil(props.selectedReport),
    renderNothing,
  ),
  pure,
);

const ReportSelector = (props: Object) => {
  const {
    reportList,
    setUsedReport,
    selectedReport,
    handleEditReport,
    handleSelectReport,
    handlePinOrUnpinReport,
    getPivotTableDataRequest,
    changeDefaultReportRequest } = props;
  const filterProps = R.prop(selectedReport.type, filterPropsMap);

  return (
    <Flex>
      <PopupWithReportAndQuickFilters
        version={2}
        popperWithCount={true}
        filterProps={filterProps}
        setUsedReport={setUsedReport}
        selectedReport={selectedReport}
        handleListRequest={getPivotTableDataRequest}
        additionalStyles={{ popperComponentWrapperMargin: '6px 15px 0 15px' }}
        filterCount={R.pathOr(0, ['searchCriteria', 'length'], selectedReport)}
      />
      <ReportFormatPanel
        version={2}
        noExportable={true}
        options={reportList}
        selectedReport={selectedReport}
        reportType={selectedReport.type}
        selectAction={handleSelectReport}
        editReportAction={handleEditReport}
        handlePinOrUnpinReport={handlePinOrUnpinReport}
        changeDefaultReportRequest={changeDefaultReportRequest}
        filterBy={G.setFilterByParams(props.refList, filterProps)}
        initialValues={{ 'report-panel': R.pathOr('', [GC.FIELD_NAME], selectedReport) }}
      />
    </Flex>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  branchGuid: makeSelectCurrentBranchGuid(state),
  pivotTableData: makeSelectPivotTableData(state),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  createReportRequest,
  updateReportRequest,
  changeDefaultReportRequest,
})(enhance(ReportSelector));
