import * as R from 'ramda';
import React from 'react';
// helpers/constants
import * as G from '../../../helpers';
// ui
import { Box, Flex } from '../../../ui';
//////////////////////////////////////////////////

const SectionDivider = ({ mt, mb, text }: Object) => (
  <Flex mt={R.or(mt, 20)} mb={R.or(mb, 20)}>
    <Box height='1px' flexGrow='1' bg={G.getTheme('colors.dark.grey')} />
    {
      G.isNotNilAndNotEmpty(text) &&
      <Box px='5px' color={G.getTheme('colors.dark.grey')}>{text}</Box>
    }
    <Box height='1px' flexGrow='1' bg={G.getTheme('colors.dark.grey')} />
  </Flex>
);

export default SectionDivider;
