import * as R from 'ramda';
import * as P from 'plow-js';
import React, { Component } from 'react';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// utilities
import { sendRequest } from '../../../utilities/http';
import endpointsMap from '../../../utilities/endpoints';
// feature rate
import {
  mapDriversToOptions,
  mapEquipmentsToOptions,
  mapAvailableDriversToOptions,
  mapRateDataWithProperQuantity,
} from '../helpers';
//////////////////////////////////////////////////

// NOTE: refactor without class component and render props

class FleetAssignment extends Component {
  constructor(props: Object) {
    super(props);

    this.state = {
      rateData: {},
      rateInfo: null,
      availTrucks: [],
      vendorRates: [],
      trucksOptions: [],
      availTrailers: [],
      trailersOptions: [],
      availableDrivers: {},
      primaryDriversOptions: [],
      secondaryDriversOptions: [],
    };

    this.getVendorRates = this.getVendorRates.bind(this);
    this.getAvailableDrivers = this.getAvailableDrivers.bind(this);
    this.getAssignmentFromOrder = this.getAssignmentFromOrder.bind(this);
    this.getDriverCompensations = this.getDriverCompensations.bind(this);
    this.getAssignmentByLoadData = this.getAssignmentByLoadData.bind(this);
    this.getAvailTrucksByTelGuid = this.getAvailTrucksByTelGuid.bind(this);
    this.getAvailTrailersByTelGuid = this.getAvailTrailersByTelGuid.bind(this);
    this.getSingleDriverCompensations = this.getSingleDriverCompensations.bind(this);
    this.getAssignmentFromRouteBuilder = this.getAssignmentFromRouteBuilder.bind(this);
    this.getAvailTrucksByBranchGuidAndTelEvents = this.getAvailTrucksByBranchGuidAndTelEvents.bind(this);
    this.getAvailTrailersByBranchGuidAndTelEvents = this.getAvailTrailersByBranchGuidAndTelEvents.bind(this);
  }

  async getAvailableDrivers(reqBody: string) {
    const { openLoader, closeLoader } = this.props;

    if (G.isFunction(openLoader)) {
      openLoader({ showDimmer: true});
    }

    const res = await sendRequest(
      'post',
      endpointsMap.availableDrivers,
      { data: reqBody },
    );

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const { drivers } = data;

      const teamDrivers = drivers.filter((driver: Object) => G.isTrue(driver.teamDriver));

      const newState = P.$all(
        P.$set('availableDriversData', data),
        P.$set('rateInfo', R.omit('drivers', data)),
        P.$set('secondaryDriversOptions', mapAvailableDriversToOptions(teamDrivers)),
        P.$set('primaryDriversOptions', mapAvailableDriversToOptions(drivers, true)),
        this.state,
      );
      this.setState(newState);
    } else {
      G.handleFailResponseSimple(res);
    }

    if (G.isFunction(closeLoader)) {
      closeLoader();
    }
  }

  async getDriverCompensations(reqBody: string) {
    const { openLoader, closeLoader } = this.props;

    if (G.isFunction(openLoader)) {
      openLoader({ showDimmer: true});
    }

    const res = await sendRequest(
      'post',
      endpointsMap.driverCompensations,
      { data: reqBody },
    );

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const teamDrivers = data.rateData.filter((driver: Object) => G.isTrue(driver.teamDriver));
      const forAssignDriverGuid = R.path(['rateData', '0', GC.FIELD_GUID], data);

      const newState = P.$all(
        P.$set(
          'rateInfo',
          R.assoc('forAssignDriverGuid', forAssignDriverGuid, R.omit(['rateData'], data)),
        ),
        P.$set('secondaryDriversOptions', mapDriversToOptions(teamDrivers)),
        P.$set('rateData', mapRateDataWithProperQuantity(data.rateData)),
        P.$set('primaryDriversOptions', mapDriversToOptions(data.rateData, true)),
        P.$set('rateGeneratedGuid', G.generateGuid()),
        this.state,
      );

      this.setState(newState);
    } else {
      G.handleFailResponseSimple(res, true, 'withFleetAssignment -> getDriverCompensations');
    }

    if (G.isFunction(closeLoader)) {
      closeLoader();
    }
  }

  getAssignmentByLoadData(reqBody: string) {
    const { telGuid, shouldGetCompensations } = reqBody;

    this.getAvailTrucksByTelGuid(telGuid);
    this.getAvailTrailersByTelGuid(telGuid);

    if (G.isTrue(shouldGetCompensations)) return this.getDriverCompensations(reqBody);

    this.getAvailableDrivers(reqBody);
  }

  getAssignmentFromRouteBuilder(reqBody: string) {
    const { loadGuid, shouldGetCompensations } = reqBody;

    const branchGuid = R.prop(GC.FIELD_BRANCH_GUID, reqBody);

    const events = R.path(['loadData', 'events'], reqBody);

    const reqBodyWithEvents = {
      events,
      loadGuid,
      [GC.FIELD_BRANCH_GUID]: branchGuid,
    };

    this.getAvailTrucksByBranchGuidAndTelEvents(reqBodyWithEvents);
    this.getAvailTrailersByBranchGuidAndTelEvents(reqBodyWithEvents);

    if (G.isTrue(shouldGetCompensations)) return this.getDriverCompensations(reqBody);

    this.getAvailableDrivers(reqBodyWithEvents);
  }

  getAssignmentFromOrder(reqBody: string) {
    const { shouldGetCompensations } = reqBody;

    const branchGuid = R.prop(GC.FIELD_BRANCH_GUID, reqBody);

    const events = R.path(['loadData', 'events'], reqBody);

    const reqBodyWithEvents = {
      events,
      [GC.FIELD_BRANCH_GUID]: branchGuid,
    };

    this.getAvailTrucksByBranchGuidAndTelEvents(reqBodyWithEvents);
    this.getAvailTrailersByBranchGuidAndTelEvents(reqBodyWithEvents);

    if (G.isTrue(shouldGetCompensations)) return this.getDriverCompensations(reqBody);

    this.getAvailableDrivers(reqBodyWithEvents);
  }

  async getSingleDriverCompensations(reqBody: string) {
    const { openLoader, closeLoader } = this.props;

    const currentState = this.state;
    const currentRateData = R.path(['rateData'], currentState);

    if (G.isNotNilAndNotEmpty(currentRateData)) return;

    if (G.isFunction(openLoader)) {
      openLoader({ showDimmer: true});
    }

    const res = await sendRequest(
      'post',
      endpointsMap.driverCompensations,
      { data: reqBody },
    );

    const { data, status } = res;

    let returnData = null;

    if (G.isResponseSuccess(status)) {
      const rateData = R.path(['rateData'], data);

      if (G.isNilOrEmpty(rateData)) return;

      const driver = R.head(rateData);

      returnData = driver;

      const currentAvailableDriversDataDrivers = R.pathOr([], ['availableDriversData', 'drivers'], currentState);

      const mappedRateData = mapDriversToOptions(rateData, true);

      const option = R.head(mappedRateData);
      const optionValue = R.path(['value'], option);

      const newPrimaryDriverOptions = R.compose(
        R.map((item: Object) => {
          if (R.propEq(optionValue, 'value', item)) return option;

          return item;
        }),
      )(R.pathOr([], ['primaryDriversOptions'], currentState));

      const newAvailableDriversDataDrivers = R.compose(
        R.map((item: Object) => {
          if (R.eqProps(GC.FIELD_GUID, driver, item)) return R.mergeRight(item, driver);

          return item;
        }),
      )(currentAvailableDriversDataDrivers);

      const newState = P.$all(
        P.$set('availableDriversData.drivers', newAvailableDriversDataDrivers),
        P.$set('primaryDriversOptions', newPrimaryDriverOptions),
        P.$set('rateGeneratedGuid', G.generateGuid()),
        this.state,
      );

      this.setState(newState);
    } else {
      G.handleFailResponseSimple(res, true, 'withFleetAssignment -> getSingleDriverCompensations');
    }

    G.callFunction(closeLoader);

    return returnData;
  }

  async getVendorRates(reqBody: string) {
    const { openLoader, closeLoader } = this.props;

    const currentState = this.state;

    const { vendorRates, availTrucks, trucksOptions } = currentState;
    const { truckGuids } = reqBody;

    const currentTruckVendorRatesGuids = R.map(R.prop(GC.FIELD_GUID), vendorRates);
    const truckGuidsToUse = R.map(R.prop(GC.FIELD_GUID), availTrucks);

    const guidsToUse = G.ifElse(
      G.isNilOrEmpty(truckGuids),
      R.without(currentTruckVendorRatesGuids, truckGuidsToUse),
      R.without(currentTruckVendorRatesGuids, R.or(truckGuids, [])),
    );

    if (G.isNilOrEmpty(guidsToUse)) {
      return this.setState(P.$set('vendorRateGeneratedGuid', G.generateGuid(), currentState));
    }

    if (G.isFunction(openLoader)) {
      openLoader({ showDimmer: true});
    }

    const res = await sendRequest(
      'post',
      endpointsMap.vendorRates,
      { data: R.assoc('truckGuids', guidsToUse, reqBody) },
    );

    const { data, status } = res;

    let returnData = null;

    if (G.isResponseSuccess(status)) {
      if (G.isNilOrEmpty(data)) {
        return G.callFunction(closeLoader);
      }

      const newVendorRates = R.concat(vendorRates, data);
      const indexedRates = R.indexBy(R.prop(GC.FIELD_GUID), data);

      returnData = newVendorRates;

      const newTrucksOptions = R.map((option: Object) => {
        const { label, vendorGuid } = option;

        const vendorRate = R.path([vendorGuid], indexedRates);

        if (G.isNilOrEmpty(vendorRate)) return option;

        const rateTotal = R.prop('total', vendorRate);

        const totalString = G.ifElse(
          G.isNotNilAndNotEmpty(rateTotal),
          `,
          ${G.getWindowLocale('titles:total-rate', 'Total Rate')}: $${rateTotal}`,
          '',
        );

        const newLabel = R.compose(
          R.head,
          R.split(','),
        )(label);

        return R.assoc('label', `${newLabel}${totalString}`, option);
      }, trucksOptions);

      const newState = P.$all(
        P.$set('vendorRates', newVendorRates),
        P.$set('trucksOptions', newTrucksOptions),
        P.$set('vendorRateGeneratedGuid', G.generateGuid()),
        currentState,
      );

      this.setState(newState);
    } else {
      G.handleFailResponseSimple(res, true, 'withFleetAssignment -> getVendorRates');
    }

    G.callFunction(closeLoader);

    return returnData;
  }

  async getAvailTrailersByTelGuid(telGuid: string) {
    const res = await sendRequest(
      'get',
      endpointsMap.availableTrailers,
      G.setParamToRequestQuery(GC.FIELD_TEL_GUID, telGuid),
    );

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const newState = P.$all(
        P.$set('availTrailers', data),
        P.$set('trailersOptions', mapEquipmentsToOptions(data)),
        this.state,
      );

      this.setState(newState);
    } else {
      G.handleFailResponseSimple(res);
    }
  }

  async getAvailTrucksByTelGuid(telGuid: string) {
    const res = await sendRequest(
      'get',
      endpointsMap.availableTrucks,
      G.setParamToRequestQuery(GC.FIELD_TEL_GUID, telGuid),
    );

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const newState = P.$all(
        P.$set('availTrucks', data),
        P.$set('trucksOptions', mapEquipmentsToOptions(data)),
        this.state,
      );

      this.setState(newState);
    }
  }

  async getAvailTrailersByBranchGuidAndTelEvents(reqBody: string) {
    const res = await sendRequest(
      'post',
      endpointsMap.availableTrailers,
      { data: reqBody },
    );

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const newState = P.$all(
        P.$set('availTrailers', data),
        P.$set('trailersOptions', mapEquipmentsToOptions(data)),
        this.state,
      );

      this.setState(newState);
    }
  }

  async getAvailTrucksByBranchGuidAndTelEvents(reqBody: string) {
    const res = await sendRequest(
      'post',
      endpointsMap.availableTrucks,
      { data: reqBody },
    );

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const newState = P.$all(
        P.$set('availTrucks', data),
        P.$set('trucksOptions', mapEquipmentsToOptions(data)),
        this.state,
      );

      this.setState(newState);
    }
  }

  render() {
    return (
      <div>
        {this.props.render(
          this.state,
          this.getVendorRates,
          this.getAssignmentFromOrder,
          this.getAssignmentByLoadData,
          this.getSingleDriverCompensations,
          this.getAssignmentFromRouteBuilder,
        )}
      </div>
    );
  }
}

const optionsToPick = [
  'trucksOptions',
  'trailersOptions',
  'primaryDriversOptions',
  'secondaryDriversOptions',
];

const withFleetAssignment = (Component: any) => {
  return class extends React.Component {
    render() {
      return (
        <FleetAssignment
          openLoader={this.props.openLoader}
          closeLoader={this.props.closeLoader}
          render={(
            parentState: Object,
            getVendorRates: Function,
            getAssignmentFromOrder: Function,
            getAssignmentByLoadData: Function,
            getSingleDriverCompensations: Function,
            getAssignmentFromRouteBuilder: Function,
          ) => (
            <Component
              {...this.props}
              getVendorRates={getVendorRates}
              rateData={parentState.rateData}
              rateInfo={parentState.rateInfo}
              availTrucks={parentState.availTrucks}
              vendorRates={parentState.vendorRates}
              availTrailers={parentState.availTrailers}
              getAssignmentFromOrder={getAssignmentFromOrder}
              getAssignmentByLoadData={getAssignmentByLoadData}
              rateGeneratedGuid={parentState.rateGeneratedGuid}
              availableDriversData={parentState.availableDriversData}
              fleetOptionsForSelect={R.pick(optionsToPick, parentState)}
              getSingleDriverCompensations={getSingleDriverCompensations}
              getAssignmentFromRouteBuilder={getAssignmentFromRouteBuilder}
              vendorRateGeneratedGuid={parentState.vendorRateGeneratedGuid}
            />
          )}
        />
      );
    }
  };
};

export {
  withFleetAssignment,
};
