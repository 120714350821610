import * as R from 'ramda';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
import { ENUMS } from '../../constants/enums';
// feature rate
import { telRateInitFields } from './driver/settings';
import { cloRateInitFields } from './customer/settings';
import { carrierRateInitFields } from './carrier/settings';
//////////////////////////////////////////////////

export const isActiveTabPrimaryDriver = R.equals('primary');

export const isActiveTabTeamDriver = R.equals('team');

export const isActiveTabVendor = R.equals('vendor');

const getPackageTypeAndTotalPickupQuantity = (totalQuantities: Object) => {
  if (G.isNilOrEmpty(totalQuantities)) return null;

  const packageTypes = R.keys(totalQuantities);

  const packageType = G.ifElse(
    R.equals(R.length(R.uniq(packageTypes)), 1),
    R.head(packageTypes),
    GC.ITEM_QUANTITY_UNIT_PIECES,
  );

  return {
    [GC.FIELD_PACKAGE_TYPE]: packageType,
    [GC.FIELD_TOTAL_PICKUP_QUANTITY]: R.sum(R.values(totalQuantities)),
  };
};

export const getDefaultsFromValues = (values: Object) => {
  const mode = R.pathOr('', [GC.FIELD_MODE], values);
  const currency = R.pathOr('', [GC.FIELD_CURRENCY], values);
  const serviceType = R.pathOr('', [GC.FIELD_SERVICE_TYPE], values);
  const serviceDays = R.pathOr('', [GC.FIELD_SERVICE_DAYS], values);

  return { mode, currency, serviceType, serviceDays };
};

const mapInitCharges = (charges: Array, isEditMode: boolean) => R.map(
  (item: Object) => {
    const toUse = G.ifElse(
      G.isTrue(isEditMode),
      R.assoc(GC.FIELD_GL_CODE, R.path([GC.FIELD_GL_CODE, GC.FIELD_DROPDOWN_OPTION_GUID], item), item),
      R.omit(GC.GROUPED_FIELDS.SYSTEM_OMIT_ARR_2, item),
    );

    return R.assoc(GC.FIELD_ID, R.or(G.getGuidFromObject(item), G.genShortId()), toUse);
  },
  G.addOneFuelChargeToCharges(charges),
);

const invoiceChargesFilter = (isInvoice: Object, charge: Object) => {
  if (isInvoice) {
    const internalExpense = R.prop(GC.FIELD_CHARGE_INTERNAL_EXPENSE, charge);

    return R.not(internalExpense);
  }

  return true;
};

export const getDriverInitCharges = (charges: Array, props: Object) => {
  const { isInvoice, isEditMode, sharedAccessorialsApplyTo } = props;

  if (G.isNilOrEmpty(charges)) return R.concat(G.createMainAndFuelCharges(), R.or(sharedAccessorialsApplyTo, []));

  const mapped = mapInitCharges(charges, isEditMode);

  return R.filter((item: Object) => invoiceChargesFilter(isInvoice, item), mapped);
};

export const makeResultObjectOnChangeDriver = (driver: Object, secondaryDriver: Object, props: Object) => {
  const { values, availTrucks, availTrailers } = props;

  const { mode, currency, serviceType, serviceDays } = getDefaultsFromValues(values);

  const assignment = G.getPropFromObject('assignment', driver);
  const truckGuid = G.getPropFromObject(GC.FIELD_TRUCK_GUID, assignment);

  const secondaryDriverGuid = G.getGuidFromObject(secondaryDriver);

  const secondaryDriverCharges = G.ifElse(
    G.isNotNilAndNotEmpty(secondaryDriverGuid),
    getDriverInitCharges(R.path([GC.FIELD_CHARGES], secondaryDriver), props),
    null,
  );

  const result = {
    [GC.FIELD_SECONDARY_DRIVER_CHARGES]: secondaryDriverCharges,
    [GC.FIELD_CURRENCY]: R.pathOr(currency, [GC.FIELD_CURRENCY], driver),
    [GC.FIELD_PRIMARY_DRIVER_GUID]: R.pathOr(null, [GC.FIELD_GUID], driver),
    [GC.FIELD_SECONDARY_DRIVER_GUID]: G.fromUndefinedToNull(secondaryDriverGuid),
    [GC.FIELD_DISTANCE_TO_START]: R.pathOr(0, [GC.FIELD_DISTANCE_TO_START], driver),
    [GC.FIELD_SERVICE_DAYS]: R.pathOr(serviceDays, [GC.FIELD_SERVICE_DAYS], driver),
    [GC.FIELD_MODE]: R.pathOr(mode, [GC.FIELD_MODE, GC.FIELD_DROPDOWN_OPTION_GUID], driver),
    [GC.FIELD_PRIMARY_DRIVER_CHARGES]: getDriverInitCharges(R.path([GC.FIELD_CHARGES], driver), props),
    [GC.FIELD_SERVICE_TYPE]: R.pathOr(serviceType, [GC.FIELD_SERVICE_TYPE, GC.FIELD_DROPDOWN_OPTION_GUID], driver),
  };

  if (R.any(R.propEq(truckGuid, GC.FIELD_GUID), availTrucks)) {
    result.truckGuid = truckGuid;
  }

  const trailerGuids = R.filter(
    (trailerGuid: string) => R.any(R.propEq(trailerGuid, GC.FIELD_GUID), availTrailers),
    R.pathOr([], [GC.FIELD_TRAILER_GUIDS], assignment),
  );

  result.trailerGuids = trailerGuids;

  return result;
};

export const makeResultObjectOnRemoveDriver = () => ({
  [GC.FIELD_TRUCK_GUID]: null,
  [GC.FIELD_TRAILER_GUIDS]: [],
  [GC.FIELD_DISTANCE_TO_START]: 0,
  [GC.FIELD_DEADHEAD_DISTANCE]: 0,
  [GC.FIELD_PRIMARY_DRIVER_GUID]: null,
  [GC.FIELD_START_POINT_LOCATION]: null,
  [GC.FIELD_FINISH_POINT_LOCATION]: null,
  [GC.FIELD_FLEET_VENDOR_CHARGES]: null,
  [GC.FIELD_SECONDARY_DRIVER_GUID]: null,
  [GC.FIELD_SECONDARY_DRIVER_CHARGES]: null,
  [GC.FIELD_PRIMARY_DRIVER_CHARGES]: G.createMainAndFuelCharges(),
});

export const getVendorRateData = (props: Object) => {
  const { vendorRates, truckFleetVendor } = props;

  const vendorGuid = G.getGuidFromObject(truckFleetVendor);
  const vendorRate = R.find(R.propEq(vendorGuid, GC.FIELD_GUID), vendorRates);
  const price = R.pathOr([], ['price'], vendorRate);
  const charges = R.pathOr([], ['charges'], vendorRate);

  return {
    price,
    charges,
  };
};

const getVendorInitCharges = (charges: Array, props: Object) => {
  const { isInvoice, isEditMode, sharedAccessorialsApplyTo } = props;

  if (G.isNilOrEmpty(charges)) return R.concat(G.createMainAndFuelCharges(), R.or(sharedAccessorialsApplyTo, []));

  const mapped = mapInitCharges(charges, isEditMode);

  return R.filter((item: Object) => invoiceChargesFilter(isInvoice, item), mapped);
};

export const makeResultObjectOnChangeVendorRate = (props: Object) => {
  const { values } = props;

  const { mode, currency, serviceType, serviceDays } = getDefaultsFromValues(values);
  const { price, charges } = getVendorRateData(props);

  const chargesToUse = getVendorInitCharges(charges, props);

  const result = {
    [GC.FIELD_FLEET_VENDOR_CHARGES]: chargesToUse,
    [GC.FIELD_CURRENCY]: R.pathOr(currency, [GC.FIELD_CURRENCY], price),
    [GC.FIELD_SERVICE_DAYS]: R.pathOr(serviceDays, [GC.FIELD_SERVICE_DAYS], price),
    [GC.FIELD_MODE]: R.pathOr(mode, [GC.FIELD_MODE, GC.FIELD_DROPDOWN_OPTION_GUID], price),
    [GC.FIELD_SERVICE_TYPE]: R.pathOr(serviceType, [GC.FIELD_SERVICE_TYPE, GC.FIELD_DROPDOWN_OPTION_GUID], price),
  };

  return result;
};

export const getFormGroupTitles = ({ isEditMode }: Object) => G.ifElse(
  isEditMode,
  G.getWindowLocale('titles:edit', 'Edit'),
  G.getWindowLocale('titles:add', 'Add'),
);

export const getTotalsFromValues = (values: Object) => (
  R.pick(GC.GROUPED_FIELDS.TRIP_TOTALS_ARR, values)
);

export const setDefaultCustomerRateValues = (props: Object) => {
  const {
    totalWeight,
    totalDistance,
    initialValues,
    defaultCurrency,
    defaultUomFields,
    asyncTotalDistance,
    totalPickupQuantity,
  } = props;

  if (G.isNotNilAndNotEmpty(initialValues)) {
    const initValues = G.mapDropdownsObjectInEntity(
      GC.FIELD_DROPDOWN_OPTION_GUID,
      [GC.FIELD_SERVICE_TYPE, GC.FIELD_MODE],
      initialValues,
    );
    const initWithDist = R.mergeRight(cloRateInitFields, asyncTotalDistance);
    const initWithWeight = R.mergeRight(initWithDist, totalWeight);

    return R.mergeRight(initWithWeight, initValues);
  }

  return G.setDefaultFieldsToRateInvoiceFormik({
    totalWeight,
    defaultCurrency,
    defaultUomFields,
    totalPickupQuantity,
    formikInitFields: cloRateInitFields,
    totalDistance: R.mergeRight(asyncTotalDistance, totalDistance),
  });
};

export const setDefaultCustomerRateValuesWithAsync = (props: Object) => {
  const { totalWeight, totalDistance, initialValues, asyncConfigs, asyncTotalDistance } = props;

  if (G.isNotNilAndNotEmpty(initialValues)) {
    const initValues = G.mapDropdownsObjectInEntity(
      GC.FIELD_DROPDOWN_OPTION_GUID,
      [GC.FIELD_SERVICE_TYPE, GC.FIELD_MODE],
      initialValues,
    );
    const initWithDist = R.mergeRight(cloRateInitFields, asyncTotalDistance);
    const initWithWeight = R.mergeRight(initWithDist, totalWeight);

    return R.mergeRight(initWithWeight, initValues);
  }

  return G.setDefaultFieldsToRateInvoiceAsyncFormik({
    totalWeight,
    formikInitFields: cloRateInitFields,
    defaultUomFields: G.getDefaultUomFields(asyncConfigs),
    totalDistance: R.mergeRight(asyncTotalDistance, totalDistance),
    currency: G.getConfigValueFromStore(GC.GENERAL_BRANCH_DEFAULT_CURRENCY, asyncConfigs),
    mode: G.getConfigValueFromStore(GC.CLO_GENERAL_RATE_TRANSPORTATION_MODE, asyncConfigs),
    serviceType: G.getConfigValueFromStore(GC.CLO_GENERAL_RATE_SERVICE_TYPE, asyncConfigs),
  });
};

// TODO: check quantities
export const getTotalValuesFromRateInfo = (rateInfo: Object) => {
  const totalVolume = R.pick([
    GC.FIELD_TOTAL_TRIP_VOLUME,
    GC.FIELD_TOTAL_TRIP_VOLUME_UOM,
  ], R.or(rateInfo, {}));

  const totalWeight = R.pick([
    GC.FIELD_TOTAL_TRIP_WEIGHT,
    GC.FIELD_TOTAL_TRIP_WEIGHT_UOM,
  ], R.or(rateInfo, {}));

  const totalDistance = R.pick([
    GC.FIELD_TOTAL_TRIP_DISTANCE,
    GC.FIELD_TOTAL_TRIP_DISTANCE_UOM,
  ], R.or(rateInfo, {}));

  return {
    totalVolume,
    totalWeight,
    totalDistance,
    totalPickupQuantity: getPackageTypeAndTotalPickupQuantity(R.prop(GC.FIELD_TOTAL_QUANTITIES, rateInfo)),
  };
};

const getInitialCustomerTotal = (rateInfo: Object, orderRateInfo: Object, defaultCurrency: string) => {
  // TODO: check if we have this total in normalizedCurrency
  const rateInfoTotal = G.getPropFromObject2(GC.FIELD_TOTAL_CUSTOMER_RATE, rateInfo, null);

  if (G.isNotNilAndNotEmpty(rateInfoTotal)) {
    const { normalizedCurrency } = rateInfo;

    const convertedTotal = G.convertCurrencyByExchangeRateToFloatOrNull(
      rateInfoTotal,
      normalizedCurrency,
      defaultCurrency,
    );

    return convertedTotal;
  }

  const orderRateTotal = G.getPropFromObject2(GC.FIELD_TOTAL_CUSTOMER_RATE, orderRateInfo, null);

  if (G.isNotNilAndNotEmpty(orderRateTotal)) {
    const { currency } = orderRateInfo;

    const convertedTotal = G.convertCurrencyByExchangeRateToFloatOrNull(
      orderRateTotal,
      currency,
      defaultCurrency,
    );

    return convertedTotal;
  }

  return null;
};

export const setDefaultDriverRateValues = (props: Object) => {
  const { rateInfo, asyncConfigs, initialValues, orderRateInfo, transportationMode } = props;

  if (G.isNotNilAndNotEmpty(initialValues)) {
    const assignment = R.path([GC.SYSTEM_OBJECT_FLEET_ASSIGNMENT], initialValues);

    if (G.isNotNilAndNotEmpty(assignment)) {
      const assignmentFields = R.pick(
        GC.GROUPED_FIELDS.FLEET_ASSIGNMENT_PICK_ARR,
        assignment,
      );
      const merged = R.mergeRight(initialValues, assignmentFields);
      const initValues = G.mapDropdownsObjectInEntity(
        GC.FIELD_DROPDOWN_OPTION_GUID,
        [GC.FIELD_SERVICE_TYPE, GC.FIELD_MODE, GC.FIELD_MARGIN_VIOLATION_REASON],
        merged,
      );

      return R.mergeRight(telRateInitFields, initValues);
    }

    const values = G.mapDropdownsObjectInEntity(
      GC.FIELD_DROPDOWN_OPTION_GUID,
      [GC.FIELD_SERVICE_TYPE, GC.FIELD_MODE, GC.FIELD_MARGIN_VIOLATION_REASON],
      initialValues,
    );

    return R.mergeRight(telRateInitFields, values);
  }

  const mode = R.or(G.getConfigValueFromStore(GC.TEL_DRIVER_RATE_DEFAULT_MODE, asyncConfigs), transportationMode);
  const defaultUomFields = G.getDefaultUomFields(asyncConfigs);
  const defaultCurrency = G.getConfigValueFromStore(GC.GENERAL_BRANCH_DEFAULT_CURRENCY, asyncConfigs);
  const serviceType = G.getConfigValueFromStore(GC.TEL_DRIVER_RATE_DEFAULT_SERVICE_TYPE, asyncConfigs);

  const customerTotal = getInitialCustomerTotal(rateInfo, orderRateInfo, defaultCurrency);

  return G.setDefaultFieldsToRateInvoiceFormik({
    ...getTotalValuesFromRateInfo(rateInfo),
    mode,
    serviceType,
    customerTotal,
    defaultCurrency,
    defaultUomFields,
    formikInitFields: R.mergeRight(
      telRateInitFields,
      { [GC.FIELD_START_POINT_LOCATION]: null, [GC.FIELD_FINISH_POINT_LOCATION]: null },
    ),
  });
};

export const calcDMTotalRate = (charges: Object) => {
  if (G.isNotNilAndNotEmpty(charges)) {
    return G.calculateChargesTotal(charges);
  }

  return 0;
};

export const filterDriversOptions = (props: Object) => {
  const primary = R.path(['values', GC.FIELD_PRIMARY_DRIVER_GUID], props);
  const secondary = R.path(['values', GC.FIELD_SECONDARY_DRIVER_GUID], props);
  const fleetOptions = R.clone(props.fleetOptionsForSelect);

  if (G.isNotNilAndNotEmpty(secondary)) {
    fleetOptions.primaryDriversOptions = R.filter(
      (option: Object) => G.notEquals(option.value, secondary),
      fleetOptions.primaryDriversOptions,
    );
  }

  if (G.isNotNilAndNotEmpty(primary)) {
    fleetOptions.secondaryDriversOptions = R.filter(
      (option: Object) => G.notEquals(option.value, primary),
      fleetOptions.secondaryDriversOptions,
    );
  }

  return fleetOptions;
};

export const disableDriverField = (field: Object, props: Object) => {
  const { telStatus } = props;

  if (R.propEq(GC.FIELD_PRIMARY_DRIVER_GUID, 'fieldName', field)) {
    return R.assoc('disabled', true, field);
  }

  if (R.propEq(GC.FIELD_TRAILER_GUIDS, 'fieldName', field)) {
    const manageConfig = G.getAmousBranchConfigFromWindow(GC.TRAILER_MANAGE_TRAILERS_FROM);

    if (R.equals(manageConfig, ENUMS.ENUM_EVENTS)) {
      const options = R.compose(
        R.map(({ guid, unitId}: Object) => ({ value: guid, label: unitId })),
        R.pathOr([], ['values', GC.FIELD_FLEET_ASSIGNMENT, 'trailers']),
      )(props);

      return {
        ...field,
        options,
        disabled: true,
      };
    }
  }

  if (G.isNotNilAndNotEmpty(telStatus)) {
    if (R.or(
      R.propEq(GC.FIELD_SECONDARY_DRIVER_GUID, 'fieldName', field),
      R.propEq(GC.FIELD_TRUCK_GUID, 'fieldName', field),
    )) {
      const sequence = GC.TEL_STATUS_SEQUENCE_MAP[telStatus];
      const disabled = R.gt(sequence, 7);

      return R.assoc('disabled', disabled, field);
    }
  }

  return field;
};

export const mapDriversToOptions = (data: Array, primary: boolean) => (
  R.map(
    (item: Object) => {
      const driverTotalValue = R.prop('normalizedTotalOrTotal', item);

      const getPrimaryDriverCurrency = R.pathOr(
        G.getPropFromObject(GC.FIELD_CURRENCY, item),
        [GC.SYSTEM_OBJECT_NORMALIZED_TOTAL, GC.FIELD_CURRENCY],
        item,
      );

      const vendorString = G.ifElse(
        G.isNotNilAndNotEmpty(R.path([GC.SYSTEM_OBJECT_FLEET_VENDOR, 'name'], item)),
        `, ${G.getWindowLocale('titles:vendor', 'Vendor')}: ${R.path([GC.SYSTEM_OBJECT_FLEET_VENDOR, 'name'], item)}`,
        '',
      );

      const location = R.or(
        R.path(['precedingLocation'], item),
        R.path(['currentLocation'], item),
      );

      const lnl = G.ifElse(
        G.isNotNilAndNotEmpty(location),
        `${G.getWindowLocale('titles:lnl', 'LNL')}: ${G.concatLocationFields(location)}`,
        '',
      );

      const distanceValue = R.pathOr('', [GC.FIELD_DISPLAY_DISTANCE_TO_START], item);

      const distanceString = G.ifElse(
        G.isNotNilAndNotEmpty(distanceValue),
        `${G.getWindowLocale('titles:distance-to-start', 'Distance To Start')}:
          ${distanceValue} ${R.prop(GC.FIELD_DISPLAY_DISTANCE_TO_START_UOM, item)}`,
        '',
      );

      const teamTotalValue = R.prop('teamTotal', item);

      const teamTotal = G.ifElse(
        G.isAllTrue(
          G.isNotNilAndNotEmpty(R.path(['assignment', GC.FIELD_SECONDARY_DRIVER_GUID], item)),
          G.isNotNilAndNotEmpty(teamTotalValue),
          G.isTrue(primary),
        ),
        `${G.getWindowLocale('titles:team-total', 'Team Total')}: $${teamTotalValue},`,
        '',
      );

      const driverTotal = G.ifElse(
        G.isNotNilAndNotEmpty(driverTotalValue),
        `${G.getWindowLocale('titles:driver-total', 'Driver Total')}: ${
          G.getCurrencySymbol(getPrimaryDriverCurrency)}${driverTotalValue}`,
        '',
      );

      return {
        value: R.prop(GC.FIELD_GUID, item),
        label: `${R.prop(GC.FIELD_FIRST_NAME, item)} ${R.prop(GC.FIELD_LAST_NAME, item)}${vendorString}`,
        lnl,
        distance: distanceString,
        total: `${teamTotal}${driverTotal}`,
      };
    },
    data,
  )
);

export const mapAvailableDriversToOptions = (data: Array, primary: boolean) => (
  R.map(
    (item: Object) => {
      const driverTotalValue = R.prop('total', item);
      const teamTotalValue = R.prop('teamTotal', item);

      const vendorString = G.ifElse(
        G.isNotNilAndNotEmpty(R.path(['fleetVendor', 'name'], item)),
        `, ${G.getWindowLocale('titles:vendor', 'Vendor')}: ${R.path(['fleetVendor', 'name'], item)}`,
        '',
      );

      const location = R.or(
        R.path(['precedingLocation'], item),
        R.path(['currentLocation'], item),
      );

      const lnl = G.ifElse(
        G.isNotNilAndNotEmpty(location),
        `${G.getWindowLocale('titles:lnl', 'LNL')}: ${G.concatLocationFields(location)}`,
        '',
      );

      const teamTotal = G.ifElse(
        G.isAllTrue(
          G.isNotNilAndNotEmpty(R.path(['assignment', GC.FIELD_SECONDARY_DRIVER_GUID], item)),
          G.isNotNilAndNotEmpty(teamTotalValue),
          G.isTrue(primary),
        ),
        `${G.getWindowLocale('titles:team-total', 'Team Total')}: $${teamTotalValue},`,
        '',
      );

      const driverTotal = G.ifElse(
        G.isNotNilAndNotEmpty(driverTotalValue),
        `${G.getWindowLocale('titles:driver-total', 'Driver Total')}: $${driverTotalValue}`,
        '',
      );

      return {
        value: R.prop(GC.FIELD_GUID, item),
        label: `${R.prop(GC.FIELD_FIRST_NAME, item)} ${R.prop(GC.FIELD_LAST_NAME, item)}${vendorString}`,
        lnl,
        total: `${teamTotal}${driverTotal}`,
      };
    },
    data,
  )
);

const getEquipmentLabel = (item: Object) => {
  const unitId = R.path(['unitId'], item);
  const vendorName = R.path(['fleetVendor', 'name'], item);

  if (G.isNotNilAndNotEmpty(vendorName)) {
    return `${unitId} (${G.getWindowLocale('titles:vendor', 'Vendor')}: ${vendorName})`;
  }

  return unitId;
};

export const mapEquipmentsToOptions = (data: Array) => (
  R.map(
    (item: Object) => ({
      label: getEquipmentLabel(item),
      value: R.prop(GC.FIELD_GUID, item),
      vendorGuid: R.path(['fleetVendor', GC.FIELD_GUID], item),
    }),
    data,
  )
);

export const mapRateDataWithProperQuantity = (rateData: Object) => {
  const mapCharges = R.map(
    R.when(
      R.propEq(null, GC.FIELD_CHARGE_QUANTITY),
      R.assoc(GC.FIELD_CHARGE_QUANTITY, 1),
    ),
  );

  return R.compose(
    R.indexBy(R.prop(GC.FIELD_GUID)),
    R.map((rate: Object) => R.assoc(GC.FIELD_CHARGES, mapCharges(R.prop(GC.FIELD_CHARGES, rate)), rate)),
  )(rateData);
};

export const mapCarrierRatesWithProperQuantity = (carrierRates: Object) => {
  const mapCharges = R.map(
    R.when(
      R.propEq(null, GC.FIELD_CHARGE_QUANTITY),
      R.assoc(GC.FIELD_CHARGE_QUANTITY, 1),
    ),
  );

  return R.map((rate: Object) => R.assoc(
    GC.FIELD_CHARGES, mapCharges(R.prop(GC.FIELD_CHARGES, rate)),
    rate,
  ), carrierRates);
};

const getTotalsFromValuesForCarrierRate = (rateInfo: any, mockRateInfo: any) => {
  if (G.isNotNilAndNotEmpty(mockRateInfo)) {
    const totalTripVolume = R.or(
      R.path(['totalWeight', GC.FIELD_TOTAL_TRIP_VOLUME], mockRateInfo),
      G.getPropFromObject(GC.FIELD_TOTAL_TRIP_VOLUME, rateInfo),
    );
    const totalTripVolumeUom = R.or(
      R.path(['totalWeight', GC.FIELD_TOTAL_TRIP_VOLUME_UOM], mockRateInfo),
      G.getPropFromObject(GC.FIELD_TOTAL_TRIP_VOLUME_UOM, rateInfo),
    );
    const totalTripWeight = R.or(
      R.path(['totalWeight', GC.FIELD_TOTAL_TRIP_WEIGHT], mockRateInfo),
      G.getPropFromObject(GC.FIELD_TOTAL_TRIP_WEIGHT, rateInfo),
    );
    const totalTripWeightUom = R.or(
      R.path(['totalWeight', GC.FIELD_TOTAL_TRIP_WEIGHT_UOM], mockRateInfo),
      G.getPropFromObject(GC.FIELD_TOTAL_TRIP_WEIGHT_UOM, rateInfo),
    );
    const totalTripDistance = R.or(
      R.path(['totalWeight', GC.FIELD_TOTAL_TRIP_DISTANCE], mockRateInfo),
      G.getPropFromObject(GC.FIELD_TOTAL_TRIP_DISTANCE, rateInfo),
    );
    const totalTripDistanceUom = R.or(
      R.path(['totalWeight', GC.FIELD_TOTAL_TRIP_DISTANCE_UOM], mockRateInfo),
      G.getPropFromObject(GC.FIELD_TOTAL_TRIP_DISTANCE_UOM, rateInfo),
    );

    return {
      totalVolume: { totalTripVolume, totalTripVolumeUom },
      totalWeight: { totalTripWeight, totalTripWeightUom },
      totalDistance: { totalTripDistance, totalTripDistanceUom },
      totalPickupQuantity: R.propOr(
        getPackageTypeAndTotalPickupQuantity(R.prop(GC.FIELD_TOTAL_QUANTITIES, rateInfo)),
        'totalPickupQuantity',
        mockRateInfo,
      ),
    };
  }

  return getTotalValuesFromRateInfo(rateInfo);
};

// TODO: this helper has bug when rate info come up later then user choose carrier
// quick fixed with showing loader on available drivers
export const setDefaultCarrierRateValues = (props: Object) => {
  const { rateInfo, mockRateInfo, asyncConfigs, initialValues, orderRateInfo } = props;

  if (G.isNotNilAndNotEmpty(initialValues)) {
    const initValues = G.mapDropdownsObjectInEntity(
      GC.FIELD_DROPDOWN_OPTION_GUID,
      [GC.FIELD_SERVICE_TYPE, GC.FIELD_MODE, GC.FIELD_MARGIN_VIOLATION_REASON],
      initialValues,
    );

    return R.mergeRight(carrierRateInitFields, initValues);
  }

  const mode = G.getConfigValueFromStore(GC.TEL_CARRIER_RATE_DEFAULT_MODE, asyncConfigs);

  const defaultCurrency = G.getConfigValueFromStore(GC.GENERAL_BRANCH_DEFAULT_CURRENCY, asyncConfigs);

  const customerTotal = getInitialCustomerTotal(rateInfo, orderRateInfo, defaultCurrency);

  return G.setDefaultFieldsToRateInvoiceFormik({
    ...getTotalsFromValuesForCarrierRate(rateInfo, mockRateInfo),
    mode,
    customerTotal,
    defaultCurrency,
    formikInitFields: carrierRateInitFields,
    defaultUomFields: G.getDefaultUomFields(asyncConfigs),
    serviceType: G.getConfigValueFromStore(GC.TEL_CARRIER_RATE_DEFAULT_SERVICE_TYPE, asyncConfigs),
  });
};

export const getChargesFromValues = (props: Object) => {
  const { values, isCarrier, isInvoice, isEditMode, activeDriver, isServiceVendor } = props;

  if (R.and(isInvoice, isEditMode)) {
    return R.pathOr([], [GC.FIELD_CHARGES], values);
  }

  if (G.isTrue(isCarrier)) {
    return R.pathOr([], [GC.FIELD_CARRIER_RATE_CHARGES], values);
  }

  if (G.isTrue(isServiceVendor)) {
    return R.pathOr([], [GC.FIELD_CHARGES], values);
  }

  if (isActiveTabPrimaryDriver(activeDriver)) {
    return R.pathOr([], [GC.FIELD_PRIMARY_DRIVER_CHARGES], values);
  }

  if (isActiveTabTeamDriver(activeDriver)) {
    return R.pathOr([], [GC.FIELD_SECONDARY_DRIVER_CHARGES], values);
  }

  if (isActiveTabVendor(activeDriver)) {
    return R.pathOr([], [GC.FIELD_FLEET_VENDOR_CHARGES], values);
  }

  return [];
};

export const getChargesForRateTotalFromValues = (values: Object) => {
  const fleetVendorCharges = R.path([GC.FIELD_FLEET_VENDOR_CHARGES], values);

  if (G.isNotNilAndNotEmpty(fleetVendorCharges)) {
    return fleetVendorCharges;
  }

  const chargesNames = [
    GC.FIELD_CHARGES,
    GC.FIELD_CARRIER_RATE_CHARGES,
    GC.FIELD_PRIMARY_DRIVER_CHARGES,
    GC.FIELD_SECONDARY_DRIVER_CHARGES,
  ];

  return R.reduce((acc: Array, name: string) => (
    R.concat(acc, R.pathOr([], [name], values))
  ), [], chargesNames);
};

export const getChargesArrayNameFromProps = (props: Object) => {
  const { isCarrier, isInvoice, isEditMode, activeDriver } = props;

  if (R.and(isInvoice, isEditMode)) {
    return GC.FIELD_CHARGES;
  }

  if (G.isTrue(isCarrier)) {
    return GC.FIELD_CARRIER_RATE_CHARGES;
  }

  if (isActiveTabPrimaryDriver(activeDriver)) {
    return GC.FIELD_PRIMARY_DRIVER_CHARGES;
  }

  if (isActiveTabTeamDriver(activeDriver)) {
    return GC.FIELD_SECONDARY_DRIVER_CHARGES;
  }

  if (isActiveTabVendor(activeDriver)) {
    return GC.FIELD_FLEET_VENDOR_CHARGES;
  }

  return GC.FIELD_CHARGES;
};

export const getMarginViolationData = (props: Object) => {
  const {
    values,
    rateInfo,
    marginViolationRules,
  } = props;

  if (G.isNilOrEmpty(rateInfo)) {
    return { maxPayViolated: false, marginViolated: false, criticalMarginViolated: false };
  }

  const { currency, transportationMode } = values;

  const {
    routeTotalSpend,
    routeTotalIncome,
    normalizedCurrency,
    normalizedTripMaxPay,
  } = rateInfo;

  const charges = getChargesForRateTotalFromValues(values);

  const chargesTotal = G.convertCurrencyByExchangeRate(
    G.getCalcTelRateChargesTotal(charges, currency),
    currency,
    normalizedCurrency,
  );

  if (G.isNumber(normalizedTripMaxPay)) {
    return {
      marginViolated: false,
      criticalMarginViolated: false,
      maxPayViolated: R.lt(normalizedTripMaxPay, chargesTotal),
    };
  }

  const routeTransportationModes = R.pathOr([], ['routeTransportationModes'], rateInfo);
  const useTransportationModeRuleFromValues = R.or(
    R.isEmpty(routeTransportationModes),
    R.includes(transportationMode, routeTransportationModes),
  );
  const rule = R.pathOr(null, [transportationMode], marginViolationRules);

  const conditions = [
    R.isNil(rule),
    G.isNilOrEmpty(transportationMode),
    G.isNilOrEmpty(marginViolationRules),
    R.not(useTransportationModeRuleFromValues),
    R.and(G.isNotEmpty(routeTransportationModes), G.notContain(transportationMode, routeTransportationModes)),
  ];

  if (G.isAnyTrue(...conditions)) {
    return { maxPayViolated: false, marginViolated: false, criticalMarginViolated: false };
  }

  const { minMargin, criticalMargin } = rule;

  const totalSpend = R.add(R.or(routeTotalSpend, 0), R.or(chargesTotal, 0));

  let marginViolated = false;
  let criticalMarginViolated = false;
  const routeMargin = R.subtract(routeTotalIncome, totalSpend);

  if (R.lt(routeMargin, 0)) {
    marginViolated = G.ifElse(G.isNotNil(minMargin), true, false);
    criticalMarginViolated = G.ifElse(G.isNotNil(criticalMargin), true, false);
  } else {
    const divided = R.divide(routeMargin, routeTotalIncome);
    marginViolated = R.compose(
      R.gt(minMargin),
      R.multiply(100),
    )(divided);
    criticalMarginViolated = R.compose(
      R.gt(criticalMargin),
      R.multiply(100),
    )(divided);
  }

  return { marginViolated, criticalMarginViolated, maxPayViolated: false };
};

export const getTotalDistanceFromValues = (values: Object) => {
  const { deadHeadDistance, deadHeadDistanceUom, totalTripDistance, totalTripDistanceUom } = values;

  const defaultUomSystem = G.getConfigGeneralUomCalcDefaultUomSystemFromWindow();
  const defaultUom = R.pathOr(GC.UOM_MILE, [defaultUomSystem], GC.uomSystemToDistanceUomMap);

  const total = G.milesKmsFromToAccordingSystemWithNullable(
    defaultUom,
    totalTripDistanceUom,
    totalTripDistance,
  );

  const deadhead = G.milesKmsFromToAccordingSystemWithNullable(
    defaultUom,
    deadHeadDistanceUom,
    deadHeadDistance,
  );

  return G.toFixed(R.add(total, deadhead));
};
