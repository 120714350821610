import * as Yup from 'yup';
import * as R from 'ramda';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// feature new-import
import * as C from '../constants';
//////////////////////////////////////////////////

export const booleanOptions = [
  {
    [GC.FIELD_VALUE]: true,
    [GC.FIELD_LABEL]: 'Yes',
  },
  {
    [GC.FIELD_LABEL]: 'No',
    [GC.FIELD_VALUE]: false,
  },
];

export const matchFields = (field: Object) => (
  {
    label: field,
    type: 'select',
    width: '200px',
    afterTop: '14px',
    fieldName: field,
    flexWrap: 'no-wrap',
    alignItems: 'center',
    inputWrapMargin: '0',
    options: 'matchedOptions',
    justifyContent: 'space-between',
    shouldCustomChange: G.ifElse(R.includes('.', field), true, false),
  }
);

export const importTypes = [
  {
    value: C.IMPORT_TYPE_DRIVER,
    label: G.getWindowLocale('titles:driver', 'Driver'),
  },
  {
    value: C.IMPORT_TYPE_TRUCK,
    label: G.getWindowLocale('titles:truck', 'Truck'),
  },
  {
    value: C.IMPORT_TYPE_TRAILER,
    label: G.getWindowLocale('titles:trailer', 'Trailer'),
  },
  {
    value: C.IMPORT_TYPE_TOLL_CHARGE,
    label: G.getWindowLocale('titles:toll-charge', 'Toll Charge'),
  },
  {
    value: C.IMPORT_TYPE_FUEL_CARD_TRANSACTION,
    label: G.getWindowLocale('titles:fuel-transactions', 'Fuel Transactions'),
  },
  {
    value: C.IMPORT_TYPE_LOCATION,
    label: G.getWindowLocale('titles:location', 'Location'),
  },
  {
    value: C.IMPORT_TYPE_CONTACT_BOOK,
    label: G.getWindowLocale('titles:contact', 'Contact'),
  },
  {
    value: C.IMPORT_TYPE_BRANCH,
    label: G.getWindowLocale('titles:branch', 'Branch'),
  },
  {
    value: C.IMPORT_TYPE_CARRIER,
    label: G.getWindowLocale('titles:carrier', 'Carrier'),
  },
  {
    value: C.IMPORT_TYPE_CARRIER_INVOICE,
    label: G.getWindowLocale('titles:carrier-invoice', 'Carrier Invoice'),
  },
  {
    value: C.IMPORT_TYPE_CARRIER_INSURANCE,
    label: G.getWindowLocale('titles:carrier-insurance', 'Carrier Insurance'),
  },
  {
    value: C.IMPORT_TYPE_CARRIER_PAY_TO_LOCATION,
    label: G.getWindowLocale('titles:carrier-pay-to-location', 'Carrier Pay To Location'),
  },
  {
    value: C.IMPORT_TYPE_GEO_FENCING_ZONE,
    label: G.getWindowLocale('titles:geo-fencing-zone', 'Geo Fencing Zone'),
  },
  {
    value: C.IMPORT_TYPE_CLO,
    label: G.getWindowLocale('titles:clo', 'CLO'),
  },
];

export const importStatusesTypes = [
  {
    value: GC.STATUS_FAILED,
    label: G.getWindowLocale('titles:failed', 'Failed'),
  },
  {
    value: GC.STATUS_PENDING,
    label: G.getWindowLocale('enum:pending', 'Pending'),
  },
  {
    value: GC.STATUS_SUCCESS,
    label: G.getWindowLocale('titles:success', 'Success'),
  },
  {
    value: GC.STATUS_IN_PROGRESS,
    label: G.getWindowLocale('titles:in-progress', 'In Progress'),
  },
  {
    value: GC.STATUS_PARTIAL_SUCCESS,
    label: G.getWindowLocale('titles:partial-success', 'Partial Success'),
  },
];

const importTypeCarrierRatePrice = {
  value: C.IMPORT_TYPE_CARRIER_RATE_PRICE,
  label: G.getWindowLocale('titles:carrier-rate-price', 'Carrier Rate Price'),
};

export const importTypesWithCarrierRatePrice = R.append(importTypeCarrierRatePrice, importTypes);

export const tollChargeSourceOptions = [
  {
    label: 'I-Pass',
    value: GC.FLEET_INTEGRATION_TYPE_I_PASS,
  },
  {
    label: 'Bestpass',
    value: GC.FLEET_INTEGRATION_TYPE_BESTPASS,
  },
  {
    label: 'PrePass',
    value: GC.FLEET_INTEGRATION_TYPE_PRE_PASS,
  },
  {
    label: 'Self Toll',
    value: GC.FLEET_INTEGRATION_TYPE_SELF_TOLL,
  },
];

export const fuelCardTransactionSourceOptions = [
  {
    value: GC.FUEL_CARDS_TYPE_EFS,
    label: G.getWindowLocale('titles:efs', 'EFS'),
  },
  {
    value: GC.FUEL_CARDS_TYPE_QUIKQ,
    label: G.getWindowLocale('titles:quikq', 'QUIKQ'),
  },
  {
    value: GC.FUEL_CARDS_TYPE_SELF_FUELED,
    label: G.getWindowLocale('titles:self-fueled', 'Self Fueled'),
  },
  {
    label: 'BVD Group',
    value: GC.FUEL_CARDS_TYPE_BVD,
  },
  {
    label: 'Relay',
    value: GC.FUEL_CARDS_TYPE_RELAY,
  },
  {
    label: 'Comdata',
    value: GC.FUEL_CARDS_TYPE_COMDATA,
  },
];

const defaultFieldStyles = {
  width: 300,
  errorTop: '110%',
  afterTop: '14px',
  errorTitle: true,
  errorLeft: '10px',
  labelWidth: '100%',
  errorWidth: '100%',
  errorOverflow: 'hidden',
  flexDirection: 'column',
  errorWhiteSpace: 'nowrap',
  errorPosition: 'absolute',
  errorTextOverflow: 'ellipsis',
};

export const addRowsField = {
  ...defaultFieldStyles,
  width: 200,
  type: 'number',
  fieldName: 'count',
  inputsWidth: '100%',
  loc: 'titles:number-of-rows',
};

export const importFileField = {
  ...defaultFieldStyles,
  type: 'file',
  loc: 'titles:upload-file',
  fieldName: GC.FIELD_DOCUMENT_UPLOAD,
};

export const importTypeField = {
  ...defaultFieldStyles,
  width: '100%',
  type: 'select',
  inputsWidth: '93%',
  options: importTypes,
  fieldName: GC.FIELD_TYPE,
  loc: 'titles:import-type',
  fieldGroupDisplay: 'flex',
};

export const importTypeSchema = {
  [GC.FIELD_TYPE]: Yup.string().required(G.getRequiredLocaleTxt()),
};

export const defaultImportFromFileFields = {
  [GC.FIELD_SOURCE]: '',
  [GC.FIELD_ROLE_ROLES_GUIDS]: [],
  [GC.FIELD_DOCUMENT_UPLOAD]: null,
  [GC.FIELD_TYPE]: C.IMPORT_TYPE_DRIVER,
};

export const importFromFileFieldSettings = [
  {
    ...importTypeField,
    width: 300,
    fieldGroupDisplay: (_: any, { type }: Object) => G.ifElse(
      R.equals(type, C.IMPORT_TYPE_CARRIER_RATE_PRICE),
      'none',
      'flex',
    ),
    shouldCustomChange: true,
    customChangeHandler: (event: Object, _: any, { values, setValues }: Object) => {
      setValues(R.mergeRight(
        values,
        {
          [GC.FIELD_SOURCE]: null,
          [GC.FIELD_TYPE]: G.getEventTargetValue(event),
        },
      ));
    },
  },
  {
    ...defaultFieldStyles,
    type: 'searchselect',
    loc: 'titles:source',
    options: 'sourceOptions',
    fieldName: GC.FIELD_SOURCE,
    fieldGroupDisplay: (_: Object, { type }: Object) => G.ifElse(
      R.includes(type, [C.IMPORT_TYPE_TOLL_CHARGE, C.IMPORT_TYPE_FUEL_CARD_TRANSACTION]),
      'flex',
      'none',
    ),
  },
  {
    ...defaultFieldStyles,
    loc: 'titles:roles',
    type: 'multiselect',
    options: 'driverRoleOptions',
    fieldName: GC.FIELD_ROLE_ROLES_GUIDS,
    fieldGroupDisplay: (_: Object, values: Object) => G.ifElse(
      R.propEq(C.IMPORT_TYPE_DRIVER, GC.FIELD_TYPE, values),
      'flex',
      'none',
    ),
  },
  importFileField,
];

export const importFromFileValidationSchema = Yup.object().shape({
  [GC.FIELD_DOCUMENT_UPLOAD]: Yup.string().required(G.getRequiredLocaleTxt()),
});

export const sequenceTemplateIdField = {
  ...defaultFieldStyles,
  type: 'text',
  width: '200px',
  inputsWidth: '100%',
  loc: 'titles:template-id',
  fieldName: GC.FIELD_TEMPLATE_ID,
};

export const additionalField = (label: string) => ({
  ...defaultFieldStyles,
  loc: label,
  type: 'text',
  width: '200px',
  inputsWidth: '100%',
  fieldName: GC.FIELD_VALUE,
});

export const importCLOFromFileFields = [
  importFileField,
  {
    ...defaultFieldStyles,
    type: 'select',
    fieldName: 'mapper',
    loc: 'titles:mapper',
    options: 'mapperOptions',
  },
];
