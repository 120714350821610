import * as Yup from 'yup';
import * as R from 'ramda';
import { withFormik } from 'formik';
import React, { Fragment } from 'react';
import { pure, compose, lifecycle, withState, withProps, withHandlers } from 'react-recompose';
// components
import { InfoPair } from '../../../components/info-pair';
import { TextBtn1 } from '../../../components/text-btns';
import { ChargeFormFooter } from '../../../components/form-footer';
import PriceSheetItems from '../../../components/price-sheet-items';
import { ChargesTotal } from '../../../components/charge/components';
import { withRecalculateAllChargesId } from '../../../components/charge/hocs';
import { DMChargeComponent } from '../../../components/charge/formik/charges-array';
import {
  withAsyncChargeAccessorials,
  getTelSharedAccessorialsApplyTo,
} from '../../../components/charge/hocs/with-async-charge-accessorials';
// forms
import { Fieldset2 } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import {
  withAsyncConfigs,
  withAsyncMarginViolationRules,
  withConnectModalAndLoaderActions,
} from '../../../hocs';
// ui
import {
  Box,
  Flex,
  Span,
  StickedBox,
  ActionButton,
  CancelButton,
} from '../../../ui';
// schemes
import * as VS from '../../../schemes';
// feature rate
import ShowScorecards from './scorecards';
import ShowLaneHistory from './lane-history';
import CarrierSection from './carrier-section';
import AvailableRates from './available-rates';
import DispatcherSection from './dispatcher-section';
import { withAsyncContacts, withAsyncCarrierRates } from '../hocs';
import TrackingReferenceSection from './trucking-references-section';
import { mainCarrierRateFields, totalCarrierRateFields } from './settings';
import MarginViolationSection from '../components/margin-violation-section';
import { NotAllowedCustomerRate } from '../components/not-allowed-customer-rate';
import { getMarginViolationData, getChargesFromValues, setDefaultCarrierRateValues } from '../helpers';
//////////////////////////////////////////////////

const defaultBtnProps = {
  height: 32,
  width: 120,
  p: '4px 8px',
  fontSize: 14,
  type: 'button',
};

const blackColor = G.getTheme('colors.black');

const renderIntegrationCarriers = ({ originCarrierName, destinationCarrierName, transportingCarrierName }: Object) => {
  if (G.isOneNotNilOrNotEmpty([originCarrierName, destinationCarrierName])) {
    return (
      <Fragment>
        {G.getWindowLocale(
          'message:rate-has-origin-destination-carriers',
          'Warning, this rate has the following carriers',
        )}:
        <Flex mt='4px' maxWidth='90vw' flexWrap='wrap'>
          <InfoPair
            width='unset'
            fontSize={16}
            marginRight={10}
            lineHeight='initial'
            textColor={blackColor}
            text={originCarrierName}
            textMaxWidth='calc(90vw - 200px)'
            label={G.getWindowLocale('titles:origin-carrier-name', 'Origin Carrier Name')}
          />
          <InfoPair
            pt='8px'
            width='unset'
            fontSize={16}
            lineHeight='initial'
            textColor={blackColor}
            text={destinationCarrierName}
            textMaxWidth='calc(90vw - 200px)'
            label={G.getWindowLocale('titles:destination-carrier-name', 'Destination Carrier Name')}
          />
        </Flex>
        {G.getWindowLocale('messages:do-you-want-to-continue', 'Do you want to continue')}?
      </Fragment>
    );
  }

  return (
    <Fragment>
      {G.getWindowLocale(
        'message:rate-has-transporting-carrier',
        'Warning, this rate has the following Transporting Carrier',
      )}:
      <Span ml='5px' fontWeight='bold'>{transportingCarrierName}</Span>
      <br />{G.getWindowLocale('messages:do-you-want-to-continue', 'Do you want to continue')}?
    </Fragment>
  );
};

const SelectRateFromIntegration = ({
  closeModal,
  handleSelect,
  originCarrierName,
  destinationCarrierName,
  transportingCarrierName,
}: Object) => (
  <Box fontSize={16} lineHeight={1.8} textAlign='center'>
    {renderIntegrationCarriers({
      originCarrierName,
      destinationCarrierName,
      transportingCarrierName,
    })}
    <Flex mt={15} justifyContent='space-around'>
      <CancelButton {...defaultBtnProps} onClick={closeModal}>
        {G.getWindowLocale('titles:no', 'No')}
      </CancelButton>
      <ActionButton {...defaultBtnProps} onClick={handleSelect}>
        {G.getWindowLocale('titles:yes', 'Yes')}
      </ActionButton>
    </Flex>
  </Box>
);

const validationSchemaObject = Yup.object()
  .shape(VS.validationSchemaCarrierObject)
  .shape(VS.validationSchemaTripTotalObject)
  .shape(VS.validationSchemaTripServiceObject)
  .shape(VS.validationSchemaDispatcherInfoObject)
  .shape(VS.validationSchemaMarginViolationObject);

const validationUpdateSchemaObject = Yup.object()
  .shape(VS.validationSchemaCarrierObject)
  .shape(VS.validationSchemaTripTotalObject)
  .shape(VS.validationSchemaTripServiceObject)
  .shape(VS.validationSchemaDispatcherInfoObject)
  .shape(VS.validationSchemaMarginViolationObject);

const enhanceCommon = compose(
  withState('notAllowedCustomerRate', 'setNotAllowedCustomerRate', ({ notAllowCustomerRateInit }: Object) => (
    R.or(notAllowCustomerRateInit, false)
  )),
  lifecycle({
    componentDidMount() {
      const { getTelRateCarrierRatingInfoRequest } = this.props;

      getTelRateCarrierRatingInfoRequest(this.props);
    },
  }),
  withAsyncMarginViolationRules,
);

const mapCharges = (charges: Array) => R.map(
  (item: Object) => R.assoc(GC.FIELD_ID, R.or(G.getGuidFromObject(item), G.genShortId()), item),
  G.addOneDiscountChargeToCharges(charges),
);

const getCarrierRateFields = (data: Object) => {
  const {
    quoteNumber,
    carrierSnapshot,
    originCarrierName,
    configAccountNumber,
    transportingCarrier,
    destinationCarrierName,
  } = data;

  const transportingCarrierForCarrierAssignment = R.compose(
    R.pick(['quoteId', 'quoteShipmentId', 'transportingCarrierScac', GC.FIELD_TRANSPORTING_CARRIER_NAME]),
    G.renameKeys({
      [GC.FIELD_SCAC]: 'transportingCarrierScac',
      [GC.FIELD_NAME]: GC.FIELD_TRANSPORTING_CARRIER_NAME,
    }),
  )(transportingCarrier);

  return {
    ...carrierSnapshot,
    ...transportingCarrierForCarrierAssignment,
    quoteNumber,
    originCarrierName,
    destinationCarrierName,
    [GC.FIELD_INTEGRATION_ACCOUNT_NUMBER]: configAccountNumber,
    [GC.FIELD_CARRIER_GUID]: G.getGuidFromObject(carrierSnapshot),
  };
};

const enhanceCreate = compose(
  withRecalculateAllChargesId,
  withAsyncCarrierRates,
  withAsyncContacts,
  withState('isValidEmails', 'setIsValidEmails', true),
  withState('isOpenedDispatcherInfoSection', 'setIsOpenedDispatcherInfoSection', false),
  enhanceCommon,
  withFormik({
    enableReinitialize: true,
    validationSchema: validationSchemaObject,
    mapPropsToValues: (props: Object) => setDefaultCarrierRateValues(props),
    handleSubmit: (values: Object, { props }: Object) => {
      const { shouldSelect, handleSendTelRate } = props;

      let data = G.ifElse(
        G.isBoolean(shouldSelect),
        R.assoc('selected', shouldSelect, values),
        values,
      );

      const { marginViolated, maxPayViolated } = getMarginViolationData({ ...props, values, isCarrier: true });

      if (R.and(R.not(marginViolated), R.not(maxPayViolated))) {
        data = R.mergeRight(data, {
          [GC.FIELD_MARGIN_VIOLATION_NOTE]: null,
          [GC.FIELD_MARGIN_VIOLATION_REASON]: null,
        });
      }

      handleSendTelRate(data);
    },
    displayName: 'ADD_CARRIER_RATE_FORM',
  }),
  withState(
    'selectedCarrier',
    'setSelectedCarrier',
    ({ values }: Object) => R.prop(GC.SYSTEM_OBJECT_CARRIER_ASSIGNMENT, values),
  ),
  withHandlers({
    // NOTE: remember common logic with withHandleCloRateAvailableRates -> handleSelectRate -> setCarrierRateToStore
    handleSelectAutoRate: (props: Object) => (data: Object) => {
      const { values, setValues, openModal, closeModal, setSelectedCarrier, handleSetRecalculateAllChargesId } = props;

      const {
        charges,
        originCarrierName,
        destinationCarrierName,
      } = data;

      const carrierRateFields = getCarrierRateFields(data);

      const carrierAssignment = {
        ...R.prop(GC.SYSTEM_OBJECT_CARRIER_ASSIGNMENT, props),
        ...carrierRateFields,
      };

      const mode = R.or(
        R.path(['price', GC.FIELD_MODE, GC.FIELD_DROPDOWN_OPTION_GUID], data),
        R.path([GC.FIELD_MODE], values),
      );

      const serviceType = R.pathOr(
        '',
        [GC.FIELD_DROPDOWN_OPTION_GUID],
        R.head(R.pathOr([], [GC.FIELD_PRICE, GC.FIELD_CARRIER_SERVICE_TYPES], data)),
      );

      const carrierContractGuid = R.path(['price', 'contractGuid'], data);

      const cloRateAdjustments = R.path(['rateInfo', GC.FIELD_CLO_RATE_ADJUSTMENTS], props);

      const staticFields = R.pick(
        [
          GC.FIELD_CURRENCY,
          GC.FIELD_SERVICE_DAYS,
          GC.FIELD_REQUESTED_PICKUP_EARLY_DATE,
          GC.FIELD_ESTIMATED_DELIVERY_LATE_DATE,
          GC.FIELD_ESTIMATED_DELIVERY_EARLY_DATE,
        ],
        data,
      );

      const newValues = {
        ...values,
        ...staticFields,
        carrierAssignment,
        carrierContractGuid,
        [GC.FIELD_MODE]: mode,
        [GC.FIELD_SERVICE_TYPE]: serviceType,
        [GC.FIELD_CLO_RATE_ADJUSTMENTS]: cloRateAdjustments,
        [GC.FIELD_CARRIER_RATE_CHARGES]: mapCharges(charges),
      };

      const transportingCarrierName = R.path([GC.FIELD_TRANSPORTING_CARRIER, GC.FIELD_NAME], data);

      if (G.isOneNotNilOrNotEmpty([originCarrierName, destinationCarrierName, transportingCarrierName])) {
        const handleSelect = () => {
          setValues(newValues);
          setSelectedCarrier(carrierAssignment);

          G.callFunction(handleSetRecalculateAllChargesId);
          G.callFunctionTwice(closeModal);
        };

        const component = (
          <SelectRateFromIntegration
            closeModal={closeModal}
            handleSelect={handleSelect}
            originCarrierName={originCarrierName}
            destinationCarrierName={destinationCarrierName}
            transportingCarrierName={transportingCarrierName}
          />
        );

        const modal = {
          p: 15,
          component,
          options: {},
        };

        return openModal(modal);
      }

      setValues(newValues);
      setSelectedCarrier(carrierAssignment);

      G.callFunction(handleSetRecalculateAllChargesId);

      closeModal();
    },
  }),
  withHandlers({
    handleOpenAutoRateModal: (props: Object) => () => {
      const {
        tel,
        values,
        telGuid,
        loadData,
        openModal,
        branchGuid,
        closeModal,
        handleSelectAutoRate,
        customerEnterpriseGuid,
      } = props;

      const transportationMode = R.path([GC.FIELD_MODE], values);
      const serviceType = R.path([GC.FIELD_SERVICE_TYPE], values);
      const equipments = R.map(
        R.prop(GC.FIELD_DROPDOWN_OPTION_GUID),
        R.pathOr([], [GC.FIELD_TEL, GC.FIELD_CARRIER_EQUIPMENTS], props),
      );

      const modalContent = (
        <AvailableRates
          tel={tel}
          telGuid={telGuid}
          loadData={loadData}
          openModal={openModal}
          branchGuid={branchGuid}
          closeModal={closeModal}
          equipments={equipments}
          serviceType={serviceType}
          onSelectRate={handleSelectAutoRate}
          transportationMode={transportationMode}
          customerEnterpriseGuid={customerEnterpriseGuid}
        />
      );

      const modal = {
        p: '0',
        component: modalContent,
        options: {
          width: 'auto',
          height: 'auto',
          overflow: 'auto',
          maxWidth: '90vw',
          maxHeight: '80vh',
          minHeight: '50vh',
        },
      };

      openModal(modal);
    },
  }),
  withProps({
    isCarrier: true,
    isEditMode: false,
    isCreateMode: true,
    title: G.getWindowLocale('titles:add-carrier-rate', 'Add Carrier Rate'),
  }),
  pure,
);

const enhanceUpdate = compose(
  withRecalculateAllChargesId,
  withAsyncCarrierRates,
  withAsyncContacts,
  withState('isValidEmails', 'setIsValidEmails', true),
  enhanceCommon,
  withFormik({
    enableReinitialize: true,
    validationSchema: validationUpdateSchemaObject,
    mapPropsToValues: (props: Object) => setDefaultCarrierRateValues(props),
    handleSubmit: (values: Object, { props }: Object) => {
      const { handleSendTelRate } = props;

      const { marginViolated, maxPayViolated } = getMarginViolationData({ ...props, values, isCarrier: true });

      const fieldsToOmit = [
        GC.FIELD_MAX_PAY_VIOLATED,
        GC.FIELD_MIN_MARGIN_VIOLATED,
        GC.FIELD_CRITICAL_MARGIN_VIOLATED,
      ];

      const valuesToUse = R.omit(fieldsToOmit, values);

      const data = G.ifElse(
        R.or(marginViolated, maxPayViolated),
        valuesToUse,
        R.mergeRight(valuesToUse, {
          [GC.FIELD_MARGIN_VIOLATION_NOTE]: null,
          [GC.FIELD_MARGIN_VIOLATION_REASON]: null,
        }),
      );

      const violationFields = [GC.FIELD_MIN_MARGIN_VIOLATED, GC.FIELD_CRITICAL_MARGIN_VIOLATED];

      handleSendTelRate(R.omit(violationFields, data));
    },
    displayName: 'EDIT_CARRIER_RATE_FORM',
  }),
  withHandlers({
    handleGetSharedAccessorialsApplyTo: (props: Object) => async () => {
      const {
        values,
        telGuid,
        loadData,
        setValues,
        branchGuid,
        accessorials,
        handleSetRecalculateAllChargesId,
      } = props;

      const sharedApplyTo = await getTelSharedAccessorialsApplyTo({
        telGuid,
        loadData,
        branchGuid,
        accessorials: R.values(accessorials),
        stopCount: R.pathOr(0, ['rateInfo', 'stopCount'], props),
        applyToType: GC.SHARED_ACCESSORIAL_AUTO_APPLY_TO_TYPE_CARRIER,
      });

      const carrierRateCharges = R.pathOr([], [GC.FIELD_CARRIER_RATE_CHARGES], values);
      const charges = G.replaceAutoChargesWithNewSharedAssessorialsApplyTo(carrierRateCharges, sharedApplyTo);
      const newValues = R.assoc(GC.FIELD_CARRIER_RATE_CHARGES, charges, values);

      setValues(newValues);

      G.callFunction(handleSetRecalculateAllChargesId);
    },
  }),
  withState('carrierRate', 'setCarrierRate', 0),
  withProps({
    isCarrier: true,
    isEditMode: true,
    isCreateMode: false,
    title: G.getWindowLocale('titles:edit-carrier-rate', 'Edit Carrier Rate'),
  }),
  pure,
);

const isShownDispatcherSection = R.compose(
  R.lt(0),
  R.length,
  R.values,
  R.filter(G.isNotNilAndNotEmpty),
  R.pick([GC.FIELD_DISPATCH_NAME, GC.FIELD_DISPATCH_PHONE, GC.FIELD_DISPATCH_EMAILS]),
);

const getCommonChargeProps = (props: Object) => {
  const currency = R.pathOr(
    R.pathOr(0, ['orderRateInfo', 'currency'], props),
    ['values', GC.FIELD_CURRENCY],
    props,
  );

  const customerLineHaul = R.pathOr(
    R.pathOr(0, ['orderRateInfo', 'customerLineHaul'], props),
    ['rateInfo', 'customerLineHaul'],
    props,
  );

  const totalCustomersRate = R.pathOr(
    R.pathOr(0, ['orderRateInfo', GC.FIELD_TOTAL_CUSTOMER_RATE], props),
    ['rateInfo', GC.FIELD_TOTAL_CUSTOMER_RATE],
    props,
  );

  const stopsQuantity = R.pathOr(0, ['rateInfo', 'stopCount'], props);
  const totalQuantities = R.path(['rateInfo', 'totalQuantities'], props);
  const cloRateAdjustments = R.pathOr([], ['rateInfo', 'cloRateAdjustments'], props);
  const normalizedTotal = R.pathOr({}, ['values', GC.SYSTEM_OBJECT_NORMALIZED_TOTAL], props);

  return {
    currency,
    stopsQuantity,
    normalizedTotal,
    totalQuantities,
    customerLineHaul,
    totalCustomersRate,
    cloRateAdjustments,
  };
};

const CarrierCharges = (props: Object) => {
  const { branchGuid, isEditMode, isCreateMode, recalculateAllChargesId } = props;

  const commonTotalProps = G.getTotalsFromValues(props);
  const commonChargeProps = getCommonChargeProps(props);

  return (
    <div>
      <DMChargeComponent
        {...commonTotalProps}
        {...commonChargeProps}
        rateProps={props}
        isCarrier={true}
        isCarrierRate={true}
        branchGuid={branchGuid}
        loadType={GC.FIELD_TEL}
        isEditMode={isEditMode}
        isCreateMode={isCreateMode}
        chargesArrayName={GC.FIELD_CARRIER_RATE_CHARGES}
        recalculateAllChargesId={recalculateAllChargesId}
      />
    </div>
  );
};

const CarrierRateForm = (props: Object) => {
  const {
    title,
    stops,
    values,
    setValues,
    openModal,
    closeModal,
    branchGuid,
    openLoader,
    isEditMode,
    closeLoader,
    contactList,
    isCreateMode,
    handleSubmit,
    equipmentGuid,
    initialValues,
    isValidEmails,
    setIsValidEmails,
    setSelectedCarrier,
    notAllowedCustomerRate,
    handleOpenAutoRateModal,
    handleSetRecalculateAllChargesId,
    handleGetSharedAccessorialsApplyTo,
  } = props;

  const { marginViolated, maxPayViolated, criticalMarginViolated } = getMarginViolationData(props);

  const carrierName = R.path([GC.SYSTEM_OBJECT_CARRIER_ASSIGNMENT, GC.FIELD_NAME], values);
  const carrierGuid = R.path([GC.SYSTEM_OBJECT_CARRIER_ASSIGNMENT, GC.FIELD_CARRIER_GUID], values);

  return (
    <Box width={835} bg='white'>
      <StickedBox top='0px' zIndex={15}>
        <Flex
          p='7px 10px'
          color='white'
          bg='dark.darkBlue'
          justifyContent='space-between'
        >
          <Box>
            {title}
          </Box>
          <Flex>
            {
              G.isNotNilAndNotEmpty(carrierGuid) &&
              <ShowScorecards
                openModal={openModal}
                branchGuid={branchGuid}
                carrierGuid={carrierGuid}
                carrierName={carrierName}
              />
            }
            <TextBtn1
              onClickHandler={handleSetRecalculateAllChargesId}
              text={G.getWindowLocale('titles:recalc-all-charges', 'Recalc All Charges')}
            />
            {
              isEditMode &&
              <TextBtn1
                onClickHandler={handleGetSharedAccessorialsApplyTo}
                text={G.getWindowLocale('titles:update-auto-acc', 'Update Auto Acc.')}
              />
            }
            {
              isCreateMode &&
              <TextBtn1
                onClickHandler={handleOpenAutoRateModal}
                text={G.getWindowLocale('titles:show-rates', 'Show Rates')}
              />
            }
            {
              R.and(isCreateMode, G.getAmousConfigByNameFromWindow(GC.UI_TEL_CARRIER_RATE_SHOW_LANE_HISTORY)) &&
              <ShowLaneHistory
                {...G.getModeServiceFromAsyncConfigsForRateSelect(props)}
                stops={stops}
                openModal={openModal}
                setValues={setValues}
                openLoader={openLoader}
                branchGuid={branchGuid}
                closeModal={closeModal}
                closeLoader={closeLoader}
                equipmentGuid={equipmentGuid}
                setSelectedCarrier={setSelectedCarrier}
                transportationModeGuid={R.prop(GC.FIELD_MODE, values)}
                serviceTypeGuid={R.prop(GC.FIELD_SERVICE_TYPE, values)}
              />
            }
          </Flex>
        </Flex>
      </StickedBox>
      <form onSubmit={handleSubmit}>
        {
          R.or(isCreateMode, R.and(isEditMode, G.isTrue(R.path(['selected'], initialValues)))) &&
          <MarginViolationSection
            {...G.getFormikProps(props)}
            marginViolated={marginViolated}
            maxPayViolated={maxPayViolated}
            criticalMarginViolated={criticalMarginViolated}
            optionsForSelect={{
              marginViolationReasonOptions: G.createOptionsFromDropdownConfigWithGuidOrParentGuid(
                props.asyncConfigs,
                GC.RATE_ENGINE_MARGIN_VIOLATION_REASON,
                true,
              ),
            }}
          />
        }
        <CarrierSection {...props} initialCarrier={R.path(['selectedCarrier'], props)} />
        <DispatcherSection
          {...G.getFormikProps(props)}
          openModal={openModal}
          closeModal={closeModal}
          contactList={contactList}
          setIsValidEmails={setIsValidEmails}
          isEditMode={isShownDispatcherSection(values)}
          fieldError={R.path(['errors', GC.FIELD_DISPATCH_EMAILS], props)}
          emails={R.pathOr([], ['values', GC.FIELD_DISPATCH_EMAILS], props)}
        />
        <TrackingReferenceSection {...G.getFormikProps(props)} initialValues={initialValues} />
        <Fieldset2
          {...G.getFormikProps(props)}
          fields={mainCarrierRateFields}
          fieldsWrapperStyles={{ pt: 20, px: 15 }}
          {...G.getModeServiceFromAsyncConfigsForRateSelect(props)}
        />
        <Fieldset2
          {...G.getFormikProps(props)}
          fields={totalCarrierRateFields}
          fieldsWrapperStyles={{ px: 15, mb: 20 }}
        />
        {
          isEditMode && <PriceSheetItems values={values} />
        }
        <CarrierCharges {...props} />
        <StickedBox bottom='0px' zIndex={13}>
          <ChargesTotal {...props} charges={getChargesFromValues(props)} />
          <ChargeFormFooter submitDisabled={R.or(R.not(isValidEmails), notAllowedCustomerRate)} />
          <NotAllowedCustomerRate notAllowedCustomerRate={notAllowedCustomerRate} />
        </StickedBox>
      </form>
    </Box>
  );
};

const enhanceCreateWithConfigs = compose(
  withConnectModalAndLoaderActions,
  withAsyncConfigs,
  withAsyncChargeAccessorials({ isCarrier: true, isCreateMode: true }),
  enhanceCreate,
);

const enhanceUpdateWithConfigs = compose(
  withConnectModalAndLoaderActions,
  withAsyncConfigs,
  withAsyncChargeAccessorials({ isCarrier: true, isCreateMode: false }),
  enhanceUpdate,
);

export default enhanceCreateWithConfigs(CarrierRateForm);

const EditCarrierRateForm = enhanceUpdateWithConfigs(CarrierRateForm);

export {
  EditCarrierRateForm,
  getCarrierRateFields,
  SelectRateFromIntegration,
};
