// components
import {
  createMainCharge,
  createFuelCharge,
  createDiscountCharge,
} from '../../../components/charge/settings';
// helpers/constants
import * as GC from '../../../constants';
import {
  DEFAULT_ITEM_WEIGHT_UOM_OPTIONS,
  DEFAULT_QUANTITY_RATE_UNIT_OPTIONS,
  DEFAULT_DISTANCE_RATE_UNIT_OPTIONS,
} from '../../../helpers/options';
//////////////////////////////////////////////////

const cloRateInitFields = {
  [GC.FIELD_FAX]: null,
  [GC.FIELD_MODE]: null,
  [GC.FIELD_PHONE]: null,
  [GC.FIELD_EMAIL]: null,
  [GC.FIELD_CURRENCY]: null,
  [GC.FIELD_COMMENTS]: null,
  [GC.FIELD_SERVICE_TYPE]: null,
  [GC.FIELD_SERVICE_DAYS]: null,
  [GC.FIELD_CONTACT_NAME]: null,
  [GC.FIELD_TRIP_MAX_PAY]: null,
  [GC.FIELD_TOTAL_TRIP_WEIGHT]: null,
  [GC.FIELD_TOTAL_TRIP_DISTANCE]: null,
  [GC.FIELD_TOTAL_TRIP_WEIGHT_UOM]: null,
  [GC.FIELD_TOTAL_TRIP_DISTANCE_UOM]: null,
};

const cloRateEmptyFields = {
  [GC.FIELD_FAX]: '',
  [GC.FIELD_MODE]: '',
  [GC.FIELD_PHONE]: '',
  [GC.FIELD_EMAIL]: '',
  [GC.FIELD_CURRENCY]: '',
  [GC.FIELD_COMMENTS]: '',
  [GC.FIELD_SERVICE_TYPE]: '',
  [GC.FIELD_SERVICE_DAYS]: '',
  [GC.FIELD_CONTACT_NAME]: '',
  [GC.FIELD_PHONE_EXTENSION]: '',
  [GC.FIELD_TOTAL_TRIP_WEIGHT]: '',
  [GC.FIELD_TOTAL_TRIP_DISTANCE]: '',
  [GC.FIELD_TOTAL_TRIP_WEIGHT_UOM]: '',
  [GC.FIELD_TOTAL_TRIP_DISTANCE_UOM]: '',
  [GC.FIELD_CHARGES]: [
    createMainCharge(),
    createFuelCharge(),
    createDiscountCharge(),
  ],
};

const firstInputWrapperStyles = {
  mr: 15,
  mb: 30,
  width: 170,
};

const mainCustomerRateFields = [
  {
    type: 'select',
    isRequired: true,
    fieldName: GC.FIELD_CURRENCY,
    options: GC.CURRENCY_OPTIONS,
    label: ['titles:currency', 'Currency'],
    inputWrapperStyles: firstInputWrapperStyles,
  },
  {
    type: 'select',
    isRequired: true,
    options: GC.FIELD_MODE,
    fieldName: GC.FIELD_MODE,
    label: ['titles:mode', 'Mode'],
    inputWrapperStyles: firstInputWrapperStyles,
  },
  {
    type: 'select',
    options: GC.FIELD_SERVICE_TYPE,
    fieldName: GC.FIELD_SERVICE_TYPE,
    inputWrapperStyles: firstInputWrapperStyles,
    label: ['titles:service-type', 'Service Type'],
  },
  {
    type: 'number',
    fieldName: GC.FIELD_SERVICE_DAYS,
    label: ['titles:service-days', 'Service Days'],
    inputWrapperStyles: { ...firstInputWrapperStyles, mr: 0 },
  },
];

const totalCustomerRateFields = [
  {
    type: 'number',
    isRequired: true,
    inputWrapperStyles: { width: 112 },
    fieldName: GC.FIELD_TOTAL_TRIP_DISTANCE,
    inputStyles: { borderRadius: '4px 0 0 4px' },
    label: ['titles:trip-distance', 'Trip Distance'],
  },
  {
    type: 'select',
    inputWrapperStyles: { width: 119, mr: 15 },
    fieldName: GC.FIELD_TOTAL_TRIP_DISTANCE_UOM,
    options: DEFAULT_DISTANCE_RATE_UNIT_OPTIONS,
    inputStyles: { borderRadius: '0 4px 4px 0' },
  },
  {
    type: 'number',
    isRequired: true,
    inputWrapperStyles: { width: 112 },
    fieldName: GC.FIELD_TOTAL_TRIP_WEIGHT,
    inputStyles: { borderRadius: '4px 0 0 4px' },
    label: ['titles:total-weight', 'Total Weight'],
  },
  {
    type: 'select',
    options: DEFAULT_ITEM_WEIGHT_UOM_OPTIONS,
    fieldName: GC.FIELD_TOTAL_TRIP_WEIGHT_UOM,
    inputWrapperStyles: { width: 119, mr: 15 },
    inputStyles: { borderRadius: '0 4px 4px 0' },
  },
  {
    type: 'number',
    inputWrapperStyles: { width: 112 },
    fieldName: GC.FIELD_TOTAL_PICKUP_QUANTITY,
    inputStyles: { borderRadius: '4px 0 0 4px' },
    label: ['titles:pickup-quantity', 'Pickup Quantity'],
  },
  {
    type: 'select',
    fieldName: GC.FIELD_PACKAGE_TYPE,
    inputWrapperStyles: { width: 119, mr: 0 },
    options: DEFAULT_QUANTITY_RATE_UNIT_OPTIONS,
    inputStyles: { borderRadius: '0 4px 4px 0' },
  },
];

const contactInputWrapperStyles = {
  mr: 15,
  width: 170,
};

const contactCustomerRateFields = [
  {
    type: 'text',
    fieldName: GC.FIELD_CONTACT_NAME,
    inputWrapperStyles: contactInputWrapperStyles,
    label: ['titles:contact-name', 'Contact Name'],
  },
  {
    type: 'phoneNumber',
    fieldName: GC.FIELD_PHONE,
    label: ['titles:phone', 'Phone'],
    inputWrapperStyles: contactInputWrapperStyles,
  },
  {
    type: 'number',
    fieldName: GC.FIELD_PHONE_EXTENSION,
    inputWrapperStyles: contactInputWrapperStyles,
    label: ['titles:phone-extension', 'Phone Extension'],
  },
  {
    type: 'text',
    fieldName: GC.FIELD_EMAIL,
    label: ['titles:email', 'Email'],
    inputWrapperStyles: { ...contactInputWrapperStyles, mr: 0 },
  },
  {
    type: 'text',
    fieldName: GC.FIELD_FAX,
    label: ['titles:fax-number', 'Fax Number'],
    inputWrapperStyles: { ...contactInputWrapperStyles, mt: 30 },
  },
  {
    type: 'text',
    fieldName: GC.FIELD_COMMENTS,
    label: ['titles:comments', 'Comments'],
    inputWrapperStyles: { mt: 30, mr: 0, width: 540 },
  },
];

const maxTripRateField = {
  type: 'text',
  fieldName: GC.FIELD_TRIP_MAX_PAY,
  inputWrapperStyles: contactInputWrapperStyles,
  label: ['titles:trip-max-pay', 'Trip Max Pay'],
};

export {
  maxTripRateField,
  cloRateInitFields,
  cloRateEmptyFields,
  mainCustomerRateFields,
  totalCustomerRateFields,
  contactCustomerRateFields,
};
