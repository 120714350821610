import * as R from 'ramda';
import FileDownload from 'js-file-download';
import { put, call, select, takeLatest } from 'redux-saga/effects';
// components
import { closeModal } from '../../../components/modal/actions';
import { openLoader, closeLoader } from '../../../components/loader/actions';
import {
  transformSearchCriteriaBeforeFilterPost,
  transformSearchCriteriaBeforeReportPost,
} from '../../../components/edit-report/helpers';
// features
import { makeSelectCurrentBranchGuid } from '../../branch/selectors';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// report-common
import { generateDefaultReport } from '../../../report-common';
// utilities
import { sendRequest } from '../../../utilities/http';
import endpointsMap from '../../../utilities/endpoints';
// feature payroll
import * as A from './actions';
import * as H from '../helpers';
import { vendorReport } from '../settings/payroll-list-table-settings';
import {
  makeSelectPagination,
  makeSelectUsedReport,
  makeSelectFilterParams,
  makeSelectTitleSortValues,
  makeSelectTableTitleFilters,
} from './selectors';
//////////////////////////////////////////////////

const requiredFields = [
  GC.FIELD_INVOICES_TOTAL,
  GC.FIELD_INVOICES_COUNT,
  GC.FIELD_PAYROLL_NUMBER,
  GC.FIELD_PAYROLL_DATE_TO,
  GC.GRC.FLEET_VENDOR_GUID,
  GC.GRC.FLEET_VENDOR_NAME,
  GC.FIELD_PAYROLL_DATE_FROM,
  GC.GRC.STATUS_DISPLAYED_VALUE,
];

function* getPayrollByReportAndUpdateOnListSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const report = yield select(makeSelectUsedReport());
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      data: {
        limit: 1,
        offset: 0,
        [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
        fields: G.addRequiredFields(G.getOrElse(report, 'fields', []), requiredFields),
        searchCriteria: R.of(Array, {
          dataType: 'string',
          operation: 'equal',
          stringValue: payload,
          propertyName: GC.FIELD_GUID,
        }),
      },
    };

    const res = yield call(sendRequest, 'post', endpointsMap.vendorPayrollList, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const payroll = R.path(['results', 0], data);

      if (G.isNotNil(payroll)) yield put(A.collapseAndResetPayrollInvoices(payroll));

      yield put(closeLoader());
    } else {
      yield call(G.handleFailResponse, res, 'getPayrollByReportAndUpdateOnListSaga fail');

      yield put(closeLoader());
    }
  } catch (err) {
    yield put(closeLoader());

    yield call(G.handleException, err, 'getPayrollByReportAndUpdateOnListSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handleGetItemListSaga({ payload }: boolean) {
  try {
    if (G.isTrue(payload)) yield put(openLoader({ showDimmer: true }));

    yield put(A.setListLoading(true));

    const pagination = yield select(makeSelectPagination());
    const reportParams = yield select(makeSelectUsedReport());
    const filterParams = yield select(makeSelectFilterParams());
    const titleOrderFields = yield select(makeSelectTitleSortValues());
    const titleFilterParams = yield select(makeSelectTableTitleFilters());
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());

    const newFilterParams = transformSearchCriteriaBeforeFilterPost(filterParams);

    const orderFields = G.ifElse(
      G.isNotEmpty(titleOrderFields),
      R.values(titleOrderFields),
      G.getOrElse(reportParams, 'orderFields', []),
    );

    const searchCriteria = G.ifElse(
      G.isNotEmpty(titleFilterParams),
      R.values(titleFilterParams),
      G.getOrElse(reportParams, 'searchCriteria', []),
    );

    const reqBody = {
      orderFields,
      limit: pagination.limit,
      offset: pagination.offset,
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
      searchCriteria: transformSearchCriteriaBeforeReportPost(searchCriteria),
      fields: G.addRequiredFields(G.getOrElse(reportParams, 'fields', []), requiredFields),
    };

    const options = {
      data: G.setSearchCriteria({ filterParams: newFilterParams, reqBody }),
    };

    const res = yield call(sendRequest, 'post', endpointsMap.vendorPayrollList, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getItemListSuccess(data));
      yield put(A.setListLoading(false));
      yield put(closeLoader());
    } else {
      yield call(G.handleFailResponse, res, 'handleGetItemListSaga fail');

      yield put(A.setListLoading(false));
      yield put(closeLoader());
    }
  } catch (err) {
    yield put(A.setListLoading(false));
    yield put(closeLoader());

    yield call(G.handleException, err, 'handleGetItemListSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handleAddPayrolls({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const data = {
      [GC.FIELD_BRANCH_GUID]: branchGuid,
      payrolls: H.getVendorPayrollsData(payload),
    };

    const res = yield call(sendRequest, 'post', endpointsMap.vendorPayroll, { data });

    if (G.isResponseSuccess(res.status)) {
      yield put(A.resetListAndPagination());

      yield call(handleGetItemListSaga, { payload: true });
    } else {
      yield call(G.handleFailResponse, res, 'handleAddPayrolls fail');
    }

    yield put(closeModal());
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleAddPayrolls exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handleCreateVendorPayrollsSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const data = R.assoc(GC.FIELD_BRANCH_GUID, branchGuid, payload);

    const res = yield call(sendRequest, 'post', endpointsMap.vendorPayrollWithoutTrips, { data });

    if (G.isResponseSuccess(res.status)) {
      yield put(A.resetListAndPagination());

      yield call(handleGetItemListSaga, { payload: true });

      yield put(closeModal());
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateVendorPayrollsSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleCreateVendorPayrollsSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handleUpdatePayrollSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const { callback, shouldClose, mapDataToFormValues } = payload;

    const options = {
      data: R.omit(['callback', 'shouldClose', 'mapDataToFormValues'], payload),
    };

    const res = yield call(sendRequest, 'put', endpointsMap.vendorPayroll, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getPayrollByReportAndUpdateOnListRequest(G.getGuidFromObject(data)));

      if (shouldClose) {
        yield put(closeModal());

        yield call(G.callFunction, callback);
      } else {
        // TODO: check if we need this else callback
        callback(mapDataToFormValues({ asyncInitialData: { data } }));
      }
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdatePayrollSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleUpdatePayrollSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handleGetPayrollDetails({ payload }: Object) {
  try {
    yield put(A.togglePayrollDetails(payload.guid));

    if (
      R.and(
        R.not(payload.expanded),
        R.isNil(R.prop('details', payload)),
      )
    ) {
      yield put(A.setPayrollDetailsLoading(payload.guid));

      const params = {
        payrollGuid: payload.guid,
      };

      const res = yield call(sendRequest, 'get', endpointsMap.vendorPayrollInvoices, { params });

      if (G.isResponseSuccess(res.status)) {
        yield put(A.getPayrollDetailsSuccess({
          details: res.data,
          guid: payload.guid,
        }));
      } else {
        yield put(A.getPayrollDetailsError(payload.guid));

        yield call(G.handleFailResponse, res, 'handleAddPayrolls fail');
      }
    }
  } catch (error) {
    yield call(G.handleException, error, 'handleGetPayrollDetails exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handleGetPayrollStatistic({ payload }: Object) {
  try {
    const { guid, expanded, statistic, afterDetailsUpdate } = payload;

    if (R.or(G.isTrue(afterDetailsUpdate), R.and(R.not(expanded), R.isNil(statistic)))) {
      yield put(A.setPayrollStatisticLoading({ guid, loading: true }));

      const res = yield call(sendRequest, 'get', endpointsMap.getVendorPayrollStatisticEndpoint(guid));

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        yield put(A.getPayrollStatisticSuccess({ guid, statistic: data }));
      } else {
        yield call(G.handleFailResponse, res, 'handleGetPayrollStatistic fail');
      }
    }

    yield put(A.setPayrollStatisticLoading({ guid, loading: false }));
  } catch (error) {
    yield put(A.setPayrollStatisticLoading({ loading: false, guid: G.getGuidFromObject(payload) }));

    yield call(G.handleException, error, 'handleGetPayrollStatistic exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handleUpdatePayrollInvoicesSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const res = yield call(sendRequest, 'put', endpointsMap.updateVendorPayrollInvoices, { data: payload });

    if (G.isResponseSuccess(res.status)) {
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');

      yield put(A.getPayrollByReportAndUpdateOnListRequest(G.getGuidFromObject(res.data)));
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdatePayrollInvoicesSaga fail');
    }

    yield put(closeModal());
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleUpdatePayrollInvoicesSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handleRemoveInvoiceFromPayrollSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const res = yield call(sendRequest, 'put', endpointsMap.removeInvoiceFromVendorPayroll, { params: payload });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.removePayrollInvoiceFromUI({
        payroll: data,
        invoiceGuid: G.getPropFromObject('invoiceGuid', payload),
      }));

      yield put(A.getPayrollStatistic({ afterDetailsUpdate: true, guid: G.getGuidFromObject(data) }));
    } else {
      yield call(G.handleFailResponse, res, 'handleRemoveInvoiceFromPayrollSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleRemoveInvoiceFromPayrollSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handleDeleteVendorPayrollSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const res = yield call(sendRequest, 'delete', endpointsMap.vendorPayroll, { data: payload });

    if (G.isResponseSuccess(res.status)) {
      if (R.gt(payload.length, 1)) {
        yield put(A.resetListAndPagination());

        yield call(handleGetItemListSaga, { payload: true });
      } else {
        yield put(A.removeVendorPayrollFromUI(R.head(payload)));
      }
    } else {
      yield call(G.handleFailResponse, res, 'handleDeleteVendorPayrollSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleDeleteVendorPayrollSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handleAvailableReportsRequest({ payload, notSetUsedReport }: Object) {
  try {
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());

    if (G.isNilOrEmpty(currentBranchGuid)) return false;

    const { reportType } = payload;

    const params = {
      reportType,
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
    };

    const res = yield call(sendRequest, 'get', endpointsMap.listReports, { params });

    if (G.isResponseSuccess(res.status)) {
      const reports = G.getReportsSortedBySeqFreez(res.data);

      yield put(A.setReports(reports));

      if (G.isNilOrEmpty(reports)) {
        return yield put(A.setUsedReport(generateDefaultReport(GC.VENDOR_PAYROLL_REPORT)));
      }

      if (R.not(notSetUsedReport)) {
        const defaultReport = G.findDefaultReport(reports);

        const usedReport = G.ifElse(
          G.isNilOrEmpty(R.path(['fields'], defaultReport)),
          R.assoc('fields', vendorReport.fields, defaultReport),
          defaultReport,
        );

        yield put(A.setUsedReport(usedReport));
      }
    } else {
      yield call(G.handleFailResponse, res, 'handleAvailableReportsRequest fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'handleAvailableReportsRequest exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handleCreateReportRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());

    const data = R.assoc(GC.FIELD_BRANCH_GUID, currentBranchGuid, payload);

    const res = yield call(sendRequest, 'post', endpointsMap.report, { data });

    if (G.isResponseSuccess(res.status)) {
      yield put(A.setUsedReport(G.getReportSortedBySeqFreez(res.data)));

      yield call(
        handleAvailableReportsRequest,
        {
          payload: {
            reportType: GC.VENDOR_PAYROLL_REPORT,
          },
          notSetUsedReport: true,
        },
      );
      yield call(handleGetItemListSaga, { payload: true });
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateReportRequestSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleCreateReportRequestSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handleUpdateReportRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const res = yield call(sendRequest, 'put', endpointsMap.report, { data: payload });

    if (G.isResponseSuccess(res.status)) {
      yield put(A.setUsedReport(G.getReportSortedBySeqFreez(res.data)));

      yield call(
        handleAvailableReportsRequest,
        {
          payload: {
            reportType: GC.VENDOR_PAYROLL_REPORT,
          },
          notSetUsedReport: true,
        },
      );

      yield call(handleGetItemListSaga, { payload: true });
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateReportRequestSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleUpdateReportRequestSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handleChangeDefaultReportSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const res = yield call(sendRequest, 'put', endpointsMap.changeDefaultReport, { data: payload });

    if (G.isResponseSuccess(res.status)) {
      yield call(
        handleAvailableReportsRequest,
        {
          payload: {
            reportType: GC.VENDOR_PAYROLL_REPORT,
          },
        },
      );

      yield call(handleGetItemListSaga, { payload: true });
    } else {
      yield call(G.handleFailResponse, res, 'handleChangeDefaultReportSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleChangeDefaultReportSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handleExportReportDataSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
    const filterParams = yield select(makeSelectFilterParams());

    const reqBody = {
      fields: payload.fields,
      orderFields: payload.orderFields,
      searchCriteria: payload.searchCriteria,
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
      [GC.FIELD_REPORT_NAME]: G.getPropFromObject(GC.FIELD_NAME, payload),
    };

    const params = { format: payload.fileType };

    const options = {
      params,
      resType: 'arraybuffer',
      data: G.setSearchCriteria({ filterParams, reqBody }),
    };

    const res = yield call(sendRequest, 'post', endpointsMap.vendorPayrollReportExport, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'info', 'messages:downloading-file');
    } else {
      yield call(G.handleFailResponse, res, 'handleExportReportDataSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleExportReportDataSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handleUpdateVendorInvoiceSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const { invoice, callback, updateFrom } = payload;

    const res = yield call(sendRequest, 'put', endpointsMap.telFleetVendorInvoice, { data: invoice });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'messages:success:update');

      yield put(A.updateVendorInvoiceSuccess({ invoice: data, updateFrom }));

      yield put(A.getPayrollStatistic({
        afterDetailsUpdate: true,
        guid: G.getPropFromObject(GC.FIELD_FLEET_VENDOR_PAYROLL_GUID, data),
      }));

      yield put(closeModal());

      yield call(G.callFunction, callback);
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateVendorInvoiceSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeModal());
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleUpdateVendorInvoiceSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handleGetPayrollXMLSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const { guid, isNew, payrollNumber } = payload;

    const endpoint = G.ifElse(
      G.isTrue(isNew),
      endpointsMap.getVendorPayrollNewXMLEndpoint,
      endpointsMap.getVendorPayrollXMLEndpoint,
    )(guid);

    const res = yield call(sendRequest, 'get', endpoint, { resType: 'arraybuffer' });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      FileDownload(data, `${payrollNumber}.xml`);
    } else {
      yield call(G.handleFailResponse, res, 'handleGetPayrollXMLSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleGetPayrollXMLSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handleGetDriverRatesXmlFileSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const { guid, payrollNumber } = payload;

    const endpoint = endpointsMap.getVendorDriverRatesXMLEndpoint(guid);

    const res = yield call(sendRequest, 'get', endpoint, { resType: 'arraybuffer' });

    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      FileDownload(data, `${payrollNumber}.xml`);
    } else {
      yield call(G.handleFailResponse, res, 'handleGetDriverRatesXmlFileSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleGetDriverRatesXmlFileSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handleSendToQuickBookSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      data: {
        [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
        guids: R.or(payload.fileGuids, payload.guids),
      },
    };

    const res = yield call(sendRequest, 'post', endpointsMap.vendorPayrollExportToQuickBooks, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      if (G.isNotNilAndNotEmpty(data.errors)) {
        G.handlePartialSuccessErrors(data.errors);
      } else {
        yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
      }
    } else {
      yield call(G.handleFailResponse, res, 'handleSendToQuickBookSaga fail', true);
    }

    yield put(A.resetListAndPagination());

    yield call(handleGetItemListSaga, { payload: true });

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleSendToQuickBookSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handlePrintPayrollSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const options = {
      resType: 'arraybuffer',
      data: {
        format: GC.EXTENSION_PDF,
        templateGuid: payload.templateGuid,
        payrollsGuids: payload.payrollGuids,
      },
    };

    const res = yield call(sendRequest, 'post', endpointsMap.vendorPayrollExport, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());

      yield call(G.showToastrMessage, 'info', 'messages:downloading-file');
    } else {
      yield call(G.handleFailResponse, res, 'handlePrintPayrollSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handlePrintPayrollSaga exception');
  }
}

function* handlePrintByReportSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const reportParams = yield select(makeSelectUsedReport());
    const filterParams = yield select(makeSelectFilterParams());
    const requestFilterParams = transformSearchCriteriaBeforeFilterPost(filterParams);
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());

    const reqBody = {
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
      orderFields: G.getOrElse(reportParams, 'orderFields', []),
      searchCriteria: transformSearchCriteriaBeforeReportPost(G.getOrElse(reportParams, 'searchCriteria', [])),
    };

    const options = {
      params: payload,
      resType: 'arraybuffer',
      data: G.setSearchCriteria({ filterParams: requestFilterParams, reqBody }),
    };

    const res = yield call(sendRequest, 'post', endpointsMap.vendorPayrollPrintByReport, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      G.saveFileFromResponse(res, GC.VENDOR_PAYROLL_REPORT);

      yield put(closeModal());
    } else {
      yield call(G.handleFailResponse, res, 'handlePrintByReportSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handlePrintByReportSaga exception');
  }
}

function* handleGetXMLByReportSaga() {
  try {
    yield put(openLoader({ showDimmer: true }));

    const reportParams = yield select(makeSelectUsedReport());
    const filterParams = yield select(makeSelectFilterParams());
    const requestFilterParams = transformSearchCriteriaBeforeFilterPost(filterParams);
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());

    const reqBody = {
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
      fields: G.getOrElse(reportParams, 'fields', []),
      orderFields: G.getOrElse(reportParams, 'orderFields', []),
      searchCriteria: transformSearchCriteriaBeforeReportPost(G.getOrElse(reportParams, 'searchCriteria', [])),
    };

    const options = {
      resType: 'arraybuffer',
      data: G.setSearchCriteria({ filterParams: requestFilterParams, reqBody }),
    };

    const res = yield call(sendRequest, 'post', endpointsMap.vendorPayrollXmlFileByReport, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      G.saveFileFromResponse(res, `${GC.VENDOR_PAYROLL_REPORT}.xml`);

      yield put(closeModal());
    } else {
      yield call(G.handleFailResponse, res, 'handleGetXMLByReportSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleGetXMLByReportSaga exception');
  }
}

function* chaseCSVExportRequest({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const options = {
      data: payload,
    };

    const res = yield call(sendRequest, 'post', endpointsMap.vendorPayrollChaseExport, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      G.saveFileFromResponse(res);

      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'chaseCSVExportRequest fail', true);
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'chaseCSVExportRequest exception');
  }
}

function* exportStatisticRequest({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const options = {
      data: payload,
      resType: 'arraybuffer',
      params: { format: GC.EXTENSION_XLSX },
    };

    const res = yield call(sendRequest, 'post', endpointsMap.vendorPayrollStatisticExport, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      G.saveFileFromResponse(res);

      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'exportStatisticRequest fail', true);
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'exportStatisticRequest exception');
  }
}

export function* handleStartVendorPayrollSaga() {
  try { // eslint-disable-line
    yield put(A.setVisited());
    yield put(A.setReportPending());

    yield call(handleAvailableReportsRequest, { payload: { reportType: GC.VENDOR_PAYROLL_REPORT }});
    yield call(handleGetItemListSaga, { payload: true });
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleStartVendorPayrollSaga exception');
  }
}

function* VendorPayrollWatcherSaga() {
  yield takeLatest(A.addPayrolls, handleAddPayrolls);
  yield takeLatest(A.getItemListRequest, handleGetItemListSaga);
  yield takeLatest(A.getPayrollDetails, handleGetPayrollDetails);
  yield takeLatest(A.printPayrollRequest, handlePrintPayrollSaga);
  yield takeLatest(A.chaseCSVExportRequest, chaseCSVExportRequest);
  yield takeLatest(A.getPayrollXMLRequest, handleGetPayrollXMLSaga);
  yield takeLatest(A.printByReportRequest, handlePrintByReportSaga);
  yield takeLatest(A.getPayrollStatistic, handleGetPayrollStatistic);
  yield takeLatest(A.exportStatisticRequest, exportStatisticRequest);
  yield takeLatest(A.getXMLByReportRequest, handleGetXMLByReportSaga);
  yield takeLatest(A.startVendorPayroll, handleStartVendorPayrollSaga);
  yield takeLatest(A.sendToQuickBookRequest, handleSendToQuickBookSaga);
  yield takeLatest(A.createReportRequest, handleCreateReportRequestSaga);
  yield takeLatest(A.updateReportRequest, handleUpdateReportRequestSaga);
  yield takeLatest(A.handleUpdatePayrollRequest, handleUpdatePayrollSaga);
  yield takeLatest(A.exportReportDataRequest, handleExportReportDataSaga);
  yield takeLatest(A.updatePayrollInvoices, handleUpdatePayrollInvoicesSaga);
  yield takeLatest(A.updateVendorInvoiceRequest, handleUpdateVendorInvoiceSaga);
  yield takeLatest(A.changeDefaultReportRequest, handleChangeDefaultReportSaga);
  yield takeLatest(A.deleteVendorPayrollsRequest, handleDeleteVendorPayrollSaga);
  yield takeLatest(A.createVendorPayrollsRequest, handleCreateVendorPayrollsSaga);
  yield takeLatest(A.removeInvoiceFromPayroll, handleRemoveInvoiceFromPayrollSaga);
  yield takeLatest(A.getDriverRatesXmlFileRequest, handleGetDriverRatesXmlFileSaga);
  yield takeLatest(A.getPayrollByReportAndUpdateOnListRequest, getPayrollByReportAndUpdateOnListSaga);
}

export default VendorPayrollWatcherSaga;
