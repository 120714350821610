import React from 'react';
import * as R from 'ramda';
import * as Yup from 'yup';
import { withFormik } from 'formik';
import upperCase from 'voca/upper_case';
import {
  pure,
  compose,
  lifecycle,
  withProps,
  withState,
  withHandlers,
} from 'react-recompose';
// components
import { ChargeFormFooter } from '../../../components/form-footer';
import PriceSheetItems from '../../../components/price-sheet-items';
import { ChargesTotal } from '../../../components/charge/components';
import { withRecalculateAllChargesId } from '../../../components/charge/hocs';
import { DMChargeComponent } from '../../../components/charge/formik/charges-array';
import {
  withAsyncChargeAccessorials,
  getTelSharedAccessorialsApplyTo,
} from '../../../components/charge/hocs/with-async-charge-accessorials';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import {
  withAsyncConfigs,
  withAsyncBranchConfigsByNames,
  withAsyncMarginViolationRules,
  withConnectModalAndLoaderActions,
  withComponentDidUpdatePropCallback,
} from '../../../hocs';
// ui
import { Box, StickedBox, SectionsDivider } from '../../../ui';
// feature rate
import RateFleetMap from './fleet-map';
import { RateHeaderAuto } from './rate-header';
import { getDriverRateValidationSchema } from '../validation';
import MarginViolationSection from '../components/margin-violation-section';
import { NotAllowedCustomerRate } from '../components/not-allowed-customer-rate';
import { getTeamDriverCompensations, getDriverCompensationsReqBody } from '../api';
import { withSearchLocation, withFleetAssignment, withAsyncDeadheadMiles } from '../hocs';
import {
  DriverSection,
  PointsSection,
  EditDriverSection,
  GeneralSectionForTel,
} from '../sections';
import {
  getDriverInitCharges,
  getChargesFromValues,
  filterDriversOptions,
  getMarginViolationData,
  setDefaultDriverRateValues,
  getChargesArrayNameFromProps,
  makeResultObjectOnChangeDriver,
  makeResultObjectOnRemoveDriver,
  makeResultObjectOnChangeVendorRate,
} from '../helpers';
//////////////////////////////////////////////////

const getTelBranchGuid = (props: Object) => R.or(
  R.or(props.branchGuid, R.path([GC.FIELD_TEL, GC.FIELD_BRANCH_GUID], props)),
  R.path([GC.FIELD_TEL, GC.FIELD_BRANCH, GC.FIELD_GUID], props),
);

const handleGetSingleDriverCompensation = async (driverGuid: string, props: Object) => {
  const { getSingleDriverCompensations } = props;

  const reqBody = getDriverCompensationsReqBody(driverGuid, props);

  const driver = await getSingleDriverCompensations(reqBody);

  return driver;
};

const handleGetVendorRates = async (truckGuid: string, props: Object) => {
  const { values, telGuid, loadData, telEvents, isFromOrder, isFromPlanner, getVendorRates } = props;

  const { serviceType, transportationMode } = values;

  const branchGuid = getTelBranchGuid(props);

  if (G.isAllTrue(
    G.isTrue(isFromPlanner),
    G.isNotNilAndNotEmpty(truckGuid),
    G.isNotNilAndNotEmpty(branchGuid),
    G.isNotNilAndNotEmpty(telEvents),
  )) {
    const data = await getVendorRates({
      telGuid,
      serviceType,
      loadGuid: telGuid,
      transportationMode,
      truckGuids: R.of(Array, truckGuid),
      loadData: {
        events: telEvents,
      },
      [GC.FIELD_BRANCH_GUID]: branchGuid,
    });

    return data;
  }

  if (G.isAllTrue(
    G.isNotNilAndNotEmpty(truckGuid),
    G.isNotNilAndNotEmpty(branchGuid),
    G.isNotNilAndNotEmpty(telGuid),
  )) {
    const data = await getVendorRates({
      telGuid,
      serviceType,
      loadGuid: telGuid,
      transportationMode,
      truckGuids: R.of(Array, truckGuid),
      [GC.FIELD_BRANCH_GUID]: branchGuid,
    });

    return data;
  }

  if (G.isAllTrue(
    G.isTrue(isFromOrder),
    G.isNotNilAndNotEmpty(loadData),
    G.isNotNilAndNotEmpty(truckGuid),
    G.isNotNilAndNotEmpty(branchGuid),
  )) {
    const data = await getVendorRates({
      loadData,
      serviceType,
      transportationMode,
      truckGuids: R.of(Array, truckGuid),
      [GC.FIELD_BRANCH_GUID]: branchGuid,
    });

    return data;
  }
};

const handleGetAllVendorRates = async (props: Object) => {
  const { values, telGuid, telEvents, isFromPlanner, getVendorRates } = props;

  const { serviceType, transportationMode } = values;

  const branchGuid = getTelBranchGuid(props);

  if (G.isAllTrue(
    G.isTrue(isFromPlanner),
    G.isNotNilAndNotEmpty(branchGuid),
    G.isNotNilAndNotEmpty(telEvents),
  )) {
    const data = await getVendorRates({
      telGuid,
      serviceType,
      loadGuid: telGuid,
      transportationMode,
      loadData: {
        events: telEvents,
      },
      [GC.FIELD_BRANCH_GUID]: branchGuid,
    });

    return data;
  }

  if (G.isAllTrue(
    G.isNotNilAndNotEmpty(branchGuid),
    G.isNotNilAndNotEmpty(telGuid),
  )) {
    const data = await getVendorRates({
      telGuid,
      serviceType,
      loadGuid: telGuid,
      transportationMode,
      [GC.FIELD_BRANCH_GUID]: branchGuid,
    });

    return data;
  }
};

const handleGetAssignmentNew = (props: Object, shouldGetCompensations: boolean) => {
  const { values, telGuid, getAssignmentByLoadData } = props;

  const branchGuid = getTelBranchGuid(props);

  if (G.isAllTrue(
    G.isNotNilAndNotEmpty(branchGuid),
    G.isNotNilAndNotEmpty(telGuid),
  )) {
    const { serviceType, transportationMode } = values;

    getAssignmentByLoadData({
      telGuid,
      serviceType,
      loadGuid: telGuid,
      transportationMode,
      shouldGetCompensations,
      [GC.FIELD_BRANCH_GUID]: branchGuid,
    });
  }
};

const handleGetAssignmentFromRouteBuilder = (props: Object, shouldGetCompensations: boolean) => {
  const { values, telGuid, telEvents, getAssignmentFromRouteBuilder } = props;

  const branchGuid = getTelBranchGuid(props);

  if (G.isAllTrue(
    G.isNotNilAndNotEmpty(branchGuid),
    G.isNotNilAndNotEmpty(telEvents),
  )) {
    const { serviceType, transportationMode } = values;

    const data = {
      telGuid,
      serviceType,
      loadGuid: telGuid,
      transportationMode,
      shouldGetCompensations,
      loadData: {
        events: telEvents,
      },
      [GC.FIELD_BRANCH_GUID]: branchGuid,
    };

    getAssignmentFromRouteBuilder(data);
  }
};

const handleGetAssignmentFromOrder = (props: Object, shouldGetCompensations: boolean) => {
  const { values, loadData, getAssignmentFromOrder } = props;

  const branchGuid = getTelBranchGuid(props);
  if (G.isAllTrue(
    G.isNotNilAndNotEmpty(branchGuid),
    G.isNotNilAndNotEmpty(loadData),
  )) {
    const { serviceType, transportationMode } = values;

    const data = {
      loadData,
      serviceType,
      transportationMode,
      shouldGetCompensations,
      [GC.FIELD_BRANCH_GUID]: branchGuid,
    };

    getAssignmentFromOrder(data);
  }
};

const getSecondaryDriver = (secondaryDriverGuid: string, rateData: Object, availableDrivers: Object) => {
  const driverFromRateData = R.path([secondaryDriverGuid], rateData);
  const driverFromAvailableDrivers = R.path([secondaryDriverGuid], availableDrivers);

  if (G.isNotNilAndNotEmpty(driverFromRateData)) return driverFromRateData;

  if (G.isNotNilAndNotEmpty(driverFromAvailableDrivers)) return driverFromAvailableDrivers;

  return null;
};

const getStartLocation = (driver: Object) => {
  const precedingLocation = R.path(['precedingLocation'], driver);
  const currentLocation = R.path(['currentLocation'], driver);

  if (G.isNotNilAndNotEmpty(precedingLocation)) {
    const sourceName = R.path(['telPrimaryReferenceValue'], precedingLocation);

    const location = {
      ...precedingLocation,
      sourceName,
      sourceType: GC.START_POINT_SOURCE_TYPE_TEL,
    };

    const current = R.omit(GC.GROUPED_FIELDS.SYSTEM_OMIT_ARR, location);

    return R.assoc(
      GC.FIELD_ADDRESS_1,
      G.concatLocationFields(current),
      current,
    );
  }

  if (G.isNotNilAndNotEmpty(currentLocation)) {
    const sourceName = R.path([GC.FIELD_CREATED_BY], currentLocation);

    const location = {
      ...currentLocation,
      sourceName,
      sourceType: GC.START_POINT_SOURCE_TYPE_LNL,
    };

    const current = R.omit(GC.GROUPED_FIELDS.SYSTEM_OMIT_ARR, location);

    return R.assoc(
      GC.FIELD_ADDRESS_1,
      G.concatLocationFields(current),
      current,
    );
  }
};

const getRateDriverData = (driver: Object) => {
  const truckGuid = R.path(['assignment', GC.FIELD_TRUCK_GUID], driver);
  const secondaryDriverGuid = R.path(['assignment', GC.FIELD_SECONDARY_DRIVER_GUID], driver);

  const primaryDistanceToStart = R.propOr(0, GC.FIELD_DISTANCE_TO_START, driver);

  const startLocation = getStartLocation(driver);

  return {
    truckGuid,
    startLocation,
    secondaryDriverGuid,
    primaryDistanceToStart,
  };
};

const enhanceDrivers = compose(
  withState('truckFleetVendor', 'setTruckFleetVendor', null),
  withState('driverFleetVendor', 'setDriverFleetVendor', null),
  withState('primaryDriverPayedHours', 'setPrimaryDriverPayedHours', null),
  withState('secondaryDriverPayedHours', 'setSecondaryDriverPayedHours', null),
  withState('primaryDriverDistanceToStart', 'setPrimaryDriverDistanceToStart', 0),
  withState('secondaryDriverDistanceToStart', 'setSecondaryDriverDistanceToStart', 0),
  withHandlers({
    handleChangeDriversSelect: (props: Object) => async (driverGuid: string, fieldId: string, from: string) => {
      const {
        values,
        rateData,
        setValues,
        vendorRates,
        activeDriver,
        setFieldValue,
        setActiveDriver,
        setTruckFleetVendor,
        availableDriversData,
        setPrimaryDriverPayedHours,
        setSecondaryDriverPayedHours,
        setPrimaryDriverDistanceToStart,
        handleSetRecalculateAllChargesId,
        setSecondaryDriverDistanceToStart,
      } = props;

      const availableDrivers = R.indexBy(
        R.prop(GC.FIELD_GUID),
        R.pathOr([], ['drivers'], availableDriversData),
      );

      const driver = R.or(
        R.path([driverGuid], rateData),
        R.path([driverGuid], availableDrivers),
      );

      const cloRateAdjustments = R.path([GC.FIELD_CLO_RATE_ADJUSTMENTS], availableDriversData);

      if (R.equals(GC.FIELD_PRIMARY_DRIVER_GUID, fieldId)) {
        if (R.isNil(driverGuid)) {
          const result = makeResultObjectOnRemoveDriver();

          const newValues = R.mergeRight(values, result);

          setActiveDriver('primary');

          return setValues(newValues);
        }

        const {
          truckGuid,
          startLocation,
          secondaryDriverGuid,
          primaryDistanceToStart,
        } = getRateDriverData(driver);

        const secondaryDriver = getSecondaryDriver(secondaryDriverGuid, rateData, availableDrivers);

        const availTrucks = R.prop('availTrucks', props);
        const truck = R.find(R.propEq(truckGuid, GC.FIELD_GUID), availTrucks);
        const truckFleetVendor = R.path([GC.SYSTEM_OBJECT_FLEET_VENDOR], truck);

        const defaultUomSystem = G.getConfigGeneralUomCalcDefaultUomSystemFromWindow();
        const deadheadUom = R.pathOr(GC.UOM_MILE, [defaultUomSystem], GC.uomSystemToDistanceUomMap);

        const deadhead = G.milesKmsFromToAccordingSystemWithNullable(
          deadheadUom,
          GC.UOM_KILOMETER,
          primaryDistanceToStart,
          2,
        );

        let result = makeResultObjectOnChangeDriver(driver, secondaryDriver, props);

        if (G.notEquals(from, 'newCompensation')) {
          handleGetSingleDriverCompensation(driverGuid, props);

          if (R.and(G.isNotNilAndNotEmpty(truckFleetVendor), G.isNotNilAndNotEmpty(vendorRates))) {
            const propsObj = { vendorRates, truckFleetVendor, values: result };

            const vendorResult = makeResultObjectOnChangeVendorRate(propsObj);

            result = R.mergeRight(result, vendorResult);
          }
        }

        const newValues = R.mergeRight(values, result);

        let valuesToUse = {
          ...newValues,
          frontData: {
            driver,
            secondaryDriver,
            vendor: truckFleetVendor,
          },
          [GC.FIELD_DEADHEAD_DISTANCE]: deadhead,
          [GC.FIELD_DEADHEAD_DISTANCE_UOM]: deadheadUom,
          [GC.FIELD_START_POINT_LOCATION]: startLocation,
          [GC.FIELD_CLO_RATE_ADJUSTMENTS]: cloRateAdjustments,
        };

        if (R.and(R.equals(from, 'newCompensation'), G.isNotNilAndNotEmpty(secondaryDriverGuid))) {
          const resData = await getTeamDriverCompensations(secondaryDriverGuid, props);

          const charges = R.path(['rateData', 0, GC.FIELD_CHARGES], resData);

          valuesToUse = R.assoc(GC.FIELD_SECONDARY_DRIVER_CHARGES, getDriverInitCharges(charges, props), valuesToUse);
        }

        if (R.and(R.equals(from, 'newCompensation'), G.isNotNilAndNotEmpty(truckFleetVendor))) {
          const vendorRates = await handleGetVendorRates(truckGuid, props);

          if (G.isNotNilAndNotEmpty(vendorRates)) {
            const propsObj = { vendorRates, truckFleetVendor, values: valuesToUse };

            const result = makeResultObjectOnChangeVendorRate(propsObj);

            valuesToUse = R.mergeRight(valuesToUse, result);
          }
        }

        setValues(valuesToUse);

        setTruckFleetVendor(truckFleetVendor);
        setPrimaryDriverDistanceToStart(primaryDistanceToStart);
        setPrimaryDriverPayedHours(R.pathOr(0, [GC.FIELD_PAYED_HOURS], driver));
        setSecondaryDriverPayedHours(R.pathOr(0, [GC.FIELD_PAYED_HOURS], secondaryDriver));
        setSecondaryDriverDistanceToStart(R.pathOr(0, [GC.FIELD_DISTANCE_TO_START], secondaryDriver));

        if (R.and(G.isNilOrEmpty(truckFleetVendor), R.equals(activeDriver, 'vendor'))) {
          setActiveDriver('primary');

          if (G.isNotNilAndNotEmpty(truckGuid)) setFieldValue(GC.FIELD_FLEET_VENDOR_CHARGES, null);
        }

        if (R.and(G.isNotNilAndNotEmpty(truckFleetVendor), G.notEquals(activeDriver, 'vendor'))) {
          setActiveDriver('vendor');
        }
      } else if (R.equals(GC.FIELD_SECONDARY_DRIVER_GUID, fieldId)) {
        if (R.isNil(driverGuid)) {
          const newValues = {
            ...values,
            [GC.FIELD_SECONDARY_DRIVER_GUID]: null,
            [GC.FIELD_SECONDARY_DRIVER_CHARGES]: null,
          };

          setValues(newValues);
        } else {
          const driverCharges = R.path([GC.FIELD_CHARGES], driver);

          if (R.isNil(driverCharges)) {
            const resData = await getTeamDriverCompensations(driverGuid, props);

            const charges = R.path(['rateData', 0, GC.FIELD_CHARGES], resData);

            const newValues = {
              ...values,
              [GC.FIELD_SECONDARY_DRIVER_GUID]: driverGuid,
              [GC.FIELD_SECONDARY_DRIVER_CHARGES]: getDriverInitCharges(charges, props),
            };

            setValues(newValues);
          } else {
            const newValues = {
              ...values,
              [GC.FIELD_SECONDARY_DRIVER_GUID]: driverGuid,
              [GC.FIELD_SECONDARY_DRIVER_CHARGES]: getDriverInitCharges(driverCharges, props),
            };

            setValues(newValues);
          }
        }

        setSecondaryDriverPayedHours(R.pathOr(0, [GC.FIELD_PAYED_HOURS], driver));
        setSecondaryDriverDistanceToStart(R.pathOr(0, [GC.FIELD_DISTANCE_TO_START], driver));
      }

      G.callFunction(handleSetRecalculateAllChargesId);
    },
  }),
  withHandlers({
    handleChangeSelectedRateGeneratedGuid: (props: Object) => () => {
      const { values, handleChangeDriversSelect } = props;

      const driverGuid = R.path([GC.FIELD_PRIMARY_DRIVER_GUID], values);

      if (G.isNilOrEmpty(driverGuid)) return;

      handleChangeDriversSelect(driverGuid, GC.FIELD_PRIMARY_DRIVER_GUID, 'newCompensation');
    },
    handleChangeVendorRatesGeneratedGuid: (props: Object) => () => {
      const { values, setValues, handleSetRecalculateAllChargesId } = props;

      const truckGuid = R.path([GC.FIELD_TRUCK_GUID], values);
      const availTrucks = R.pathOr([], ['availTrucks'], props);
      const truck = R.find(R.propEq(truckGuid, GC.FIELD_GUID), availTrucks);
      const truckFleetVendor = R.path([GC.SYSTEM_OBJECT_FLEET_VENDOR], truck);

      if (R.or(G.isNilOrEmpty(truckGuid), G.isNilOrEmpty(truckFleetVendor))) return;

      const result = makeResultObjectOnChangeVendorRate(props);

      const newValues = R.mergeRight(values, result);

      setValues(newValues);

      G.callFunction(handleSetRecalculateAllChargesId);
    },
  }),
  withComponentDidUpdatePropCallback({
    propName: 'rateGeneratedGuid',
    callbackName: 'handleChangeSelectedRateGeneratedGuid',
  }),
  withComponentDidUpdatePropCallback({
    propName: 'vendorRateGeneratedGuid',
    callbackName: 'handleChangeVendorRatesGeneratedGuid',
  }),
);

const enhanceFleetMap = compose(
  withHandlers({
    handleClickShowMap: (props: Object) => () => {
      const {
        openModal,
        availTrucks,
        availTrailers,
        availableDriversData,
      } = props;

      const modalContent = (
        <RateFleetMap
          trucks={availTrucks}
          trailers={availTrailers}
          drivers={R.path(['drivers'], availableDriversData)}
          stops={R.pathOr([], [GC.FIELD_TEL, GC.FIELD_LOAD_STOPS], props)}
          selectedDriver={R.path(['values', GC.FIELD_PRIMARY_DRIVER_GUID], props)}
        />
      );

      const modal = {
        p: '0',
        component: modalContent,
        options: {
          boxShadow: 'none',
          outsideCloseButton: true,
        },
      };

      openModal(modal);
    },
  }),
);

const enhance = compose(
  withState('notAllowedCustomerRate', 'setNotAllowedCustomerRate', ({ notAllowCustomerRateInit }: Object) => (
    R.or(notAllowCustomerRateInit, false)
  )),
  withHandlers({
    handleChangeDriver: ({ setActiveDriver }: Object) => (value: string) => setActiveDriver(value),
    handleChangeTruckSelect: (props: Object) => (truckGuid: string) => {
      const {
        availTrucks,
        activeDriver,
        setFieldValue,
        setActiveDriver,
        setTruckFleetVendor,
      } = props;

      const truck = R.find(R.propEq(truckGuid, GC.FIELD_GUID), availTrucks);
      const truckFleetVendor = G.getPropFromObject(GC.SYSTEM_OBJECT_FLEET_VENDOR, truck);

      if (G.isNilOrEmpty(truckFleetVendor)) {
        setTruckFleetVendor(null);
        setFieldValue(GC.FIELD_FLEET_VENDOR_CHARGES, null);
      }

      if (G.isNotNilAndNotEmpty(truckFleetVendor)) {
        setTruckFleetVendor(truckFleetVendor);

        if (G.notEquals(activeDriver, 'vendor')) {
          setActiveDriver('vendor');
        }
      }

      if (R.and(G.isNilOrEmpty(truckFleetVendor), R.equals(activeDriver, 'vendor'))) {
        setActiveDriver('primary');
      }

      handleGetVendorRates(truckGuid, props);
    },
  }),
  withHandlers({
    handleCustomChange: (props: Object) => (fieldValue: string, fieldId: string) => {
      const { setFieldValue, handleChangeTruckSelect, handleChangeDriversSelect } = props;

      if (R.includes(fieldId, [GC.FIELD_PRIMARY_DRIVER_GUID, GC.FIELD_SECONDARY_DRIVER_GUID])) {
        handleChangeDriversSelect(fieldValue, fieldId);
      }

      if (R.equals(fieldId, GC.FIELD_TRUCK_GUID)) {
        setFieldValue(fieldId, fieldValue);
        handleChangeTruckSelect(fieldValue);
      }
    },
  }),
  pure,
);

const getAvailableDriversHandler = ({ isFromOrder, isFromPlanner }: Object) => {
  if (G.isTrue(isFromPlanner)) return handleGetAssignmentFromRouteBuilder;

  if (G.isTrue(isFromOrder)) return handleGetAssignmentFromOrder;

  return handleGetAssignmentNew;
};

const setFleetAssignmentFromValues = (values: Object) => {
  const fleetAssignment = R.pick(GC.GROUPED_FIELDS.FLEET_ASSIGNMENT_PICK_ARR_2, values);

  return R.assoc(GC.FIELD_FLEET_ASSIGNMENT, fleetAssignment, values);
};

const getDriverName = (guid: string, rateData: Object, { drivers }: Object) => {
  const driver = R.pathOr(
    R.find(R.propEq(guid, GC.FIELD_GUID), drivers),
    [guid],
    rateData,
  );

  const firstName = R.pathOr('', [GC.FIELD_FIRST_NAME], driver);
  const lastName = R.pathOr('', [GC.FIELD_LAST_NAME], driver);

  if (R.and(G.isNilOrEmpty(firstName), G.isNilOrEmpty(lastName))) return null;

  return `${firstName} ${lastName}`;
};

const getTruckName = (guid: string, trucks: Array) => R.compose(
  R.prop(GC.FIELD_UNIT_ID),
  R.find(R.propEq(guid, GC.FIELD_GUID)),
)(trucks);

const getTrailerNames = (guids: Array, trailers: Array) => R.compose(
  R.map(R.prop(GC.FIELD_UNIT_ID)),
  R.values,
  R.pick(guids),
  R.indexBy(R.prop(GC.FIELD_GUID)),
)(trailers);

const setInfoToDriverRate = (
  values: Object,
  rateData: Object,
  { availTrucks, availTrailers, availableDriversData }: Object,
) => {
  const { guid, truckGuid, trailerGuids, primaryDriverGuid, secondaryDriverGuid } = values;

  return {
    ...values,
    isNew: G.isNilOrEmpty(guid),
    truckName: getTruckName(truckGuid, availTrucks),
    trailerNames: getTrailerNames(trailerGuids, availTrailers),
    primaryDriverName: getDriverName(primaryDriverGuid, rateData, availableDriversData),
    secondaryDriverName: getDriverName(secondaryDriverGuid, rateData, availableDriversData),
  };
};

const enhanceCreate = compose(
  withRecalculateAllChargesId,
  withFleetAssignment,
  withAsyncMarginViolationRules,
  withState('activeDriver', 'setActiveDriver', 'primary'),
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: (props: Object) => setDefaultDriverRateValues(props),
    validationSchema: () => Yup.lazy((values: Object) => getDriverRateValidationSchema(values)),
    handleSubmit: (values: Object, { props }: Object) => {
      const {
        rateData,
        isFromOrder,
        availTrucks,
        shouldSelect,
        isFromPlanner,
        availTrailers,
        handleSendTelRate,
        availableDriversData,
      } = props;

      const chassisFields = R.pick(GC.CHASSIS_GROUPED_FIELDS, values);
      const fleetAssignment = R.mergeRight(R.propOr({}, GC.FIELD_FLEET_ASSIGNMENT, values), chassisFields);

      const valuesWithoutEmptyStrings = G.mapObjectEmptyStringFieldsToNull(values);

      const data = R.mergeRight(valuesWithoutEmptyStrings, {
        fleetAssignment,
        selected: G.ifElse(shouldSelect, true, false),
      });

      const dataToUse = G.omitEmptyChargesFromDriverRate(data);

      if (isFromPlanner) {
        return handleSendTelRate(setInfoToDriverRate(
          dataToUse,
          rateData,
          { availTrucks, availTrailers, availableDriversData },
        ));
      }

      if (isFromOrder) {
        return handleSendTelRate(setFleetAssignmentFromValues(valuesWithoutEmptyStrings));
      }

      handleSendTelRate(dataToUse);
    },
    displayName: 'ADD_DRIVER_RATE_FORM',
  }),
  withAsyncDeadheadMiles,
  withSearchLocation,
  enhanceDrivers,
  enhanceFleetMap,
  withHandlers({
    handleClickTelRateAutoAssign: (props: Object) => () => {
      const { handleChangeDriversSelect } = props;

      const assignAvailable = G.isTrue(R.pathOr(false, ['rateInfo', 'autoAssignAvailable'], props));

      if (assignAvailable) {
        const driverGuid = R.path(['rateInfo', 'forAssignDriverGuid'], props);

        if (G.isNotNilAndNotEmpty(driverGuid)) {
          handleChangeDriversSelect(driverGuid, GC.FIELD_PRIMARY_DRIVER_GUID);
        }
      }
    },
    handleClickRefreshDrivers: (props: Object) => async () => {
      await handleGetAllVendorRates(props);

      getAvailableDriversHandler(props)(props, true);
    },
  }),
  withProps({
    isCreate: true,
    isEditMode: true,
    disablePointLocations: false,
    showRefreshAvailableDrivers: true,
  }),
  enhance,
  lifecycle({
    componentDidMount() {
      const props = this.props;
      getAvailableDriversHandler(props)(props);
    },
  }),
  pure,
);

const enhanceUpdate = compose(
  withRecalculateAllChargesId,
  withFleetAssignment,
  withAsyncMarginViolationRules,
  withState('activeDriver', 'setActiveDriver', 'primary'),
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: (props: Object) => setDefaultDriverRateValues(props),
    validationSchema: () => Yup.lazy((values: Object) => getDriverRateValidationSchema(values)),
    handleSubmit: (values: Object, { props }: Object) => {
      const {
        rateData,
        availTrucks,
        isFromPlanner,
        availTrailers,
        handleSendTelRate,
        availableDriversData,
      } = props;

      const chassisFields = R.pick(GC.CHASSIS_GROUPED_FIELDS, values);
      const fleetAssignment = R.mergeRight(values.fleetAssignment, chassisFields);

      const data = R.mergeRight(
        values,
        { fleetAssignment, [GC.FIELD_STATUS]: upperCase(R.prop(GC.FIELD_STATUS, values)) },
      );

      const dataWithoutEmptyStrings = G.mapObjectEmptyStringFieldsToNull(data);
      const dataToUse = G.omitEmptyChargesFromDriverRate(dataWithoutEmptyStrings);

      const fieldsToOmit = [
        GC.FIELD_MAX_PAY_VIOLATED,
        GC.FIELD_MIN_MARGIN_VIOLATED,
        GC.FIELD_CRITICAL_MARGIN_VIOLATED,
      ];

      if (isFromPlanner) {
        return handleSendTelRate(setInfoToDriverRate(
          R.omit(fieldsToOmit, dataToUse),
          rateData,
          { availTrucks, availTrailers, availableDriversData },
        ));
      }

      handleSendTelRate(R.omit(fieldsToOmit, dataToUse));
    },
    displayName: 'EDIT_DRIVER_RATE_FORM',
  }),
  withHandlers({
    handleGetSharedAccessorialsApplyTo: (props: Object) => async () => {
      const {
        values,
        telGuid,
        loadData,
        setValues,
        branchGuid,
        accessorials,
        handleSetRecalculateAllChargesId,
      } = props;

      const sharedApplyTo = await getTelSharedAccessorialsApplyTo({
        telGuid,
        loadData,
        branchGuid,
        accessorials: R.values(accessorials),
        stopCount: R.pathOr(0, ['rateInfo', 'stopCount'], props),
        applyToType: GC.SHARED_ACCESSORIAL_AUTO_APPLY_TO_TYPE_FLEET,
      });

      const chargesArrayName = getChargesArrayNameFromProps(props);
      const rateCharges = R.pathOr([], [chargesArrayName], values);
      const charges = G.replaceAutoChargesWithNewSharedAssessorialsApplyTo(rateCharges, sharedApplyTo);
      const newValues = R.assoc(chargesArrayName, charges, values);

      setValues(newValues);

      G.callFunction(handleSetRecalculateAllChargesId);
    },
  }),
  withAsyncDeadheadMiles,
  withSearchLocation,
  enhanceDrivers,
  enhance,
  lifecycle({
    componentDidMount() {
      const props = this.props;
      getAvailableDriversHandler(props)(props);
    },
  }),
  withProps({
    isUpdate: true,
    isEditMode: true,
    disablePointLocations: true,
    showRefreshAvailableDrivers: false,
  }),
  pure,
);

const getCommonChargeProps = (props: Object) => {
  const currency = R.pathOr(
    R.pathOr(0, ['orderRateInfo', 'currency'], props),
    ['values', GC.FIELD_CURRENCY],
    props,
  );

  const customerLineHaul = R.pathOr(
    R.pathOr(0, ['orderRateInfo', 'customerLineHaul'], props),
    ['rateInfo', 'customerLineHaul'],
    props,
  );

  const totalCustomersRate = R.pathOr(
    R.pathOr(0, ['orderRateInfo', GC.FIELD_TOTAL_CUSTOMER_RATE], props),
    ['rateInfo', GC.FIELD_TOTAL_CUSTOMER_RATE],
    props,
  );

  const stopsQuantity = R.pathOr(0, ['rateInfo', 'stopCount'], props);
  const totalQuantities = R.path(['rateInfo', 'totalQuantities'], props);
  const cloRateAdjustments = R.pathOr([], ['rateInfo', 'cloRateAdjustments'], props);
  const normalizedTotal = R.pathOr({}, ['values', GC.SYSTEM_OBJECT_NORMALIZED_TOTAL], props);

  return {
    currency,
    stopsQuantity,
    normalizedTotal,
    totalQuantities,
    customerLineHaul,
    totalCustomersRate,
    cloRateAdjustments,
  };
};

const DriverCharges = (props: Object) => {
  const {
    isEditMode,
    branchGuid,
    activeDriver,
    recalculateAllChargesId,
    primaryDriverPayedHours,
    secondaryDriverPayedHours,
    primaryDriverDistanceToStart,
    secondaryDriverDistanceToStart,
  } = props;

  const commonTotalProps = G.getTotalsFromValues(props);
  const commonChargeProps = getCommonChargeProps(props);

  return (
    <div>
      {
        R.equals(activeDriver, 'primary') &&
        <DMChargeComponent
          {...commonTotalProps}
          {...commonChargeProps}
          rateProps={props}
          isDriverRate={true}
          loadType={GC.FIELD_TEL}
          isEditMode={isEditMode}
          branchGuid={branchGuid}
          payedHours={primaryDriverPayedHours}
          distanceToStart={primaryDriverDistanceToStart}
          recalculateAllChargesId={recalculateAllChargesId}
          chargesArrayName={GC.FIELD_PRIMARY_DRIVER_CHARGES}
        />
      }
      {
        R.equals(activeDriver, 'team') &&
        <DMChargeComponent
          {...commonTotalProps}
          {...commonChargeProps}
          rateProps={props}
          isDriverRate={true}
          loadType={GC.FIELD_TEL}
          isEditMode={isEditMode}
          branchGuid={branchGuid}
          payedHours={secondaryDriverPayedHours}
          distanceToStart={secondaryDriverDistanceToStart}
          recalculateAllChargesId={recalculateAllChargesId}
          chargesArrayName={GC.FIELD_SECONDARY_DRIVER_CHARGES}
        />
      }
      {
        R.equals(activeDriver, 'vendor') &&
        <DMChargeComponent
          {...commonTotalProps}
          {...commonChargeProps}
          isVendor={true}
          rateProps={props}
          isVendorRate={true}
          loadType={GC.FIELD_TEL}
          isEditMode={isEditMode}
          branchGuid={branchGuid}
          activeTab={activeDriver}
          chargesArrayName={GC.FIELD_FLEET_VENDOR_CHARGES}
          recalculateAllChargesId={recalculateAllChargesId}
        />
      }
    </div>
  );
};

const DriverRateForm = (props: Object) => {
  const {
    closeModal,
    handleSubmit,
    asyncConfigs,
    notAllowedCustomerRate,
  } = props;

  const { marginViolated, maxPayViolated, criticalMarginViolated } = getMarginViolationData(props);

  return (
    <Box width='950px' bg='white'>
      <RateHeaderAuto {...props} mode='create' />
      <form onSubmit={handleSubmit}>
        <MarginViolationSection
          {...G.getFormikProps(props)}
          marginViolated={marginViolated}
          maxPayViolated={maxPayViolated}
          criticalMarginViolated={criticalMarginViolated}
          optionsForSelect={{
            marginViolationReasonOptions: G.createOptionsFromDropdownConfigWithGuidOrParentGuid(
              asyncConfigs,
              GC.RATE_ENGINE_MARGIN_VIOLATION_REASON,
              true,
            ),
          }}
        />
        <DriverSection {...props} optionsForSelect={filterDriversOptions(props)} />
        <SectionsDivider />
        {G.getConfigDriverRateShowStartFinishPointFromWindow() && <PointsSection {...props} />}
        {G.getConfigDriverRateShowStartFinishPointFromWindow() && <SectionsDivider />}
        <GeneralSectionForTel
          {...G.getFormikPropsToFieldset(props)}
          optionsForSelect={G.getModeServiceFromAsyncConfigsForRateSelect(props)}
        />
        <DriverCharges {...props} />
        <StickedBox bottom='0px' zIndex={13}>
          <ChargesTotal {...props} charges={getChargesFromValues(props)} />
          <ChargeFormFooter closeModal={closeModal} submitDisabled={notAllowedCustomerRate} />
          <NotAllowedCustomerRate notAllowedCustomerRate={notAllowedCustomerRate} />
        </StickedBox>
      </form>
    </Box>
  );
};

const EditDriverRate = (props: Object) => {
  const {
    values,
    handleSubmit,
    asyncConfigs,
    notAllowedCustomerRate,
  } = props;

  const { marginViolated, maxPayViolated, criticalMarginViolated } = getMarginViolationData(props);

  return (
    <Box width='950px' bg='white'>
      <RateHeaderAuto {...props} mode='edit' />
      <form onSubmit={handleSubmit}>
        {
          G.isTrue(R.path(['values', 'selected'], props)) &&
          <MarginViolationSection
            {...G.getFormikProps(props)}
            marginViolated={marginViolated}
            maxPayViolated={maxPayViolated}
            criticalMarginViolated={criticalMarginViolated}
            optionsForSelect={{
              marginViolationReasonOptions: G.createOptionsFromDropdownConfigWithGuidOrParentGuid(
                asyncConfigs,
                GC.RATE_ENGINE_MARGIN_VIOLATION_REASON,
                true,
              ),
            }}
          />
        }
        <EditDriverSection {...props} optionsForSelect={filterDriversOptions(props)} />
        <SectionsDivider />
        {G.getConfigDriverRateShowStartFinishPointFromWindow() && <PointsSection {...props} />}
        {G.getConfigDriverRateShowStartFinishPointFromWindow() && <SectionsDivider />}
        <GeneralSectionForTel
          {...G.getFormikPropsToFieldset(props)}
          optionsForSelect={G.getModeServiceFromAsyncConfigsForRateSelect(props)}
        />
        <PriceSheetItems values={values} additionalFormHeaderSectionStyles={{ my: 10 }} />
        <DriverCharges {...props} />
        <StickedBox bottom='0px' zIndex={13}>
          <ChargesTotal {...props} charges={getChargesFromValues(props)} />
          <ChargeFormFooter submitDisabled={notAllowedCustomerRate} />
          <NotAllowedCustomerRate notAllowedCustomerRate={notAllowedCustomerRate} />
        </StickedBox>
      </form>
    </Box>
  );
};

const branchConfigsArray = [
  GC.TRAILER_MANAGE_TRAILERS_FROM,
  GC.TEL_DRIVER_RATE_TRAILER_REQUIRED,
  GC.TEL_DRIVER_RATE_START_POINT_REQUIRED,
  GC.TEL_DRIVER_RATE_FINISH_POINT_REQUIRED,
  GC.TEL_DRIVER_RATE_SHOW_START_FINISH_POINT,
];

const enhanceCreateWithConfigs = compose(
  withConnectModalAndLoaderActions,
  withAsyncBranchConfigsByNames({ omitReceived: true, configs: branchConfigsArray }),
  withAsyncConfigs,
  withAsyncChargeAccessorials({ isDriver: true, isCreateMode: true }),
  enhanceCreate,
);

const enhanceUpdateWithConfigs = compose(
  withConnectModalAndLoaderActions,
  withAsyncBranchConfigsByNames({ omitReceived: true, configs: branchConfigsArray }),
  withAsyncConfigs,
  withAsyncChargeAccessorials({ isDriver: true, isCreateMode: false }),
  enhanceUpdate,
);

export default enhanceCreateWithConfigs(DriverRateForm);

export const EditDriverRateForm = enhanceUpdateWithConfigs(EditDriverRate);
